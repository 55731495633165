import { Modals } from 'src/components/routes/modals';
import { Sidebars } from 'src/components/routes/sidebar';
import { Actions, ColumnsSetting } from 'src/types';
import { actionCreator } from 'src/utils/factories';

export const currentActiveActions = {
  setActions: actionCreator<Actions.ui.currentActiveActions.setActions>(
    'CURRENT_ACTIVE_ACTIONS_SET_ACTIONS'
  ),
  removeActions: actionCreator<Actions.ui.currentActiveActions.removeActions>(
    'CURRENT_ACTIVE_ACTIONS_REMOVE_ACTIONS'
  ),
};

export const modal = {
  show: actionCreator<Actions.ui.modal.show<Modals>>('UI_MODAl_SHOW'),
  hide: actionCreator<Actions.ui.modal.hide>('UI_MODAl_HIDE'),
};

export const sidebar = {
  show: actionCreator<Actions.ui.sidebar.show<Sidebars>>('UI_SIDEBAR_SHOW'),
  hide: actionCreator<Actions.ui.sidebar.hide>('UI_SIDEBAR_HIDE'),
};

export const notifications = {
  addNotification: actionCreator<Actions.ui.notifications.AddNotification>(
    'UI_NOTIFICATIONS_ADD_NOTIFICATION'
  ),
  deleteNotification: actionCreator('UI_NOTIFICATION_DELETE_NOTIFICATION'),
};

export const influencers = {
  setInfluencerSearchSettings:
    actionCreator<Actions.ui.influencers.setSettings>(
      'SET_INFLUENCER_SEARCH_SETTINGS'
    ),
  setSelectedInfluencers:
    actionCreator<Actions.ui.influencers.setSelectedInfluencers>(
      'SET_SELECTED_INFLUENCERS'
    ),

  setColumnSettings: actionCreator<ColumnsSetting>('SET_COLUMNS_SETTINGS'),
};

/**
 * Todo
 */
export const todo = {
  example1: actionCreator<Actions.ui.todo.example1>('UI_TODO_EXAMPLE_1'),
  example2: actionCreator<Actions.ui.todo.example2>('UI_TODO_EXAMPLE_2'),
};

/**
 * Foo ...
 */
export const foo = {
  // ...
};

/**
 * Bar ...
 */
export const bar = {
  // ...
};

export const tags = {
  setSearchSettings: actionCreator<Actions.ui.tags.setSettings>(
    'SET_TAGS_SEARCH_SETTINGS'
  ),
};

export const pool = {
  setSearchSettings: actionCreator<Actions.ui.pool.setSettings>(
    'SET_POOL_SEARCH_SETTINGS'
  ),
  setSelectedInfluencers: actionCreator<Actions.ui.pool.setSelectedInfluencers>(
    'SET_POOL_SELECTED_INFLUENCERS'
  ),
  setPoolInfluencerSearch:
    actionCreator<Actions.ui.pool.setSelectedPoolInfluencerSearch>(
      'SET_SELECTED_POOL_INFLUENCER_SEARCH'
    ),
};

export const brand = {
  setSearchSettings: actionCreator<Actions.ui.brand.setSettings>(
    'SET_BRAND_SEARCH_SETTINGS'
  ),
};

export const organizations = {
  setSearchSettings: actionCreator<Actions.ui.organizations.setSettings>(
    'SET_ORGANIZATIONS_SEARCH_SETTINGS'
  ),
};
