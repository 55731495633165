import classNames from 'classnames';
import React from 'react';

import { DictionaryKey } from 'src/i18n';
import { Msg } from 'src/i18n/Msg';

import styles from 'src/components/atoms/WarningText/styles.module.css';

type Props = {
  textId: string;
  className?: string;
  marker?: string;
};

export const WarningText: React.FC<Props> = ({
  textId,
  className,
  marker = '',
}) => (
  <div className={styles.block}>
    <p className={classNames('contentText', 'warningTextColor', className)}>
      {marker}
      <Msg id={textId as DictionaryKey} />
    </p>
  </div>
);
