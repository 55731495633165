import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import SideBar from 'src/components/organisms/SideBar';
import { sidebars } from 'src/components/routes/sidebar';
import { ReduxState } from 'src/reducers';

export const Sidebar: React.FC = () => {
  const { name, data, title } =
    useSelector((state: ReduxState) => state.sidebar) ?? {};

  const dispatch = useDispatch();

  if (!name) {
    return null;
  }

  const dismiss = () => dispatch(actions.ui.sidebar.hide());

  const Component = sidebars[name];

  return (
    <SideBar visible={!!name} dismiss={dismiss} title={title}>
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <Component {...(data as any)} />
    </SideBar>
  );
};
