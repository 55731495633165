import { Select } from 'antd';
import { toLowercaseSeparator } from 'antd/lib/watermark/utils';
import React from 'react';
import { useIntl } from 'react-intl';

import { MsgProps, msg } from 'src/i18n/Msg';
import { debounce } from 'src/utils/lib';

import styles from 'src/components/atoms/SelectWithSearch/styles.module.css';

type Props = {
  size?: 'small' | 'middle' | 'large';
  options?: { value: string; label: string }[];
  placeholder: MsgProps;
  dropdownStyle?: React.CSSProperties;
  onChange: (value: string) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSelect: (value: string, option: any) => void;
  isDebounce?: boolean;
  delay?: number;
  defaultValue?: string;
  sort?: boolean;
  isDisabled?: boolean;
};

export const SelectWithSearch: React.FC<Props> = ({
  size = 'large',
  options,
  placeholder,
  dropdownStyle,
  onSelect,
  onChange,
  delay = 500,
  isDebounce = false,
  defaultValue,
  sort = true,
  isDisabled = false,
}) => {
  const intl = useIntl();

  const handler = isDebounce ? debounce(onChange, delay) : onChange;

  const handleChange = (text: string) => {
    handler(text);
  };

  return (
    <Select
      size={size}
      className={styles.selectStyle}
      onSelect={onSelect}
      placeholder={msg(intl, placeholder)}
      dropdownStyle={dropdownStyle}
      defaultValue={defaultValue}
      showSearch
      disabled={isDisabled}
      onSearch={handleChange}
      filterOption={(input) =>
        toLowercaseSeparator(input ?? '').includes(toLowercaseSeparator(input))
      }
      filterSort={
        sort
          ? (optionA, optionB) =>
              (optionA?.label ?? '')
                .toLowerCase()
                .localeCompare((optionB?.label ?? '').toLowerCase())
          : undefined
      }
      options={options}
    />
  );
};
