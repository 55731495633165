import { Badge } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { ReduxState } from 'src/reducers';
import { SevaTags } from 'src/types';

import styles from 'src/components/molecules/Tags/styles.module.css';

type Props = {
  tags?: SevaTags[];
  maxLimit?: number;
  isGlobal?: boolean;
  isSearchEnabled?: boolean;
};

export const Tags: React.FC<Props> = ({
  tags = [],
  maxLimit = 2,
  isGlobal = true,
  isSearchEnabled = true,
}) => {
  const { selectedTags } = useSelector(({ influencers }: ReduxState) => ({
    selectedTags: influencers?.influencerSearchSettings?.selectedTags,
  }));

  const dispatch = useDispatch();

  const [limit, setLimit] = useState(maxLimit);
  const onClick = () => {
    setLimit(tags.length);
  };

  const filterTags = (tag: SevaTags) => {
    if (isSearchEnabled) {
      const isAlreadyAdded = selectedTags?.some((x) => x.id === tag._id);
      if (!isAlreadyAdded) {
        selectedTags?.push({
          name: tag?.name || '',
          id: tag?._id || '',
        });
        dispatch(
          actions.ui.influencers.setInfluencerSearchSettings({
            currentPage: 1,
            selectedTags: selectedTags,
          })
        );
      }
    }
  };

  return (
    <div className={styles.wrapper}>
      {tags.slice(0, limit).map((el, index) => (
        <div
          key={index}
          className={styles.tag}
          role="button"
          onClick={() => filterTags(el)}
        >
          <Badge
            count={el.name}
            className={classNames(styles.badge, {
              [styles.userTags]: !el.isGlobal,
            })}
          />
        </div>
      ))}
      {limit < tags.length && limit !== tags.length && (
        <>
          <div className={styles.tag} role="button" onClick={() => onClick()}>
            <Badge
              count={`+${tags.length - maxLimit}`}
              className={classNames(styles.badge, {
                [styles.userTags]: !isGlobal,
              })}
            />
          </div>
        </>
      )}
    </div>
  );
};
