import React from 'react';

import {
  EditCreatorModal,
  Props as EditCreatorProps,
} from 'src/components/routes/modals/EditCreatorModal';

// import {
//   LogHistory,
//   Props as LogHistoryProps,
// } from 'src/components/routes/sidebar/LogHistory';

export type SIDEBARS = keyof Props;

export type Sidebars = {
  [P in SIDEBARS]: { name: P; data?: Props[P]; title?: string };
}[SIDEBARS];

type Props = {
  EditCreatorModal: EditCreatorProps;
  //LogHistory: LogHistoryProps;
};

export const sidebars: {
  [P in SIDEBARS]: React.FC<Props[P]>;
} = {
  EditCreatorModal,
};
