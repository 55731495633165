import { Radio, RadioChangeEvent } from 'antd';
import classNames from 'classnames';
import React, { ReactNode } from 'react';

import styles from 'src/components/atoms/RadioButtonGroup/styles.module.css';

type Props = {
  defaultValue?: string;
  options:
    | string[]
    | number[]
    | Array<{
        label: ReactNode | string;
        value: string;
        disabled?: boolean;
      }>;
  value?: string;
  name?: string;
  optionType: 'button' | 'default';
  size?: 'large' | 'middle' | 'small';
  onChange: (value: RadioChangeEvent) => void;
  style?: React.CSSProperties;
  buttonStyle?: 'outline' | 'solid';
};

export const RadioButtonGroup: React.FC<Props> = ({
  options,
  defaultValue,
  onChange,
  value,
  optionType,
  buttonStyle,
}) => {
  return (
    <div className={classNames(styles.wrapper)}>
      <Radio.Group
        options={options}
        onChange={onChange}
        value={value}
        optionType={optionType || 'button'}
        defaultValue={defaultValue}
        buttonStyle={buttonStyle || 'outline'}
      />
    </div>
  );
};
