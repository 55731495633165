import { put, select, takeEvery } from 'redux-saga/effects';

import { actions } from 'src/actions';
import { ReduxState } from 'src/reducers';
import { TagsSearchSettings } from 'src/types';

function* getTags() {
  const { limitElements, searchWord, currentPage }: TagsSearchSettings =
    yield select((state: ReduxState) => state.tags.searchSettings);

  yield put(
    actions.api.tags.getTags.started({
      limit: limitElements,
      search: searchWord,
      page: currentPage,
    })
  );
}

function* closeModal() {
  yield put(actions.ui.modal.hide());
}

export function* tagsSaga(): Generator {
  yield takeEvery([actions.ui.tags.setSearchSettings], getTags);
  yield takeEvery(
    [actions.api.tags.assignTags.done, actions.api.tags.createTags.done],
    closeModal
  );
}
