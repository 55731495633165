// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_selectStyle__x18yj {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/SelectWithSearch/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb","sourcesContent":[".selectStyle {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectStyle": `styles_selectStyle__x18yj`
};
export default ___CSS_LOADER_EXPORT___;
