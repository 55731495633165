import { ENDPOINTS } from 'src/constants/api';
import { Actions } from 'src/types';
import { actionCreatorAsyncWithHandler } from 'src/utils/factories';

export const access = {
  signInWithEmail: actionCreatorAsyncWithHandler<
    Actions.api.access.signInWithEmail.started,
    Actions.api.access.signInWithEmail.done
  >({
    url: ENDPOINTS.SIGN_IN_WITH_EMAIL,
    method: 'POST',
    extra: {
      hideSuccessNotification: true,
    },
  }),
  signUpWithEmail: actionCreatorAsyncWithHandler<
    Actions.api.access.signUpWithEmail.started,
    Actions.api.access.signUpWithEmail.done
  >({
    url: ENDPOINTS.SIGN_UP_WITH_EMAIL,
    method: 'POST',
    extra: {
      hideSuccessNotification: true,
    },
  }),
  signOut: actionCreatorAsyncWithHandler<
    Actions.api.access.signOut.started,
    Actions.api.access.signOut.done
  >({
    url: ENDPOINTS.SIGN_OUT,
    method: 'POST',
    extra: {
      hideSuccessNotification: true,
    },
  }),
};

export const influencers = {
  getInfluencers: actionCreatorAsyncWithHandler<
    Actions.api.influencers.getInfluencers.started,
    Actions.api.influencers.getInfluencers.done
  >({
    url: ENDPOINTS.INFLUENCERS,
    method: 'GET',
    extra: {
      hideSuccessNotification: true,
    },
  }),
  updateInfluencer: actionCreatorAsyncWithHandler<
    Actions.api.influencers.updateInfluencer.started,
    Actions.api.influencers.updateInfluencer.done
  >({
    url: ENDPOINTS.INFLUENCER_UPDATE,
    method: 'PUT',
    extra: {
      hideSuccessNotification: false,
    },
  }),
  getOrgAssigned: actionCreatorAsyncWithHandler<
    Actions.api.influencers.getOrgAssigned.started,
    Actions.api.influencers.getOrgAssigned.done
  >({
    url: ENDPOINTS.GET_ORGANIZATION_ASSIGNED,
    method: 'GET',
    extra: {
      hideSuccessNotification: true,
    },
  }),
  getPoolAssigned: actionCreatorAsyncWithHandler<
    Actions.api.influencers.getPoolAssigned.started,
    Actions.api.influencers.getPoolAssigned.done
  >({
    url: ENDPOINTS.GET_POOl_ASSIGNED,
    method: 'GET',
    extra: {
      hideSuccessNotification: true,
    },
  }),
  assignOrg: actionCreatorAsyncWithHandler<
    Actions.api.influencers.assignOrg.started,
    Actions.api.influencers.assignOrg.done
  >({
    url: ENDPOINTS.ASSIGN_ORG,
    method: 'POST',
    extra: {
      hideSuccessNotification: false,
    },
  }),
  removeInfluencerFromOrganization: actionCreatorAsyncWithHandler<
    Actions.api.influencers.removeInfluencerFromOrganization.started,
    Actions.api.influencers.removeInfluencerFromOrganization.done
  >({
    url: ENDPOINTS.REMOVE_FROM_ORGANIZATION,
    method: 'POST',
    extra: {
      hideSuccessNotification: false,
    },
  }),
  switchInfluencerOrgStatus: actionCreatorAsyncWithHandler<
    Actions.api.influencers.switchInfluencerOrgStatus.started,
    Actions.api.influencers.switchInfluencerOrgStatus.done
  >({
    url: ENDPOINTS.SWITCH_INFLUENCER_ORGANIZATION_ORG,
    method: 'POST',
    extra: {
      hideSuccessNotification: false,
    },
  }),
};

export const brand = {
  get: actionCreatorAsyncWithHandler<
    Actions.api.brand.get.started,
    Actions.api.brand.get.done
  >({
    url: ENDPOINTS.BRAND_GET,
    method: 'GET',
    extra: {
      hideSuccessNotification: true,
    },
  }),
  changeActiveStatus: actionCreatorAsyncWithHandler<
    Actions.api.brand.changeActiveStatus.started,
    Actions.api.brand.changeActiveStatus.done
  >({
    url: ENDPOINTS.BRAND_CHANGE_ACTIVE_STATUS,
    method: 'PUT',
    extra: {
      hideSuccessNotification: false,
    },
  }),
};

export const pool = {
  getPools: actionCreatorAsyncWithHandler<
    Actions.api.pool.getPools.started,
    Actions.api.pool.getPools.done
  >({
    url: ENDPOINTS.GET_POOL,
    method: 'GET',
    extra: {
      hideSuccessNotification: true,
    },
  }),
  createPool: actionCreatorAsyncWithHandler<
    Actions.api.pool.createPool.started,
    Actions.api.pool.createPool.done
  >({
    url: ENDPOINTS.CREATE_POOL,
    method: 'POST',
    extra: {
      hideSuccessNotification: false,
    },
  }),
  assignPool: actionCreatorAsyncWithHandler<
    Actions.api.pool.assignPool.started,
    Actions.api.pool.assignPool.done
  >({
    url: ENDPOINTS.ASSIGN_POOl,
    method: 'POST',
    extra: {
      hideSuccessNotification: false,
    },
  }),
  getPoolDetail: actionCreatorAsyncWithHandler<
    Actions.api.pool.getPoolDetail.started,
    Actions.api.pool.getPoolDetail.done
  >({
    url: ENDPOINTS.GET_POOL_DETAIL,
    method: 'GET',
    extra: {
      hideSuccessNotification: true,
    },
  }),
  activatePool: actionCreatorAsyncWithHandler<
    Actions.api.pool.activatePool.started,
    Actions.api.pool.activatePool.done
  >({
    url: ENDPOINTS.ACTIVATE_POOL,
    method: 'POST',
    extra: {
      hideSuccessNotification: false,
    },
  }),
  removeCreatorFromPool: actionCreatorAsyncWithHandler<
    Actions.api.pool.removeCreatorFromPool.started,
    Actions.api.pool.removeCreatorFromPool.done
  >({
    url: ENDPOINTS.REMOVE_CREATOR_FROM_POOL,
    method: 'POST',
    extra: {
      hideSuccessNotification: false,
    },
  }),
};

export const tags = {
  getTags: actionCreatorAsyncWithHandler<
    Actions.api.tags.getTags.started,
    Actions.api.tags.getTags.done
  >({
    url: ENDPOINTS.TAGS_GET,
    method: 'GET',
    extra: {
      hideSuccessNotification: true,
    },
  }),
  assignTags: actionCreatorAsyncWithHandler<
    Actions.api.tags.assignTags.started,
    Actions.api.tags.assignTags.done
  >({
    url: ENDPOINTS.ASSIGN_TAGS,
    method: 'POST',
    extra: {
      hideSuccessNotification: false,
    },
  }),
  createTags: actionCreatorAsyncWithHandler<
    Actions.api.tags.createTags.started,
    Actions.api.tags.createTags.done
  >({
    url: ENDPOINTS.CREATE_TAGS,
    method: 'POST',
    extra: {
      hideSuccessNotification: false,
    },
  }),
  markActive: actionCreatorAsyncWithHandler<
    Actions.api.tags.markActive.started,
    Actions.api.tags.markActive.done
  >({
    url: ENDPOINTS.MARK_ACTIVE,
    method: 'POST',
    extra: {
      hideSuccessNotification: false,
    },
  }),
};

export const organization = {
  getOrganization: actionCreatorAsyncWithHandler<
    Actions.api.organization.getOrganization.started,
    Actions.api.organization.getOrganization.done
  >({
    url: ENDPOINTS.GET_ORGANIZATION,
    method: 'GET',
    extra: {
      hideSuccessNotification: true,
    },
  }),
  updateModules: actionCreatorAsyncWithHandler<
    Actions.api.organization.updateModules.started,
    Actions.api.organization.updateModules.done
  >({
    url: ENDPOINTS.UPDATE_MODULES,
    method: 'PUT',
    extra: {
      hideSuccessNotification: false,
    },
  }),
  addUser: actionCreatorAsyncWithHandler<
    Actions.api.organization.addUser.started,
    Actions.api.organization.addUser.done
  >({
    url: ENDPOINTS.ORG_ADD_USER,
    method: 'POST',
    extra: {
      hideSuccessNotification: false,
    },
  }),
};

export const ws = {
  echo: actionCreatorAsyncWithHandler<
    Actions.api.ws.echo.started,
    Actions.api.ws.echo.done
  >({
    url: ENDPOINTS.WS_ECHO,
    method: 'GET',
    extra: {
      hideSuccessNotification: true,
    },
  }),
};

/**
 * Todo
 */
export const todo = {
  withID: actionCreatorAsyncWithHandler<
    Actions.api.todo.withID.started,
    Actions.api.todo.withID.done
  >({
    url: ENDPOINTS.TODO_WITH_ID,
    method: 'POST',
    extra: {
      hideSuccessNotification: true,
    },
  }),
};
