import classNames from 'classnames';
import React from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'src/actions';
import { Button } from 'src/components/atoms/Button';

import styles from 'src/components/routes/modals/ConfirmationModal/styles.module.css';

export type Props = {
  title: string;
  text: string;
  subText?: string;
  okAction: () => void;
};

export const ConfirmationModal: React.FC<Props> = ({
  title,
  text,
  subText,
  okAction,
}) => {
  const dispatch = useDispatch();

  const onClickCancel = () => dispatch(actions.ui.modal.hide());

  return (
    <div className={classNames('shadowLight', styles.container)}>
      <div className={styles.headerBlock}>
        <p className={styles.titleStyle}>{title}</p>
      </div>
      <div className={styles.mainBlock}>
        <p>{text}</p>
        {subText && <span>{subText}</span>}
      </div>
      <div className={styles.footerBlock}>
        <div className={styles.buttonCancelStyle}>
          <Button
            onClick={onClickCancel}
            text={{
              id: 'components.routes.modals.AddCampaignModal.cancelButton',
            }}
            variant="cancel"
          />
        </div>
        <div className={styles.buttonCreateStyle}>
          <Button
            onClick={() => {
              okAction();
              onClickCancel();
            }}
            text={{
              id: 'components.routes.modals.AddCampaignModal.okButton',
            }}
            variant="create"
          />
        </div>
      </div>
    </div>
  );
};
