import { RadioChangeEvent } from 'antd';
import { useState } from 'react';

import { RadioButtonGroup } from 'src/components/atoms/RadioButtonGroup';
import { ColumnMenuClick, ColumnsSetting } from 'src/types';

import style from 'src/components/molecules/ColumnView/styles.module.css';

type Props = {
  column: ColumnsSetting[0];
  columnKey: string;
  columnIndex?: number;
  onColumnMenuClicked: ColumnMenuClick;
};

const columnVisibilityOption = [
  { label: 'Auto', value: 'auto' },
  { label: 'Show', value: 'show' },
  { label: 'Hide', value: 'hide' },
];

export const ColumnView: React.FC<Props> = ({
  column,
  columnKey,
  columnIndex,
  onColumnMenuClicked,
}) => {
  const [optionValue, setOptionValue] = useState(column.value);
  const handelChange = ({ target: value }: RadioChangeEvent): void => {
    console.log('radio button state change', value);
    const selectedValue = value.value as unknown as string;
    setOptionValue(selectedValue);
    onColumnMenuClicked(selectedValue, columnKey);
  };
  return (
    <div className={style.columnList} key={columnIndex}>
      <div className={style.columnLabel}>{column.label} </div>
      <div className={style.radiobutton}>
        <RadioButtonGroup
          options={columnVisibilityOption}
          optionType={'button'}
          onChange={handelChange}
          value={optionValue}
        />
      </div>
    </div>
  );
};
