// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__BXaCj {
  border: var(--SIZE_BORDER) solid var(--COLOR_PRIMARY_350);
}

.styles_name__Ik\\+hk p {
  color: var(--COLOR_GRAY_500);
  font-size: var(--FONT_SIZE_TEXT);
  font-family: var(--FONT_MEDIUM);
  font-weight: var(--WEIGHT_NORMAL);
  line-height: var(--LINE_HEIGHT_20);
  width: var(--SIZE_88);
}

.styles_deleteOption__oWwwn {
  cursor: pointer;
}

.styles_disabledDeleteButton__CpTeW {
  opacity: 0.2;
}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/CreatorOverviewPoolTable/styles.module.css"],"names":[],"mappings":"AAAA;EACE,yDAAyD;AAC3D;;AAEA;EACE,4BAA4B;EAC5B,gCAAgC;EAChC,+BAA+B;EAC/B,iCAAiC;EACjC,kCAAkC;EAClC,qBAAqB;AACvB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".wrapper {\n  border: var(--SIZE_BORDER) solid var(--COLOR_PRIMARY_350);\n}\n\n.name p {\n  color: var(--COLOR_GRAY_500);\n  font-size: var(--FONT_SIZE_TEXT);\n  font-family: var(--FONT_MEDIUM);\n  font-weight: var(--WEIGHT_NORMAL);\n  line-height: var(--LINE_HEIGHT_20);\n  width: var(--SIZE_88);\n}\n\n.deleteOption {\n  cursor: pointer;\n}\n\n.disabledDeleteButton {\n  opacity: 0.2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__BXaCj`,
	"name": `styles_name__Ik+hk`,
	"deleteOption": `styles_deleteOption__oWwwn`,
	"disabledDeleteButton": `styles_disabledDeleteButton__CpTeW`
};
export default ___CSS_LOADER_EXPORT___;
