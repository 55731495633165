import React from 'react';

import { IconBaseProps } from 'src/types';

export const TiktokGrey: React.FC<IconBaseProps> = ({
  width = 27,
  height = 27,
  fill = 'none',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 17 17"
  >
    <g clipPath="url(#clip0_4271_82233)">
      <path
        d="M11.6382 0.214844H8.94172V11.1134C8.94172 12.412 7.90464 13.4786 6.61402 13.4786C5.3234 13.4786 4.2863 12.412 4.2863 11.1134C4.2863 9.83804 5.30036 8.79453 6.54489 8.74818V6.01195C3.80232 6.05831 1.58984 8.3076 1.58984 11.1134C1.58984 13.9424 3.84841 16.2148 6.63708 16.2148C9.42569 16.2148 11.6843 13.9192 11.6843 11.1134V5.52498C12.6983 6.26702 13.9428 6.7076 15.2565 6.7308V3.99455C13.2284 3.92499 11.6382 2.25542 11.6382 0.214844Z"
        fill="#344054"
      />
    </g>
    <defs>
      <clipPath id="clip0_4271_82233">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0.255859 0.214844)"
        />
      </clipPath>
    </defs>
  </svg>
);
