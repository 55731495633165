import { CheckCircleOutlined } from '@ant-design/icons';
import { Button as AntButton, ConfigProvider } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import classNames from 'classnames';
import React from 'react';

import { Preloader } from 'src/components/atoms/Preloader';
import { Msg, MsgProps } from 'src/i18n/Msg';
import { theme } from 'src/styles/antTheme';

import styles from 'src/components/atoms/Button/styles.module.css';

type Props = ButtonProps & {
  disabled?: boolean;
  onClick?: () => void;
  text: MsgProps;
  preloader?: boolean;
  successLoader?: boolean;
  btnClassName?: string;
  variant:
    | 'create'
    | 'createLargeText'
    | 'createMini'
    | 'cancel'
    | 'cancelLarge'
    | 'cancelMini'
    | 'cancelMiniRed'
    | 'close'
    | 'save'
    | 'link'
    | 'reset';
};

const variants: Record<
  Props['variant'],
  {
    styleButton: string;
    styleText?: string;
  }
> = {
  create: {
    styleButton: styles.createStyle,
  },
  createLargeText: {
    styleButton: styles.createStyle,
    styleText: styles.createMiniStyleText,
  },
  createMini: {
    styleButton: styles.createMiniStyle,
    styleText: styles.createMiniStyleText,
  },
  cancel: {
    styleButton: styles.cancelStyle,
    styleText: styles.cancelStyleText,
  },
  cancelLarge: {
    styleButton: styles.cancelStyle,
    styleText: styles.cancelLargeStyleText,
  },
  cancelMini: {
    styleButton: styles.cancelMiniStyle,
    styleText: styles.cancelStyleText,
  },
  cancelMiniRed: {
    styleButton: styles.cancelMiniRedStyle,
    styleText: styles.cancelRedStyleText,
  },
  close: {
    styleButton: styles.closeStyle,
    styleText: styles.closeStyleText,
  },
  save: {
    styleButton: styles.closeStyle,
    styleText: styles.saveStyleText,
  },
  link: {
    styleButton: styles.linkStyle,
    styleText: styles.linkStyleText,
  },
  reset: {
    styleButton: styles.resetStyle,
    styleText: styles.resetStyleText,
  },
};

export const Button: React.FC<Props> = ({
  text,
  preloader = false,
  successLoader = false,
  variant,
  btnClassName = '',
  ...rest
}) => {
  const { styleButton, styleText } = variants[variant];

  return (
    <ConfigProvider theme={theme}>
      <AntButton
        className={classNames(styleButton, btnClassName, {
          [styles.preloader]: preloader,
        })}
        {...rest}
      >
        <span
          className={classNames('buttonText', styleText, {
            [styles.disabledText]: rest?.disabled,
          })}
        >
          {preloader ? (
            successLoader ? (
              <CheckCircleOutlined
                style={{ fontSize: '18px', color: '#ffffff' }}
                rev={undefined}
              />
            ) : (
              <Preloader />
            )
          ) : (
            <Msg {...text} />
          )}
        </span>
      </AntButton>
    </ConfigProvider>
  );
};
