// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_block__6akWG {
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

.styles_text__5G\\+kA {
  font-size: var(--FONT_SIZE_TEXT);
  font-style: normal;
  font-family: var(--FONT_DEFAULT);
  font-weight: var(--WEIGHT_400);
  color: var(--COLOR_GRAY_500);
  line-height: var(--LINE_HEIGHT_20);
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/TableDataText/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,gCAAgC;EAChC,kBAAkB;EAClB,gCAAgC;EAChC,8BAA8B;EAC9B,4BAA4B;EAC5B,kCAAkC;AACpC","sourcesContent":[".block {\n  width: max-content;\n  height: max-content;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.text {\n  font-size: var(--FONT_SIZE_TEXT);\n  font-style: normal;\n  font-family: var(--FONT_DEFAULT);\n  font-weight: var(--WEIGHT_400);\n  color: var(--COLOR_GRAY_500);\n  line-height: var(--LINE_HEIGHT_20);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"block": `styles_block__6akWG`,
	"text": `styles_text__5G+kA`
};
export default ___CSS_LOADER_EXPORT___;
