import { Select } from 'antd';
import { BaseOptionType } from 'antd/es/select';
import React from 'react';
import { useIntl } from 'react-intl';

import { MsgProps, msg } from 'src/i18n/Msg';
import { debounce } from 'src/utils/lib';

import styles from 'src/components/atoms/MultiSelectTags/styles.module.css';

type Props = {
  size?: 'small' | 'middle' | 'large';
  options?: { value: string; label: string }[];
  placeholder: MsgProps;
  dropdownStyle?: React.CSSProperties;
  onChange: (value: string) => void;
  isDebounce?: boolean;
  delay?: number;
  defaultValue?: string[];
  transformOnChange?: (value: BaseOptionType | BaseOptionType[]) => string;
};

export const MultiSelectTags: React.FC<Props> = ({
  size = 'large',
  options,
  placeholder,
  dropdownStyle,
  onChange,
  delay = 500,
  isDebounce = false,
  transformOnChange,
  defaultValue = [],
}) => {
  const intl = useIntl();

  const handler = isDebounce ? debounce(onChange, delay) : onChange;

  const handleChange = (
    text: string,
    row: BaseOptionType | BaseOptionType[]
  ) => {
    if (transformOnChange) {
      handler(transformOnChange(row));
    } else {
      handler(text);
    }
  };

  return (
    <Select
      defaultValue={defaultValue as unknown as string}
      size={size}
      mode="multiple"
      allowClear
      style={{ width: '100%' }}
      onChange={handleChange}
      options={options}
      className={styles.selectStyle}
      placeholder={msg(intl, placeholder)}
      dropdownStyle={dropdownStyle}
    />
  );
};
