// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_mainBlock__brTYW {
  width: 100%;
  height: calc(100vh - 145px);
  display: flex;
  flex-direction: column;
  padding: var(--PADDING_24) 0px;
  border-bottom: var(--SIZE_BORDER) solid var(--COLOR_PRIMARY_350);
}

.styles_inputsBlock__580bW {
  width: 100%;
  height: max-content;
  display: flex;
  flex-direction: row;
  margin-bottom: var(--MARGIN_16);
}

.styles_inputsBlockSpacer__Ss6Hd {
  margin-bottom: var(--MARGIN_32);
  padding-bottom: var(--PADDING_34);
  border-bottom: var(--SIZE_BORDER) solid var(--COLOR_PRIMARY_350);
}

.styles_firstInputFromBlock__i5sl0 {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.styles_menuTitleText__BK9LC {
  padding-bottom: var(--PADDING_6);
  font-family: var(--FONT_MEDIUM);
  font-style: normal;
  font-weight: var(--WEIGHT_NORMAL);
  font-size: var(--FONT_SIZE_SUB_TITLE);
  line-height: var(--LINE_HEIGHT_20);
  color: var(--COLOR_PRIMARY_950);
  margin-bottom: var(--MARGIN_16);
}

.styles_addButtonStyle__mQb\\+7 {
  margin-bottom: var(--MARGIN_16);
}

.styles_menuDescriptionText__vvih7 {
  padding-bottom: var(--PADDING_6);
  font-family: var(--FONT_DEFAULT);
  font-style: normal;
  font-weight: var(--WEIGHT_NORMAL);
  font-size: var(--FONT_SIZE_TEXT);
  line-height: var(--LINE_HEIGHT_20);
  color: var(--COLOR_GRAY_600);
  margin-bottom: var(--MARGIN_16);
}
`, "",{"version":3,"sources":["webpack://./src/components/organisms/CreatorOverview/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,2BAA2B;EAC3B,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,gEAAgE;AAClE;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,aAAa;EACb,mBAAmB;EACnB,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;EAC/B,iCAAiC;EACjC,gEAAgE;AAClE;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gCAAgC;EAChC,+BAA+B;EAC/B,kBAAkB;EAClB,iCAAiC;EACjC,qCAAqC;EACrC,kCAAkC;EAClC,+BAA+B;EAC/B,+BAA+B;AACjC;;AAEA;EACE,+BAA+B;AACjC;;AAEA;EACE,gCAAgC;EAChC,gCAAgC;EAChC,kBAAkB;EAClB,iCAAiC;EACjC,gCAAgC;EAChC,kCAAkC;EAClC,4BAA4B;EAC5B,+BAA+B;AACjC","sourcesContent":[".mainBlock {\n  width: 100%;\n  height: calc(100vh - 145px);\n  display: flex;\n  flex-direction: column;\n  padding: var(--PADDING_24) 0px;\n  border-bottom: var(--SIZE_BORDER) solid var(--COLOR_PRIMARY_350);\n}\n\n.inputsBlock {\n  width: 100%;\n  height: max-content;\n  display: flex;\n  flex-direction: row;\n  margin-bottom: var(--MARGIN_16);\n}\n\n.inputsBlockSpacer {\n  margin-bottom: var(--MARGIN_32);\n  padding-bottom: var(--PADDING_34);\n  border-bottom: var(--SIZE_BORDER) solid var(--COLOR_PRIMARY_350);\n}\n\n.firstInputFromBlock {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.menuTitleText {\n  padding-bottom: var(--PADDING_6);\n  font-family: var(--FONT_MEDIUM);\n  font-style: normal;\n  font-weight: var(--WEIGHT_NORMAL);\n  font-size: var(--FONT_SIZE_SUB_TITLE);\n  line-height: var(--LINE_HEIGHT_20);\n  color: var(--COLOR_PRIMARY_950);\n  margin-bottom: var(--MARGIN_16);\n}\n\n.addButtonStyle {\n  margin-bottom: var(--MARGIN_16);\n}\n\n.menuDescriptionText {\n  padding-bottom: var(--PADDING_6);\n  font-family: var(--FONT_DEFAULT);\n  font-style: normal;\n  font-weight: var(--WEIGHT_NORMAL);\n  font-size: var(--FONT_SIZE_TEXT);\n  line-height: var(--LINE_HEIGHT_20);\n  color: var(--COLOR_GRAY_600);\n  margin-bottom: var(--MARGIN_16);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainBlock": `styles_mainBlock__brTYW`,
	"inputsBlock": `styles_inputsBlock__580bW`,
	"inputsBlockSpacer": `styles_inputsBlockSpacer__Ss6Hd`,
	"firstInputFromBlock": `styles_firstInputFromBlock__i5sl0`,
	"menuTitleText": `styles_menuTitleText__BK9LC`,
	"addButtonStyle": `styles_addButtonStyle__mQb+7`,
	"menuDescriptionText": `styles_menuDescriptionText__vvih7`
};
export default ___CSS_LOADER_EXPORT___;
