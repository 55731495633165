import InputAntd from 'antd/lib/input/Input';
import React from 'react';
import { useIntl } from 'react-intl';

import { DictionaryKey } from 'src/i18n';
import { Msg, MsgProps, msg } from 'src/i18n/Msg';

import styles from 'src/components/atoms/Input/styles.module.css';

type Props = {
  placeholder?: MsgProps;
  onChange: (value: string) => void;
  onBlur?: (value: string) => void;
  onPressEnter?: (value: string) => void;
  defaultValue?: string;
  value?: string;
  error?: string;
  className?: string;
  type?: string;
  disabled?: boolean;
  min?: number;
  max?: number;
  required?: boolean;
};

export const Input: React.FC<Props> = ({
  placeholder,
  onChange,
  value,
  defaultValue,
  error,
  className,
  onBlur,
  type,
  disabled,
  min,
  max,
  required = false,
  onPressEnter,
}) => {
  const intl = useIntl();

  return (
    <div className={styles.wrapper}>
      <InputAntd
        type={type}
        className={className}
        disabled={disabled}
        onPressEnter={(event) => {
          if (onPressEnter) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const value = event.target.value as string;
            return onPressEnter(value);
          }
        }}
        onBlur={(event) => {
          if (onBlur) {
            return onBlur(event.target.value);
          }
        }}
        placeholder={placeholder ? msg(intl, placeholder) : ''}
        value={value}
        defaultValue={defaultValue}
        onChange={(event) => onChange(event.target.value)}
        min={min}
        max={max}
        required={required}
      />

      {error && (
        <p className={styles.errorStyle}>
          <Msg id={error as DictionaryKey} />
        </p>
      )}
    </div>
  );
};
