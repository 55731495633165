import { Influencer, InfluencerResponse, Tags } from 'src/types';

import { getCountryCode } from './helper';
import { getInstagramLogin, getTiktokLogin, maskUsername } from './parser';

export const getNormalizedInfluencers = (
  result: InfluencerResponse[]
): Influencer[] =>
  result.map((el) => ({
    _id: el._id,
    firstname: el.firstname,
    lastname: el.lastname,
    id: el._id,
    key: el._id,
    firstName: el.firstname,
    lastName: el.lastname,
    sourcer: el.sourcer || '',
    country: getCountryCode(el?.country || ''),
    instagramFollowers: el.ig_followers ? el.ig_followers : 0,
    instagramLogin: getInstagramLogin(el.ig_link),
    tiktokLogin: getTiktokLogin(el.tt_link),
    tiktokFollowers: el.tt_followers ? el.tt_followers : 0,
    youtubeFollowers: el.youtube_followers ? el.youtube_followers : 0,
    engRate: el.ig_engagement_rate ? parseInt(el.ig_engagement_rate) : 0,
    personaTags: el.persona?.replace(/[\])}[{(\n]/g, '').split(' ') || [],
    email: el.email,
    secondary_email: el.secondary_email,
    organization: el.organization,
    campaign: '',
    emailSequence: '',
    lastActivity: '',
    notes: el.notes || '',
    estimatedRate: '',
    agency: '',
    bio: el.bio,
    tt_link: el?.tt_link,
    tt_bio: el?.tt_bio || el.bio,
    ig_bio: el?.bio,
    ig_link: el?.ig_link,
    youtube_link: el?.youtube_link,
    tt_username: maskUsername(getTiktokLogin(el?.tt_link)),
    ig_username: maskUsername(getInstagramLogin(el?.ig_link)),
    isActive: el?.isActive,
    profile_image: el?.profile_image,
    tt_verified: el?.tt_verified,
    ig_verified: el?.ig_verified,
    engagement_status: el?.engagement_status,
    createdAt: el.createdAt,
    added_via: el?.added_via,
    tags: el?.tags,
    persona_tags: el?.persona_tags,
    admin_tags: el?.admin_tags,
    profile_updatedAt: el?.profile_updatedAt,
    profile_updatedBy: el?.profile_updatedBy,
    //campaigns: el?.campaigns?.filter((x) => x?._id !== undefined),
    //sequences: el?.sequences?.filter((x) => x?._id !== undefined),
    open: el?.open || 0,
    click: el?.click || 0,
    replied: el?.replied || 0,
    bounce: el?.bounce || 0,
    bounceType: el?.bounceType,
    bounceReason: el?.bounceReason,
    bounceErrorCode: el?.bounceErrorCode,
    bounceStages: el?.bounceStages,
    nextStage: el?.nextStage,
    lastStage: el?.lastStage,
    orderStatus: el.orderStatus,
    saleUpdatedAt: el.saleUpdatedAt,
    status: el.status,
    campaignInfluencerId: el.campaignInfluencerId,
    campaignId: el.campaignId,
    repliedMailId: el?.repliedMailId,
    repliedEmailStageId: el?.repliedEmailStageId,
    repliedCreatedByName: el?.repliedCreatedByName,
    repliedCreatedById: el?.repliedCreatedById,
    repliedInterestStatus: el?.repliedInterestStatus,
    repliedInterestByStatus: el?.repliedInterestByStatus,
    pools: el?.pools?.filter((x) => x?._id !== undefined),
    ig_followers_growth_rate: el?.ig_followers_growth_rate,
    tt_followers_growth_rate: el?.tt_followers_growth_rate,
    tt_growth_status: el?.tt_growth_status,
    ig_growth_status: el?.ig_growth_status,
    followedBy: el?.followedBy,
  }));

// export const replaceElementInArray = (
//   item: InfluencerResponse,
//   array?: Influencer[]
// ): Influencer[] | undefined => {
//   const influencer = getNormalizedInfluencers([item]);

//   if (influencer) {
//     return array?.map((el) => (el.id !== item._id ? el : influencer[0]));
//   }
// };

export const getCurrentInfluencersId = ({
  influencers = [],
  state,
}: {
  influencers?: Influencer[];
  state?: string[];
}): string[] | undefined => {
  const newState: Record<string, string> =
    state?.reduce((accumulator, value) => {
      return { ...accumulator, [value]: value };
    }, {}) || {};

  if (influencers.length === 1) {
    if (newState[influencers[0].id]) {
      delete newState[influencers[0].id];
    } else {
      newState[influencers[0].id] = influencers[0].id;
    }

    return Object.values(newState);
  } else if (influencers.length > 1) {
    influencers.forEach((el) => {
      if (newState[el.id]) {
        delete newState[el.id];
      } else {
        newState[el.id] = el.id;
      }
    });

    return Object.values(newState);
  }
};

// export const getCurrentCampaignsId = ({
//   campaigns = [],
//   state,
// }: {
//   campaigns?: Campaign[];
//   state?: string[];
// }): string[] | undefined => {
//   const newState: Record<string, string> =
//     state?.reduce((accumulator, value) => {
//       return { ...accumulator, [value]: value };
//     }, {}) || {};

//   if (campaigns.length === 1) {
//     if (newState[campaigns[0].key]) {
//       delete newState[campaigns[0].key];
//     } else {
//       newState[campaigns[0].key] = campaigns[0].key;
//     }

//     return Object.values(newState);
//   } else if (campaigns.length > 1) {
//     campaigns.forEach((el) => {
//       if (newState[el.key]) {
//         delete newState[el.key];
//       } else {
//         newState[el.key] = el.key;
//       }
//     });

//     return Object.values(newState);
//   }
// };

export const filterSelected = ({
  recordsChanged = [],
  selected,
  selectedKeys = [],
}: {
  recordsChanged: Influencer[];
  selectedKeys: string[];
  selected: boolean;
}) => {
  if (!selected) {
    return selectedKeys.filter((item: string) => {
      if (recordsChanged.find((record) => record.id !== item)) {
        return true;
      } else {
        return false;
      }
    });
  } else {
    recordsChanged.forEach((item) => {
      selectedKeys.push(item.id);
    });
    return selectedKeys;
  }
};

export const getRootActions = (actions: string[]) =>
  actions.map((action) =>
    action.replace('_STARTED', '').replace('_DONE', '').replace('_FAILED', '')
  );

export const handleActions = (state: string[], actions: string[]) => {
  const normalizedState = getRootActions(state);
  const normalizedActions = getRootActions(actions);

  return normalizedState.filter(
    (action) => !normalizedActions.includes(action)
  );
};

// export const getSortedInfluencersById = (
//   influencers: InfluencerResponse[] = []
// ): InfluencerResponse[] => {
//   if (influencers.length < 2) {
//     return influencers;
//   }

//   const pivot = influencers[Math.floor((influencers.length - 1) / 2)];

//   const less = influencers.filter((el) => el._id < pivot._id);

//   const greater = influencers.filter((el) => el._id > pivot._id);

//   return [
//     ...getSortedInfluencersById(less),
//     pivot,
//     ...getSortedInfluencersById(greater),
//   ];
// };

// export const AddSortedInfluencersById = (
//   influencers: InfluencerResponse[],
//   exceptions: Key[]
// ) => {
//   if (!influencers.length || !exceptions.length) {
//     return influencers;
//   }

//   const sortedIds = getSortedId(exceptions);

//   return influencers.map((el) => {
//     if (el._id === sortedIds[0]) {
//       sortedIds.shift();

//       return { ...el, isSelected: true };
//     }

//     return el;
//   });
// };

// export const getSortedId = (ids: Key[]): Key[] => {
//   if (ids.length < 2) {
//     return ids;
//   }

//   const pivot = ids[Math.floor((ids.length - 1) / 2)];

//   const less = ids.filter((el) => el < pivot);

//   const greater = ids.filter((el) => el > pivot);

//   return [...getSortedId(less), pivot, ...getSortedId(greater)];
// };

// export const getSortedInfluencersWithExceptions = (
//   influencers: InfluencerResponse[],
//   exceptions: Key[]
// ) => {
//   if (!influencers.length || !exceptions.length) {
//     return influencers;
//   }

//   const sortedIds = getSortedId(exceptions);

//   return influencers.map((el) => {
//     if (el._id === sortedIds[0]) {
//       sortedIds.shift();

//       return { ...el, isSelected: false };
//     }

//     return el;
//   });
// };

// export const getDefaultEmailStage = (
//   scheduleDay = 1,
//   scheduleTime = '09:00:00',
//   scheduleTimezone = getTimeZone()
// ): EmailStage => ({
//   id: generateID(),
//   mode: 'all',
//   message: {
//     text: 'Hi {{firstname}},',
//     loading: false,
//   },
//   stageOrder: 1,
//   scheduleDay,
//   scheduleTime,
//   scheduleTimezone,
//   skipWeekends: false,
//   previewId: undefined,
//   scheduleOption: 'later',
//   subject: '',
//   replyAs: false,
//   //replyFor: undefined,
// });

// export const getNextEmailStage = (data: EmailStage[]): EmailStage => {
//   let scheduleDay = 1;
//   let scheduleTime = '09:00:00';
//   let scheduleTimezone = getTimeZone();
//   let skipWeekends = false;

//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   // @ts-ignore
//   scheduleDay = data.at(-1)?.scheduleDay + 1 || 1;
//   scheduleTime = data.at(-1)?.scheduleTime || '09:00:00';
//   scheduleTimezone = data.at(-1)?.scheduleTimezone || getTimeZone();
//   skipWeekends = data.at(-1)?.skipWeekends || false;

//   return {
//     id: generateID(),
//     mode: 'all',
//     message: {
//       text: 'Hi {{firstname}},',
//       loading: false,
//     },
//     stageOrder: 1,
//     scheduleDay,
//     scheduleTime,
//     scheduleTimezone,
//     skipWeekends,
//     previewId: undefined,
//     scheduleOption: 'later',
//     subject: '',
//     replyAs: true,
//   };
// };

// export const getArrayWithNewEmailStage = (emailStages: EmailStage[]) => {
//   const newStage = emailStages[emailStages.length - 1];

//   if (emailStages.length === 1) {
//     newStage.scheduleDay = 1;
//     newStage.scheduleTime = getCurrentTime();
//   }

//   return [...emailStages, { ...newStage, id: generateID() }];
// };

// export const getArrayWithEditedEmailStage = (
//   emailStages: EmailStage[],
//   editedEmailStage: EmailStage
// ) => {
//   return emailStages.map((emailStage) => {
//     if (
//       editedEmailStage.id === emailStage.id ||
//       editedEmailStage.id === emailStage._id
//     ) {
//       return editedEmailStage;
//     }
//     return emailStage;
//   });
// };

// export const getArrayWithEditedTimingEmailStage = (
//   emailStages: EmailStage[],
//   data: {
//     id: string;
//     scheduleDay: number;
//     scheduleTime: string;
//     scheduleTimezone: string;
//     skipWeekends: boolean;
//     scheduleOption: string;
//     stageOrder: number;
//   }
// ) => {
//   const stageIndex = emailStages.findIndex((el) => el.id === data.id);
//   emailStages[stageIndex] = {
//     ...emailStages[stageIndex],
//     ...data,
//   };
//   console.log('stage---', stageIndex, emailStages[stageIndex]);
//   // update scheduleDay for next stageIndex stages if scheduleOption is 'later' and check if skipWeekends is true or false and update scheduleDay accordingly
//   if (data.skipWeekends) {
//     for (let i = stageIndex + 1; i < emailStages.length; i++) {
//       if (emailStages[i - 1].scheduleDay === 5) {
//         emailStages[i].scheduleDay = emailStages[i - 1].scheduleDay + 3;
//       } else if (emailStages[i - 1].scheduleDay === 6) {
//         emailStages[i].scheduleDay = emailStages[i - 1].scheduleDay + 2;
//       } else {
//         emailStages[i].scheduleDay = emailStages[i - 1].scheduleDay + 1;
//       }
//     }
//   } else {
//     for (let i = stageIndex + 1; i < emailStages.length; i++) {
//       emailStages[i].scheduleDay = emailStages[i - 1].scheduleDay + 1;
//     }
//   }

//   console.log('emailStages---', emailStages);
//   return [...emailStages];
// };

// export const getEditSequenceArrayWithEditedEmailStage = (
//   emailStages: EmailStage[]
// ) =>
//   emailStages.map((emailStage) => {
//     return {
//       id: emailStage?._id || '',
//       message: { text: emailStage?.message, loading: false },
//       mode: 'all',
//       scheduleDay: emailStage?.scheduleDay,
//       scheduleTime: emailStage?.scheduleTime || '09:00:00',
//       scheduleTimezone: emailStage?.timezone || getTimeZone(),
//       skipWeekends: emailStage?.skipWeekends,
//       previewId: emailStage?.previewId,
//       scheduleOption: emailStage.scheduleOption,
//       subject: emailStage.subject,
//       replyAs: emailStage.replyAs,
//       status: emailStage?.status,
//       stageOrder: emailStage.stageOrder,
//       //replyFor: emailStage.replyFor,
//     };
//   }) as unknown as EmailStage[];

// export const updateEmailStageOnPreview = (
//   emailStages: EmailStage[],
//   previewData: {
//     message: string;
//     previewId: string;
//     id: string;
//   }
// ) => {
//   const emailStageId = previewData.id;
//   return emailStages.map((el) => {
//     if (el.id === emailStageId) {
//       return {
//         id: el?.id || '',
//         message: { text: previewData?.message, loading: false },
//         mode: 'all',
//         scheduleDay: el?.scheduleDay,
//         scheduleTime: el?.scheduleTime || '09:00:00',
//         scheduleTimezone: el?.scheduleTimezone || getTimeZone(),
//         skipWeekends: el?.skipWeekends,
//         previewId: previewData?.previewId,
//         scheduleOption: el.scheduleOption,
//         subject: el.subject,
//         replyAs: el.replyAs,
//         status: el.status,
//         stageOrder: el.stageOrder,
//         //replyFor: el.replyFor,
//       };
//     } else {
//       return el;
//     }
//   }) as unknown as EmailStage[];
// };

// export const getArrayEmailStagesWithExceptions = (
//   emailStages: EmailStage[],
//   exception: string
// ) => emailStages.filter((el) => el.id !== exception);

// export const getArrayWithNewEmailStageErrors = (
//   errors: EmailStageValidationError[],
//   newError: EmailStageValidationError
// ): EmailStageValidationError[] => {
//   if (!errors.length) {
//     return [newError];
//   }

//   let addedNewError = false;

//   const newErrors = errors.map((el) => {
//     if (el.id === newError.id) {
//       addedNewError = true;

//       return newError;
//     }

//     return el;
//   });

//   return addedNewError ? newErrors : [...newErrors, newError];
// };

// export const getArrayEmailStagesErrorsWithExceptions = (
//   errors: EmailStageValidationError[],
//   exception: string
// ) => (errors.length ? errors.filter((el) => el.id !== exception) : []);

// export const getArraysEmailStagesWithUpdatedMessages = (
//   emailStages: EmailStage[],
//   id: string,
//   message: string,
//   loading = false
// ) => {
//   if (!emailStages.length) {
//     return emailStages;
//   }

//   return emailStages.map((el) =>
//     el.id === id ? { ...el, message: { text: message, loading } } : el
//   );
// };

export const getNormalizedTags = (result: Tags[]): Tags[] =>
  result.map((x: Tags) => ({
    ...x,
    label: x.name.toLowerCase(),
    value: x._id,
    createdBy:
      typeof x.createdBy === 'object' && x.createdBy !== null
        ? x.createdBy?.contacts?.name || ''
        : typeof x.createdBy === 'string'
        ? x.createdBy
        : '',
  }));
