import { Update } from 'history';
import React, { useLayoutEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { Router as BrowserRouter } from 'react-router-dom';

import { Preloader } from 'src/components/atoms/Preloader';
import { PagesSwitch } from 'src/navigation/Switch';
import { history } from 'src/navigation/store';
import { ReduxState } from 'src/reducers';

const reducer = (_: Update, action: Update) => action;

export const Router: React.FC = () => {
  const [state, dispatch] = useReducer(reducer, {
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => {
    const unlisten = history.listen(dispatch);

    return () => {
      unlisten();
    };
  }, []);

  const isLoggedIn = useSelector(
    (state: ReduxState) => state.access.isLoggedIn
  );

  console.log('isLoggedIn', isLoggedIn);

  if (isLoggedIn === null) {
    return <Preloader />;
  }

  const mode = isLoggedIn ? 'authorized' : 'unauthorized';

  return (
    <BrowserRouter
      location={state.location}
      navigationType={state.action}
      navigator={history}
    >
      <main>
        <PagesSwitch mode={mode} />
      </main>
    </BrowserRouter>
  );
};
