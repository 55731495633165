import { Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/es/table';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { Button } from 'src/components/atoms/Button';
import { Input } from 'src/components/atoms/Input';
import { TableDataText } from 'src/components/atoms/TableDataText';
import { useFormik } from 'src/hooks/useFormik';
import { Msg } from 'src/i18n/Msg';
import { ReduxState } from 'src/reducers';
import { Organization } from 'src/types';
import { getIsActiveAction } from 'src/utils/lib';
import { validators } from 'src/utils/validation';

// import { validators } from 'src/utils/validation';
import styles from 'src/components/routes/modals/OrgUsersModal/styles.module.css';

export type Props = {
  data: Organization;
};

export const OrgUsersModal: React.FC<Props> = ({ data }) => {
  const dispatch = useDispatch();

  const onClickCancel = useCallback(
    () => dispatch(actions.ui.modal.hide()),
    [dispatch]
  );

  const { activeActions } = useSelector(
    ({ organization, currentActiveActions, access }: ReduxState) => ({
      organizations: organization.data || [],
      activeActions: currentActiveActions.actions,
      user: access.profile,
    })
  );

  const isFetching = getIsActiveAction(activeActions, [
    actions.api.tags.assignTags.started.type,
  ]);

  const isDone = getIsActiveAction(activeActions, [
    actions.api.tags.assignTags.done.type,
  ]);

  const { handleSubmit, handleChange, values } = useFormik({
    initialValues: {
      email: '',
    },

    validationSchema: validators.email,

    onSubmit: ({ email }) => {
      dispatch(
        actions.api.organization.addUser.started({
          email: [email],
          orgName: data.name,
        })
      );
    },
  });

  const columns = useMemo(() => {
    let columns: ColumnsType<Organization['users']> = [];

    columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
        render: (email: string) => <TableDataText text={email} />,
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        render: (role: string) => <TableDataText text={role} />,
      },
    ];

    return columns;
  }, []);

  return (
    <>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: '1',
            label: 'Users',
            children: (
              <div>
                <Table
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  columns={columns}
                  dataSource={data.users}
                  pagination={false}
                />
              </div>
            ),
          },
          {
            key: '2',
            label: 'Add User',
            children: (
              <div className={classNames('shadowLight', styles.container)}>
                <div className={styles.mainBlock}>
                  <p className={styles.menuTitleText}>
                    <Msg id="components.routes.modals.AddOrgUser.emailLabel" />
                  </p>
                  <Input
                    onChange={handleChange('email')}
                    className={styles.inputStyle}
                    defaultValue={values.email}
                    placeholder={{
                      id: 'components.routes.modals.AddOrgUser.emailPlaceholder',
                    }}
                  />
                </div>

                <div className={styles.footerBlock}>
                  <div className={styles.buttonCancelStyle}>
                    <Button
                      onClick={onClickCancel}
                      text={{
                        id: 'components.routes.modals.AddCampaignModal.cancelButton',
                      }}
                      variant="cancel"
                    />
                  </div>

                  <div className={styles.buttonCreateStyle}>
                    <Button
                      preloader={isFetching}
                      successLoader={isDone}
                      onClick={() => handleSubmit()}
                      text={{
                        id: 'components.routes.modals.AddCampaignModal.okButton',
                      }}
                      variant="create"
                    />
                  </div>
                </div>
              </div>
            ),
          },
        ]}
      />
    </>
  );
};
