import React from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'src/actions';
import { CreatorFilterForm } from 'src/components/organisms/forms/CreatorFilterForm';

export const CreatorFilterModal: React.FC = () => {
  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(actions.ui.modal.hide());
  };

  return <CreatorFilterForm onCancel={onCancel} />;
};
