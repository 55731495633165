// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_avatar__wrapper__na1zZ {
  box-sizing: content-box;
  width: var(--SIZE_96);
  height: var(--SIZE_96);
  border: var(--SIZE_BORDER_FRAME) solid var(--COLOR_FOREGROUND);
  border-radius: var(--RADIUS_50);
  box-shadow: 0 var(--SIZE_12) var(--SIZE_16) -4px var(--COLOR_GRAY_900_HEAVY),
    0 var(--SIZE_4) var(--SIZE_6) -2px var(--COLOR_GRAY_900_EXTRALIGHT);
}

.styles_avatar__image__aql5x {
  width: var(--SIZE_96);
  height: var(--SIZE_96);
  border-radius: var(--RADIUS_50);
  box-shadow: 0 var(--SIZE_12) var(--SIZE_16) -4px var(--COLOR_GRAY_900_HEAVY),
    0 var(--SIZE_4) var(--SIZE_6) -2px var(--COLOR_GRAY_900_EXTRALIGHT);
}

.styles_avatar__verifiedTick__ddCsa {
  position: relative;
  bottom: var(--SIZE_32);
  left: var(--SIZE_70);
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/EditCreatorAvatar/styles.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,qBAAqB;EACrB,sBAAsB;EACtB,8DAA8D;EAC9D,+BAA+B;EAC/B;uEACqE;AACvE;;AAEA;EACE,qBAAqB;EACrB,sBAAsB;EACtB,+BAA+B;EAC/B;uEACqE;AACvE;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,oBAAoB;AACtB","sourcesContent":[".avatar__wrapper {\n  box-sizing: content-box;\n  width: var(--SIZE_96);\n  height: var(--SIZE_96);\n  border: var(--SIZE_BORDER_FRAME) solid var(--COLOR_FOREGROUND);\n  border-radius: var(--RADIUS_50);\n  box-shadow: 0 var(--SIZE_12) var(--SIZE_16) -4px var(--COLOR_GRAY_900_HEAVY),\n    0 var(--SIZE_4) var(--SIZE_6) -2px var(--COLOR_GRAY_900_EXTRALIGHT);\n}\n\n.avatar__image {\n  width: var(--SIZE_96);\n  height: var(--SIZE_96);\n  border-radius: var(--RADIUS_50);\n  box-shadow: 0 var(--SIZE_12) var(--SIZE_16) -4px var(--COLOR_GRAY_900_HEAVY),\n    0 var(--SIZE_4) var(--SIZE_6) -2px var(--COLOR_GRAY_900_EXTRALIGHT);\n}\n\n.avatar__verifiedTick {\n  position: relative;\n  bottom: var(--SIZE_32);\n  left: var(--SIZE_70);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar__wrapper": `styles_avatar__wrapper__na1zZ`,
	"avatar__image": `styles_avatar__image__aql5x`,
	"avatar__verifiedTick": `styles_avatar__verifiedTick__ddCsa`
};
export default ___CSS_LOADER_EXPORT___;
