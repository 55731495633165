import React from 'react';

import { Close } from 'src/components/atoms/icons/interactions/Close';
import { TagItem } from 'src/types';

import styles from 'src/components/atoms/AddLablesDisplay/styles.module.css';

type Props = {
  tags: Array<TagItem | string>;
  canRemove: boolean;
  onRemove: (val: TagItem | string) => void;
};

export const AddLablesDisplay: React.FC<Props> = ({
  tags,
  canRemove,
  onRemove,
}) => (
  <div className={styles.wrapper}>
    {tags?.map((item, i) => (
      <div key={i} className={styles.contentBlock}>
        <p className={styles.textBlock}>
          {typeof item === 'string' ? item : item?.name}
        </p>
        {canRemove && (
          <span role={'none'} onClick={() => onRemove(item)}>
            <Close cursor width={6} height={6} />
          </span>
        )}
      </div>
    ))}
  </div>
);
