import React from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'src/actions';
import { EditCreatorAvatar } from 'src/components/atoms/EditCreatorAvatar';
import { Close } from 'src/components/atoms/icons/interactions/Close';
import { Tabs } from 'src/components/molecules/Tabs';
import { Tags } from 'src/components/molecules/Tags';
import { CreatorOverview } from 'src/components/organisms/CreatorOverview';
import { CreatorForm } from 'src/components/organisms/forms/CreatorForm';
import { Msg } from 'src/i18n/Msg';
import { Influencer } from 'src/types';

import avatar from 'src/assets/images/marla_avatar.jpg';

import styles from 'src/components/routes/modals/EditCreatorModal/styles.module.css';

export type Props = {
  influencer: Influencer;
  //sequenceTitle?: string[];
};

export const EditCreatorModal: React.FC<Props> = ({ influencer }) => {
  const dispatch = useDispatch();

  const onClickCancel = () => {
    dispatch(actions.ui.modal.hide());
    dispatch(actions.ui.sidebar.hide());
  };

  // const { totalCount } = useSelector(({ logs }: ReduxState) => ({
  //   totalCount: logs.totalCount,
  // }));

  return (
    <div className={styles.container}>
      <div className={styles.headerBlock}>
        <div className={styles.mainInfoBlock}>
          <EditCreatorAvatar src={influencer.profile_image || avatar} />

          <div className={styles.afterAvatarBlock}>
            <div className={styles.nameAndBadgeBlock}>
              <p className={styles.nameTitleTextStyle}>
                {influencer.firstName} {influencer.lastName}
              </p>

              <div className={styles.badgeStyle} />
            </div>

            <p className={styles.nameSubTitleTextStyle}>{influencer.email}</p>

            <div className={styles.sequenceBlock}>
              {influencer?.persona_tags?.length !== 0 && (
                <Tags
                  tags={influencer?.persona_tags}
                  maxLimit={influencer?.persona_tags?.length}
                />
              )}
            </div>
          </div>
        </div>

        <button onClick={onClickCancel} className={styles.closeStyle}>
          <Close /> {''}
        </button>
      </div>

      <Tabs
        defaultActiveKey="1"
        style={{
          width: '505px',
        }}
        items={[
          {
            key: '1',
            label: (
              <Msg id="components.organisms.forms.CreatorForm.profileTab" />
            ),
            children: <CreatorForm influencer={influencer} />,
          },

          {
            key: '4',
            label: (
              <>
                <Msg id="components.organisms.forms.CreatorForm.orgTab" />
              </>
            ),
            children: <CreatorOverview influencer={influencer} />,
          },
        ]}
      />
    </div>
  );
};
