import * as yup from 'yup';

import { Events } from 'src/i18n';

const _msg = (message: Events): Events => message;

export const rules = {
  firstName: yup.string().required(_msg('events.error.firstName.required')),

  lastName: yup.string().required(_msg('events.error.lastName.required')),

  email: yup.string().required(_msg('events.error.email.required')),

  password: yup.string().required(_msg('events.error.password.required')),

  projectName: yup.string().required(_msg('events.error.projectName.required')),

  poolName: yup.string().required(_msg('events.error.poolName.required')),

  orgId: yup.string().required(_msg('events.error.orgId.required')),

  text: yup
    .string()
    .max(275, _msg('events.error.text.invalid'))
    .required(_msg('events.error.text.required')),
};

export const validators = {
  influencer: () =>
    yup.object().shape({
      firstName: rules.firstName,
      lastName: rules.lastName,
      email: rules.email,
    }),

  emailStage: () =>
    yup.object().shape({
      text: rules.text,
    }),

  campaign: () =>
    yup.object().shape({
      projectName: rules.projectName,
    }),

  pool: () =>
    yup.object().shape({
      name: rules.poolName,
      orgId: rules.orgId,
    }),

  email: () =>
    yup.object().shape({
      email: rules.email,
    }),

  singUp: () =>
    yup.object().shape({
      email: rules.email,
      password: rules.password,
    }),
  tags: () =>
    yup.object().shape({
      tags: yup
        .array()
        .of(yup.string().required(_msg('events.error.tags.required'))),
      type: yup.string().required(_msg('events.error.tyoe.required')),
    }),
};
