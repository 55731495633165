export const ENDPOINTS = {
  SIGN_IN_WITH_EMAIL: '/sign-in/email',
  SIGN_OUT: '/sign-out',
  SIGN_UP_WITH_EMAIL: '/sign-up/email',
  TODO_WITH_ID: '/todo/:id([a-z0-9-.]+)',
  WS_ECHO: '/ws/echo',
  INFLUENCERS: '/influencer/list',
  INFLUENCER_UPDATE: '/influencer/update/:influencerId',
  GET_ORGANIZATION_ASSIGNED: '/influencer/getOrg/Assigned/:id',
  GET_POOl_ASSIGNED: '/pool/assigned/:id',
  REMOVE_FROM_ORGANIZATION: '/influencer/removeOrg/:influencerId/:orgId',
  SWITCH_INFLUENCER_ORGANIZATION_ORG: '/influencer/switchOrgStatus',
  TAGS_GET: '/tag/list',
  ASSIGN_TAGS: '/tag/assign',
  CREATE_TAGS: '/tag/add-bulk',
  MARK_ACTIVE: '/tag/state',
  GET_ORGANIZATION: '/get/organizations',
  UPDATE_MODULES: '/organizations/update-modules',
  ORG_ADD_USER: '/organization/add-user',
  ASSIGN_ORG: '/assign/organization',
  CREATE_POOL: '/pool/add',
  GET_POOL: '/pool/list',
  ASSIGN_POOl: '/pool/assign-influencer',
  REMOVE_CREATOR_FROM_POOL: '/pool/remove-influencer',
  GET_POOL_DETAIL: '/pool/detail/:id',
  ACTIVATE_POOL: '/pool/activate',
  BRAND_GET: '/brand/list',
  BRAND_CHANGE_ACTIVE_STATUS: '/brand/change-active-status',
} as const;

export type Endpoints = typeof ENDPOINTS;
