import actionsFactory from 'typescript-fsa-factory';

import { ENDPOINTS, Endpoints } from 'src/constants/api';

type Extra = {
  hideSuccessNotification?: boolean;
};

export type ActionCreatorAsync = ReturnType<
  typeof actionCreatorAsyncWithHandler
>;

export const {
  actionCreator,
  actionCreatorAsyncWithHandler,
  registerAsyncActions,
} = actionsFactory<Endpoints, Extra>(ENDPOINTS, {
  withCredentials: true,
});
