import { put, select, takeEvery } from 'redux-saga/effects';

import { actions } from 'src/actions';
import { ReduxState } from 'src/reducers';
import {
  // InfluencerSearchSettings,
  SearchSettings,
} from 'src/types';

function* getBrands() {
  const { limitElements, searchWord, currentPage }: SearchSettings =
    yield select((state: ReduxState) => state.brand.searchSettings);

  yield put(
    actions.api.brand.get.started({
      limit: limitElements,
      search: searchWord,
      page: currentPage,
    })
  );
}

// function* closeModal() {
//   yield put(actions.ui.modal.hide());
// }

// function* resetInfluencerSearchSettings() {
//   yield put(
//     actions.ui.influencers.setSelectedInfluencers({
//       influencers: [],
//     })
//   );
//   const {
//     limitElements,
//     searchWord,
//     currentPage,
//     isInstagram,
//     isTiktok,
//     engagement_status,
//     selectedTags,
//     notInSelectedTags,
//     socialPlatform,
//     minFollowers,
//     maxFollowers,
//     statusFilter,
//     selectedOrganization,
//     sort,
//   }: InfluencerSearchSettings = yield select(
//     (state: ReduxState) => state.influencers.influencerSearchSettings
//   );

//   yield put(
//     actions.api.influencers.getInfluencers.started({
//       limit: limitElements,
//       search: searchWord,
//       page: currentPage,
//       isInstagram,
//       isTiktok,
//       engagement_status,
//       selectedTags: selectedTags?.map((x) => x.id)?.toString(),
//       notInSelectedTags: notInSelectedTags?.map((x) => x.id)?.toString(),
//       socialPlatform: socialPlatform?.toString(),
//       minFollowers,
//       maxFollowers,
//       statusFilter,
//       selectedOrganization: selectedOrganization && selectedOrganization.id,
//       sort: JSON.stringify(sort),
//     })
//   );
// }

export function* brandSaga(): Generator {
  yield takeEvery(
    [
      actions.ui.brand.setSearchSettings,
      actions.api.brand.changeActiveStatus.done,
    ],
    getBrands
  );
}
