import { ColumnView } from 'src/components/molecules/ColumnView';
import { ColumnMenuClick, ColumnsSetting } from 'src/types';

import style from 'src/components/molecules/ColumnList/styles.module.css';

type Props = {
  columns: ColumnsSetting;
  onColumnMenuClicked: ColumnMenuClick;
};

export const ColumnList: React.FC<Props> = ({
  columns,
  onColumnMenuClicked,
}) => {
  return (
    <div className={style.wrapper}>
      {columns.map((_column, index) => {
        return (
          <div className={style.columnList} key={index}>
            <ColumnView
              column={_column}
              columnIndex={index}
              onColumnMenuClicked={onColumnMenuClicked}
              columnKey={_column.key}
            />
          </div>
        );
      })}
    </div>
  );
};
