// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__gtu9s {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: var(--PAGE_MAX_WIDTH);
  min-height: var(--SIZE_400);
  background: var(--COLOR_FOREGROUND);
}

.styles_headerBlock__83pUG {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: var(--PADDING_24) var(--PADDING_24) var(--PADDING_20);
  /* border-bottom: var(--SIZE_BORDER) solid var(--COLOR_PRIMARY_350); */
}

.styles_closeStyle__5jFja {
  padding-top: var(--PADDING_4);
  padding-right: var(--PADDING_4);
  border: 0;
  background: none;
  align-self: flex-start;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/routes/modals/ColumnListModel/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,mBAAmB;EACnB,4BAA4B;EAC5B,2BAA2B;EAC3B,mCAAmC;AACrC;;AAEA;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,8DAA8D;EAC9D,sEAAsE;AACxE;;AAEA;EACE,6BAA6B;EAC7B,+BAA+B;EAC/B,SAAS;EACT,gBAAgB;EAChB,sBAAsB;EACtB,eAAe;AACjB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  width: var(--PAGE_MAX_WIDTH);\n  min-height: var(--SIZE_400);\n  background: var(--COLOR_FOREGROUND);\n}\n\n.headerBlock {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  padding: var(--PADDING_24) var(--PADDING_24) var(--PADDING_20);\n  /* border-bottom: var(--SIZE_BORDER) solid var(--COLOR_PRIMARY_350); */\n}\n\n.closeStyle {\n  padding-top: var(--PADDING_4);\n  padding-right: var(--PADDING_4);\n  border: 0;\n  background: none;\n  align-self: flex-start;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__gtu9s`,
	"headerBlock": `styles_headerBlock__83pUG`,
	"closeStyle": `styles_closeStyle__5jFja`
};
export default ___CSS_LOADER_EXPORT___;
