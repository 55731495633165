import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { CreatorOverviewOrganizationTable } from 'src/components/molecules/CreatorOverviewOrganizationTable';
import { CreatorOverviewPoolTable } from 'src/components/molecules/CreatorOverviewPoolTable';
import { ReduxState } from 'src/reducers';
import { Influencer } from 'src/types';

import styles from 'src/components/organisms/CreatorOverview/styles.module.css';

type Props = {
  influencer: Influencer;
};

export const CreatorOverview: React.FC<Props> = ({ influencer }) => {
  const { assignedOrg, assignedPools } = useSelector(
    ({ influencers }: ReduxState) => ({
      assignedOrg: influencers.assignedOrg,
      assignedPools: influencers.assignedPools,
    })
  );

  const dispatch = useDispatch();
  // const onClick = () => {
  //   dispatch(
  //     actions.ui.modal.show({
  //       name: 'AddCampaignModal',
  //       data: {
  //         campaignId: undefined,
  //         projectName: undefined,
  //         influencerIds: [influencer.id],
  //       },
  //     })
  //   );
  // };

  useEffect(() => {
    dispatch(
      actions.api.influencers.getOrgAssigned.started({
        extra: {
          id: influencer._id,
        },
      })
    );
    dispatch(
      actions.api.influencers.getPoolAssigned.started({
        extra: {
          id: influencer._id,
        },
      })
    );
  }, [dispatch, influencer._id]);

  return (
    <div className={styles.mainBlock}>
      {assignedOrg?.length !== 0 && (
        <div
          className={classNames(styles.inputsBlock, styles.inputsBlockSpacer)}
        >
          <div className={styles.firstInputFromBlock}>
            <CreatorOverviewOrganizationTable
              assignedOrg={assignedOrg || []}
              influencerId={influencer.id}
              influencerName={`${influencer.firstName} ${influencer.lastName}`}
            />
          </div>
        </div>
      )}
      {assignedPools?.length !== 0 && (
        <div
          className={classNames(styles.inputsBlock, styles.inputsBlockSpacer)}
        >
          <div className={styles.firstInputFromBlock}>
            <CreatorOverviewPoolTable
              assignedPool={assignedPools || []}
              influencerId={influencer.id}
            />
          </div>
        </div>
      )}
    </div>
  );
};
