import { Tabs as AntTabs, TabsProps } from 'antd';

import styles from 'src/components/molecules/Tabs/styles.module.css';

const defaultItems: TabsProps['items'] = [
  {
    key: '1',
    label: `Tab 1`,
    children: `Content of Tab Pane 1`,
  },
  {
    key: '2',
    label: `Tab 2`,
    children: `Content of Tab Pane 2`,
  },
  {
    key: '3',
    label: `Tab 3`,
    children: `Content of Tab Pane 3`,
  },
];

type Props = {
  items: TabsProps['items'];
  defaultActiveKey?: TabsProps['defaultActiveKey'];
  style?: React.CSSProperties;
  type?: TabsProps['type'];
  onClick?: (key: string) => void;
  [x: string]: unknown;
};

export const Tabs: React.FC<Props> = ({
  items = defaultItems,
  defaultActiveKey = '1',
  style = {},
  type,
  onClick = () => console.log,
  ...rest
}) => {
  return (
    <AntTabs
      className={styles.wrapper}
      style={style}
      defaultActiveKey={defaultActiveKey}
      items={items}
      type={type}
      onTabClick={onClick}
      {...rest}
    />
  );
};
