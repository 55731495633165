import React from 'react';

import { IconBaseProps } from 'src/types';

export const YoutubeGrey: React.FC<IconBaseProps> = ({
  width = 27,
  height = 27,
  fill = 'none',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
    viewBox="0 0 17 12"
  >
    <path
      d="M16.0965 3.01406C16.0965 3.01406 15.9402 1.91094 15.459 1.42656C14.8496 0.789063 14.1684 0.785937 13.8559 0.748437C11.6184 0.585937 8.25899 0.585938 8.25899 0.585938H8.25273C8.25273 0.585938 4.89336 0.585937 2.65586 0.748437C2.34336 0.785937 1.66211 0.789063 1.05273 1.42656C0.571484 1.91094 0.418359 3.01406 0.418359 3.01406C0.418359 3.01406 0.255859 4.31094 0.255859 5.60469V6.81719C0.255859 8.11094 0.415234 9.40781 0.415234 9.40781C0.415234 9.40781 0.571484 10.5109 1.04961 10.9953C1.65898 11.6328 2.45898 11.6109 2.81523 11.6797C4.09648 11.8016 8.25586 11.8391 8.25586 11.8391C8.25586 11.8391 11.6184 11.8328 13.8559 11.6734C14.1684 11.6359 14.8496 11.6328 15.459 10.9953C15.9402 10.5109 16.0965 9.40781 16.0965 9.40781C16.0965 9.40781 16.2559 8.11406 16.2559 6.81719V5.60469C16.2559 4.31094 16.0965 3.01406 16.0965 3.01406ZM6.60274 8.28906V3.79219L10.9246 6.04844L6.60274 8.28906Z"
      fill="#344054"
    />
  </svg>
);
