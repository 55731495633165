import notification from 'antd/lib/notification';
import { ReactNode } from 'react';

export type notificationProps = {
  title?: ReactNode;
  content: ReactNode;
  type: 'success' | 'error' | 'info' | 'warning';
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  duration?: number;
  className?: 'string';
  clickHandel?: () => void;
  onClose?: () => void;
  key?: string;
  icon?: string | ReactNode;
};

export const showNotification = ({
  content,
  title = 'Alert',
  placement = 'bottomLeft',
  clickHandel,
  duration,
  type,
  className,
  onClose,
  key,
  icon,
}: notificationProps) => {
  //const [api] = notification.useNotification();
  const config = {
    description: content,
    message: title,
    placement,
    onClick: clickHandel,
    duration,
    className,
    onClose,
    key,
    icon,
  };

  switch (type) {
    case 'success':
      notification.success(config);
      break;
    case 'error':
      notification.error(config);
      break;
    case 'info':
      notification.info(config);
      break;
    case 'warning':
      notification.warning(config);
      break;
    default:
      notification.open(config);
      break;
  }
};
