// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__q2srt {
  color: transparent;
}

.styles_wrapper__q2srt .ant-tabs-tab-btn {
  color: var(--COLOR_GRAY_500) !important;
  font-size: var(--FONT_SIZE_SUB_TITLE);
  font-family: var(--FONT_DEFAULT);
  font-weight: var(--WEIGHT_NORMAL);
  line-height: var(--FONT_SIZE_24);
  text-shadow: none;
  outline-color: transparent !important;
}

.styles_wrapper__q2srt .ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--COLOR_PRIMARY_600) !important;
  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  outline-color: transparent !important;
}

.styles_wrapper__q2srt .ant-tabs-nav {
  margin: 0px;
}

.styles_wrapper__q2srt .ant-tabs-ink-bar {
  background-color: var(--COLOR_PRIMARY_700);
}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/Tabs/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,uCAAuC;EACvC,qCAAqC;EACrC,gCAAgC;EAChC,iCAAiC;EACjC,gCAAgC;EAChC,iBAAiB;EACjB,qCAAqC;AACvC;;AAEA;EACE,0CAA0C;EAC1C,gDAAgD;EAChD,qCAAqC;AACvC;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,0CAA0C;AAC5C","sourcesContent":[".wrapper {\n  color: transparent;\n}\n\n.wrapper :global(.ant-tabs-tab-btn) {\n  color: var(--COLOR_GRAY_500) !important;\n  font-size: var(--FONT_SIZE_SUB_TITLE);\n  font-family: var(--FONT_DEFAULT);\n  font-weight: var(--WEIGHT_NORMAL);\n  line-height: var(--FONT_SIZE_24);\n  text-shadow: none;\n  outline-color: transparent !important;\n}\n\n.wrapper :global(.ant-tabs-tab-active .ant-tabs-tab-btn) {\n  color: var(--COLOR_PRIMARY_600) !important;\n  text-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);\n  outline-color: transparent !important;\n}\n\n.wrapper :global(.ant-tabs-nav) {\n  margin: 0px;\n}\n\n.wrapper :global(.ant-tabs-ink-bar) {\n  background-color: var(--COLOR_PRIMARY_700);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__q2srt`
};
export default ___CSS_LOADER_EXPORT___;
