import { put, takeEvery } from 'redux-saga/effects';

import { actions } from 'src/actions';

function* getOrganization() {
  yield put(
    actions.api.organization.getOrganization.started({
      page: 1,
      limit: 100,
    })
  );
  yield put(actions.ui.modal.hide());
}

export function* organizationSaga(): Generator {
  yield takeEvery([actions.api.organization.addUser.done], getOrganization);
}
