import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'src/actions';
import { Brand } from 'src/types';

export type State = {
  data?: Brand[];
  count: number;
  searchSettings?: {
    searchWord: string;
    limitElements: number;
    currentPage: number;
  };
};

const initialState: State = {
  data: [],
  count: 0,
  searchSettings: {
    searchWord: '',
    limitElements: 50,
    currentPage: 1,
  },
};

const reducer = reducerWithInitialState<State>(initialState)
  .cases(
    [actions.api.brand.get.done],
    (state, payload): State => ({
      ...state,
      data: [...payload.result.result],
      count: payload.result?.count ?? 0,
    })
  )
  .cases(
    [actions.ui.brand.setSearchSettings],
    (state, payload): State => ({
      ...state,
      searchSettings: {
        ...state.searchSettings,
        ...payload,
      },
    })
  );

export const brand = { reducer, initialState };
