import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { Button } from 'src/components/atoms/Button';
import { SelectWithSearch } from 'src/components/atoms/SelectWithSearch';
import { Msg } from 'src/i18n/Msg';
import { ReduxState } from 'src/reducers';
import { Pool } from 'src/types';
import { getIsActiveAction } from 'src/utils/lib';

import styles from 'src/components/routes/modals/AssignPoolModal/styles.module.css';

export type Props = {
  poolId?: string;
};

export const AssignPoolModal: React.FC<Props> = ({ poolId }) => {
  const dispatch = useDispatch();

  const [select, setSelect] = useState<string>();

  const onClickCancel = () => dispatch(actions.ui.modal.hide());

  const onChange = (value: string) => {
    dispatch(
      actions.api.pool.getPools.started({
        limit: 20,
        search: value,
        page: 1,
      })
    );
  };

  const { pools, activeActions, influencers, userId } = useSelector(
    ({ currentActiveActions, influencers, pool, access }: ReduxState) => ({
      influencers: influencers.selectedInfluencers,
      activeActions: currentActiveActions.actions,
      pools: pool.data,
      userId: access.profile?._id,
    })
  );

  const isFetching = getIsActiveAction(activeActions, [
    actions.api.influencers.assignOrg.started.type,
  ]);

  const isDone = getIsActiveAction(activeActions, [
    actions.api.influencers.assignOrg.done.type,
  ]);

  const onCreate = () => {
    //const campaign = campaigns?.find((el: Campaign) => el._id === select);
    console.log('influencers', influencers);
    dispatch(
      actions.api.pool.assignPool.started({
        id: select || '',
        influencerIds: influencers || [],
        updatedBy: userId || '',
        extra: {
          notificationsParams: {
            content: 'Successfully Added',
            type: 'success',
          },
        },
      })
    );
  };

  const options = pools
    ?.filter((x: Pool) => x._id !== poolId)
    ?.map((el: Pool) => ({
      label: `${el.name} - ${el.orgName}`,
      value: el._id,
    }));

  useEffect(() => {
    dispatch(
      actions.api.pool.getPools.started({
        page: 1,
        limit: 20,
        search: '',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames('shadowLight', styles.container)}>
      <div className={styles.headerBlock}>
        <p className={styles.titleStyle}>
          <Msg id="components.organisms.TopMenu.addAssignPool" />
        </p>
      </div>

      <div className={styles.mainBlock}>
        <div className={styles.selectMenuBlock}>
          <SelectWithSearch
            onChange={onChange}
            onSelect={setSelect}
            options={options}
            isDebounce
            dropdownStyle={{ zIndex: 10000 }}
            placeholder={{
              id: 'components.routes.modals.AddPool.placeholder',
            }}
          />
        </div>
      </div>

      <div className={styles.footerBlock}>
        <div className={styles.buttonCancelStyle}>
          <Button
            onClick={onClickCancel}
            text={{
              id: 'components.routes.modals.AddCampaignModal.cancelButton',
            }}
            variant="cancel"
          />
        </div>

        <div className={styles.buttonCreateStyle}>
          <Button
            preloader={isFetching}
            successLoader={isDone}
            onClick={onCreate}
            disabled={!select}
            text={{
              id: 'components.routes.modals.AddCampaignModal.okButton',
            }}
            variant="create"
          />
        </div>
      </div>
    </div>
  );
};
