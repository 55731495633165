import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'src/actions';
import { User } from 'src/types';

export type State = {
  isLoggedIn: null | boolean;
  profile?: User;
};

const initialState: State = {
  isLoggedIn: false,
  profile: undefined,
};

const reducer = reducerWithInitialState<State>(initialState)
  .cases(
    [
      actions.api.access.signUpWithEmail.started,
      actions.api.access.signInWithEmail.started,
    ],
    (state): State => ({
      ...state,
      isLoggedIn: null,
      profile: undefined,
    })
  )
  .cases(
    [
      actions.api.access.signUpWithEmail.done,
      actions.api.access.signInWithEmail.done,
    ],
    (state, payload): State => ({
      ...state,
      isLoggedIn: true,
      profile: payload.result,
    })
  )
  .case(
    actions.api.access.signOut.done,
    (state): State => ({
      ...state,
      isLoggedIn: false,
      profile: undefined,
    })
  );

export const access = { initialState, reducer };
