import React from 'react';
import { IntlProvider as Provider } from 'react-intl';

import { defaultLocale, locales } from 'src/i18n';

type IntlProviderProps = {
  children: React.ReactNode;
};

export const IntlProvider: React.FC<IntlProviderProps> = ({ children }) => {
  const locale = defaultLocale; // TODO: automatic language detection

  return (
    <Provider
      defaultLocale={defaultLocale}
      key={locale}
      locale={locale}
      messages={locales[locale].dictionary}
      textComponent={React.Fragment}
    >
      {children}
    </Provider>
  );
};
