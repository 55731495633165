// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
// If you want to change the content of this file, you need to edit
// file '/dictionaries/en.po' and run '.po' extractor
export const events = {
  'events.error.day.required': 'Please enter the day',
  'events.error.email.required': 'Please enter your email',
  'events.error.firstName.required': 'Please enter your first name',
  'events.error.lastName.required': 'Please enter your last name',
  'events.error.message.required': 'This is an error message.',
  'events.error.orgId.required': 'Please select organization',
  'events.error.password.required': 'Please enter your password',
  'events.error.poolName.required': 'Please enter the pool name',
  'events.error.projectName.required': 'Please enter the project name',
  'events.error.tags.required': 'Please enter tags name',
  'events.error.text.invalid': 'The description is not valid',
  'events.error.text.required': 'Please enter the description',
  'events.error.tyoe.required': 'Please select the type',
  'events.error.type.required': 'Please select tag type',

  'events.forms.notCompletely': 'The form is incomplete',
};
