import React from 'react';

import styles from 'src/components/atoms/TableDataText/styles.module.css';

type Props = {
  text: string;
  color?: string;
  textLimit?: number;
};

export const TableDataText: React.FC<Props> = ({
  text,
  textLimit = 50,
  color = '#667085',
}) => {
  return (
    <div className={styles.block}>
      <p style={{ color }} className={styles.text}>
        {text && text.length > textLimit
          ? `${text.slice(0, textLimit)}...`
          : text}
      </p>
    </div>
  );
};
