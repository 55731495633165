// THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY
// If you want to change the content of this file, you need to edit
// file '/dictionaries/en.po' and run '.po' extractor
export const base = {
  'component.organisms.form.CreateSequenceNameForm.placeholder':
    'Enter Sequence Name',

  'component.organisms.lable.value': '{value}',

  'components.atoms.ErrorNotification.errorText': 'Error',

  'components.atoms.ErrorsInfo.titleText': 'Errors (fix before saving)',

  'components.atoms.LeftMenuGmail.leftMenuGmailText': 'Gmail',
  'components.atoms.LeftMenuGmail.useGmailSignatureText': 'Use gmail signature',

  'components.atoms.LeftMenuTracking.clickTrackingForLinksText':
    'Click tracking for links',
  'components.atoms.LeftMenuTracking.leftMenuTrackingText': 'Tracking',
  'components.atoms.LeftMenuTracking.openMessageTrackingText':
    'Open message tracking',
  'components.atoms.LeftMenuTracking.replyTrackingText': 'Reply tracking',

  'components.atoms.ReviewSequenceInfo.titleText': 'Review your sequence',

  'components.atoms.Selected.selectedText': 'Selected',

  'components.atoms.ShowingMenu.showingText': 'Showing 1-{selected} of',

  'components.atoms.Upcoming.comingSoon': 'Coming Soon',

  'components.modals.Reason.submitButton': 'Submit',

  'components.modals.ReasonModal.placeholder': 'custom Reason',

  'components.molecules.AddAnotherEmailCard.addAnotherEmailButton':
    '+Add another email',
  'components.molecules.AddAnotherEmailCard.emailTitleText': 'Email {index}',

  'components.molecules.AddCreatorsFromCampaign.addCreatorsButton':
    'Add Creators',
  'components.molecules.AddCreatorsFromCampaign.selectPlaceholder':
    'Search for campaign',
  'components.molecules.AddCreatorsFromCampaign.titleText':
    'Add creators from a campaign',

  'components.molecules.AddCreatorsIndividually.searchPlaceholder':
    'Name, Email, Handle...',
  'components.molecules.AddCreatorsIndividually.titleText':
    'Add creators individually',

  'components.molecules.AllEmailsTopMenu.allEmailsSelectText': 'All Emails',
  'components.molecules.AllEmailsTopMenu.bouncedText': 'Bounced',
  'components.molecules.AllEmailsTopMenu.clickedText': 'Clicked',
  'components.molecules.AllEmailsTopMenu.interestedText': 'Interested',
  'components.molecules.AllEmailsTopMenu.openedText': 'Opened',
  'components.molecules.AllEmailsTopMenu.repliedText': 'Replied',

  'components.molecules.DropdownAddCreators.addCreatorsText':
    'Add creators individually',
  'components.molecules.DropdownAddCreators.noCreatorFoundText':
    'No creator found.',

  'components.molecules.DropdownAddEmails.addToAnotherCampaignText':
    'Add to another campaign',
  'components.molecules.DropdownAddEmails.addToAnotherEmailSequenceText':
    'Add to another email sequence',
  'components.molecules.DropdownAddEmails.removeFromSequenceText':
    'Remove from sequence',

  'components.molecules.DropdownContinueEmails.candidateText': 'Candidate',
  'components.molecules.DropdownContinueEmails.continueWithLaterEmailsText':
    'Continue with later emails',
  'components.molecules.DropdownContinueEmails.editCreatorProfileText':
    'Edit creator profile',
  'components.molecules.DropdownContinueEmails.removeFromSequenceText':
    'Remove from sequence',
  'components.molecules.DropdownContinueEmails.viewActivityFeedText':
    'View activity feed',

  'components.molecules.DropdownEditEmails.candidateText': 'Candidate',
  'components.molecules.DropdownEditEmails.editCreatorProfileText':
    'Edit creator profile',
  'components.molecules.DropdownEditEmails.editEmailsText': 'Edit emails',
  'components.molecules.DropdownEditEmails.markAsRepliedText':
    'Mark as replied',
  'components.molecules.DropdownEditEmails.removeFromSequenceText':
    'Remove from sequence',
  'components.molecules.DropdownEditEmails.resumeScheduleText':
    'Resume schedule',
  'components.molecules.DropdownEditEmails.viewActivityFeedText':
    'View activity feed',

  'components.molecules.DropdownViewEmails.addToAnotherCampaignText':
    'Add to another campaign',
  'components.molecules.DropdownViewEmails.addToAnotherEmailSequenceText':
    'Add to another email sequence',
  'components.molecules.DropdownViewEmails.addToFolderText': 'Add to folder',
  'components.molecules.DropdownViewEmails.archiveThisSequenceText':
    'Archive this sequence',
  'components.molecules.DropdownViewEmails.candidateText': 'Candidate',
  'components.molecules.DropdownViewEmails.continueWithLaterEmailsText':
    'Continue with later emails',
  'components.molecules.DropdownViewEmails.deleteThisSequenceText':
    'Delete this sequence',
  'components.molecules.DropdownViewEmails.duplicateThisSequenceText':
    'Duplicate this sequence',
  'components.molecules.DropdownViewEmails.editCreatorProfileText':
    'Edit creator profile',
  'components.molecules.DropdownViewEmails.editEmailsText': 'Edit emails',
  'components.molecules.DropdownViewEmails.markAsRepliedText':
    'Mark as replied',
  'components.molecules.DropdownViewEmails.removeFromSequenceText':
    'Remove from sequence',
  'components.molecules.DropdownViewEmails.resumeScheduleText':
    'Resume schedule',
  'components.molecules.DropdownViewEmails.searchSequenceText':
    'Search sequence',
  'components.molecules.DropdownViewEmails.viewActivityFeedText':
    'View activity feed',
  'components.molecules.DropdownViewEmails.viewEmailsText': 'View Emails',

  'components.molecules.Dropzone.clickFirstText': 'Click to upload',
  'components.molecules.Dropzone.clickSecondText':
    'or drag and drop .CSV only (Max 5 MB)',
  'components.molecules.Dropzone.fileName': 'Creator {fileName}',
  'components.molecules.Dropzone.fileSize': '{fileSize} MB',
  'components.molecules.Dropzone.tryAgainText': 'Try again',
  'components.molecules.Dropzone.uploadFailedText':
    'Upload failed, please try again',

  'components.molecules.EmailScheduledMenu.atText': '({date}) at',
  'components.molecules.EmailScheduledMenu.emailScheduledInText':
    'Email scheduled in',

  'components.molecules.ImageDropzone.clickBottomText':
    'PNG or JPG (max. 500x500px)',
  'components.molecules.ImageDropzone.clickFirstText': 'Click to upload',
  'components.molecules.ImageDropzone.clickSecondText': 'or drag and drop',
  'components.molecules.ImageDropzone.fileName': 'CFC {fileName}',
  'components.molecules.ImageDropzone.fileSize': '{fileSize} KB',
  'components.molecules.ImageDropzone.tryAgainText': 'Try again',

  'components.molecules.LeftBar.Search.placeholder': 'Search',
  'components.molecules.LeftBar.mainName': 'HEY SEVA',
  'components.molecules.LeftBar.menuCampaign': 'Campaign',
  'components.molecules.LeftBar.menuCreatorSearch': 'Creator Search',
  'components.molecules.LeftBar.menuEmailSequence': 'Email Sequence',
  'components.molecules.LeftBar.menuHome': 'Home',
  'components.molecules.LeftBar.menuPayment': 'Payment',
  'components.molecules.LeftBar.menuSettings': 'Settings',
  'components.molecules.LeftBar.menuSupport': 'Support',
  'components.molecules.LeftBar.menuUsers': 'Users',

  'components.molecules.OptionsTopMenu.editButton': 'Edit',
  'components.molecules.OptionsTopMenu.optionsButton': 'Options',

  'components.molecules.PreviewEmails.titleText': 'Preview emails by creator',

  'components.molecules.ReviewScreen.creators': '{numberInfluencers} creators',
  'components.molecules.ReviewScreen.firstMessage':
    'First message sends immediately',
  'components.molecules.ReviewScreen.save':
    "All set? Press 'Send Messages and Save' below to start this sequence.",
  'components.molecules.ReviewScreen.stages':
    '{numberStages} stages - each recipient will receive up to {numberStages} messages if no reply',

  'components.molecules.SelectOptionForCreators.byContinuingText':
    'By continuing with this option, recipients without emails will be automatically removed.',
  'components.molecules.SelectOptionForCreators.onDemandText':
    'On-Demand | Find missing emails of creators',
  'components.molecules.SelectOptionForCreators.removeFromSequenceText':
    'Remove from sequence',
  'components.molecules.SelectOptionForCreators.sequenceText': 'Coming soon',
  'components.molecules.SelectOptionForCreators.titleText':
    'Select an option for {creatorsCount} creators without emails.',
  'components.molecules.SelectOptionForCreators.wellSearchText':
    "We'll search for emails, and send sequences automatically when emails are found. Charged at just $3 per email search. ",

  'components.molecules.SummaryTopMenu.titleText': 'Summary',

  'components.molecules.TableCampaignsHeader.columnsText': 'Columns',
  'components.molecules.TableCampaignsHeader.filtersText': 'Filters',
  'components.molecules.TableCampaignsHeader.placeholderText':
    'Name,Email etc...',
  'components.molecules.TableCampaignsHeader.sequenceText': '{count} Projects',
  'components.molecules.TableCampaignsHeader.titleText': 'All Campaigns',

  'components.molecules.TableCreatorFooter.next': 'Next',
  'components.molecules.TableCreatorFooter.previous': 'Previous',

  'components.molecules.TableCreatorsHeader.creatorListText': 'Creator List',

  'components.molecules.TableEmailHeader.placeholder': 'Sequence name',
  'components.molecules.TableEmailHeader.sequence': '{countSequence} Sequence',

  'components.molecules.TagsFilterBadge.selectedOrganizations': 'Org: {org}',
  'components.molecules.TagsFilterBadge.notInSelectedOrganizations':
    'Not in Org: {org}',
  'components.molecules.TagsFilterBadge.selectedPool': 'Pool: {pool}',

  'components.molecules.WarningEmailSequence.fixIssuesButton': 'Fix Issues',
  'components.molecules.WarningEmailSequence.warningText':
    'We found potential issues with your sequence.',

  'components.organisms.AddCreatorBlock.addCreatorDetailsText':
    'Add creator details.',
  'components.organisms.AddCreatorBlock.addCreatorsText':
    'Add creators individually',
  'components.organisms.AddCreatorBlock.cancelButton': 'Cancel',
  'components.organisms.AddCreatorBlock.exploreFromInstagramAndTikTok':
    '1 click sourcing from Instagram & TikTok',
  'components.organisms.AddCreatorBlock.exploreFromInstagramAndTiktokText':
    'Open Instagram & TikTok to add creators',
  'components.organisms.AddCreatorBlock.exploreFromText':
    'Explore from Creator Search',
  'components.organisms.AddCreatorBlock.findCreatorsText':
    'Find creators from our vetted database.',
  'components.organisms.AddCreatorBlock.getASampleFirstText':
    'Get a sample template for the sheet',
  'components.organisms.AddCreatorBlock.getASampleSecondText': 'here.',
  'components.organisms.AddCreatorBlock.nextButton': 'Next',
  'components.organisms.AddCreatorBlock.resetButton': 'Reset',
  'components.organisms.AddCreatorBlock.startNowButton': 'Start Now',
  'components.organisms.AddCreatorBlock.title': 'Start adding creators',
  'components.organisms.AddCreatorBlock.uploadCSVText':
    'Upload a CSV of your creator database.',
  'components.organisms.AddCreatorBlock.uploadText': 'Upload your list',

  'components.organisms.EditEmailsScreen.duplicateButton':
    'Duplicate from existing email',

  'components.organisms.PageTopMenu.NameSearch.placeholder': 'Name',
  'components.organisms.PageTopMenu.activatePool': 'Activate Pool',
  'components.organisms.PageTopMenu.addTags': 'Create Tags',
  'components.organisms.PageTopMenu.createPool': 'Create Pool',
  'components.organisms.PageTopMenu.deactivatePool': 'Deactivate Pool',

  'components.organisms.PreviewEmails.titleText': 'Preview emails by creator',

  'components.organisms.SelectSocialChannel.instagram': 'Instagram',
  'components.organisms.SelectSocialChannel.text': 'Select Social Channel:',
  'components.organisms.SelectSocialChannel.tiktok': 'Tiktok',
  'components.organisms.SelectSocialChannel.youtube': 'Youtube',

  'components.organisms.SelectionScreen.addCampaign':
    'Add creators from a campaign',
  'components.organisms.SelectionScreen.addCreators':
    'Add creators individually',
  'components.organisms.SelectionScreen.placeholder': 'Search for campaign',

  'components.organisms.SequenceTopMenu.menuEdit': '2. Edit Emails',
  'components.organisms.SequenceTopMenu.menuManage': '1. Manage Creator',
  'components.organisms.SequenceTopMenu.menuReview': '3. Review & Configure',

  'components.organisms.TableCampaigns.by': 'By',
  'components.organisms.TableCampaigns.createdAt': 'Date Created',
  'components.organisms.TableCampaigns.description': 'Description',
  'components.organisms.TableCampaigns.noOfCreators': 'No. of Creators',
  'components.organisms.TableCampaigns.projectName': 'Name',
  'components.organisms.TableCampaigns.sharedWith': 'Shared with',
  'components.organisms.TableCampaigns.updatedAt': 'Last Updated',

  'components.organisms.TableCreators.UserTags': 'Tags',
  'components.organisms.TableCreators.adminTags': 'Admin Tags',
  'components.organisms.TableCreators.agency': 'Agency',
  'components.organisms.TableCreators.bio': 'Bio',
  'components.organisms.TableCreators.campaign': 'Campaign',
  'components.organisms.TableCreators.country': 'Country',
  'components.organisms.TableCreators.edit': 'Edit',
  'components.organisms.TableCreators.email': 'Email',
  'components.organisms.TableCreators.emailSequence': 'Email Sequence',
  'components.organisms.TableCreators.engRate': 'Eng Rate',
  'components.organisms.TableCreators.estimatedRate': 'Estimated Rate',
  'components.organisms.TableCreators.firstName': 'First Name',
  'components.organisms.TableCreators.followers': 'Followers',
  'components.organisms.TableCreators.fullName': 'Full Name',
  'components.organisms.TableCreators.instagramFollowers':
    'Instagram Followers',
  'components.organisms.TableCreators.lastActivity': 'Last Activity',
  'components.organisms.TableCreators.lastName': 'Last Name',
  'components.organisms.TableCreators.notes': 'Notes',
  'components.organisms.TableCreators.personaTags': 'Persona Tags',
  'components.organisms.TableCreators.sourcer': 'Sourcer',
  'components.organisms.TableCreators.tiktokFollowers': 'Tiktok Followers',
  'components.organisms.TableCreators.youtubeFollowers': 'Youtube Followers',

  'components.organisms.TableEmailSequence.clicked': 'Clicked',
  'components.organisms.TableEmailSequence.creators': 'Creators',
  'components.organisms.TableEmailSequence.emailCounter': 'Email Counter',
  'components.organisms.TableEmailSequence.finished': 'Finished',
  'components.organisms.TableEmailSequence.interested': 'Interested',
  'components.organisms.TableEmailSequence.lastActivity': 'Last Activity',
  'components.organisms.TableEmailSequence.open': 'Open',
  'components.organisms.TableEmailSequence.owner': 'Owner',
  'components.organisms.TableEmailSequence.replied': 'Replied',
  'components.organisms.TableEmailSequence.scheduled': 'Scheduled',
  'components.organisms.TableEmailSequence.sequenceName': 'Sequence Name',
  'components.organisms.TableEmailSequence.status': 'Status',
  'components.organisms.TableEmailSequence.titleOne': 'Your Sequences',
  'components.organisms.TableEmailSequence.titleTwo': 'All Sequences',

  'components.organisms.TablePool.Active': 'Active',
  'components.organisms.TablePool.CreatedAt': 'Created Date',
  'components.organisms.TablePool.CreatedBy': 'Created By',
  'components.organisms.TablePool.Name': 'Name',
  'components.organisms.TablePool.NoOfInfluencers': 'No. of Influencers',
  'components.organisms.TablePool.Organization': 'Organization',
  'components.organisms.TablePool.UpdatedBy': 'Updated By',
  'components.organisms.TablePool.scheduleDate': 'Schedule Date',

  'components.organisms.TableSelectedCreators.button': 'Remove{count}Creators',
  'components.organisms.TableSelectedCreators.country': 'Country',
  'components.organisms.TableSelectedCreators.createdAt': 'Date Created',
  'components.organisms.TableSelectedCreators.email': 'Email',
  'components.organisms.TableSelectedCreators.followers': 'Followers',
  'components.organisms.TableSelectedCreators.fullName': 'Full Name',
  'components.organisms.TableSelectedCreators.youtubeFollowers':
    'Youtube Followers',

  'components.organisms.TableTags.Active': 'Active',
  'components.organisms.TableTags.CreatedAt': 'Created Date',
  'components.organisms.TableTags.CreatedBy': 'Created By',
  'components.organisms.TableTags.IsGlobal': 'Is Global',
  'components.organisms.TableTags.Name': 'Name',
  'components.organisms.TableTags.NoOfAssignInfluencers':
    'No. of Assign Influencers',

  'components.organisms.TopMenu.Search.placeholder': 'Name, Email, Handle...',
  'components.organisms.TopMenu.actions': 'Actions',
  'components.organisms.TopMenu.addAssignPool': 'Assign Pool',
  'components.organisms.TopMenu.addAssignTags': 'Assign Tags',
  'components.organisms.TopMenu.addOrganizationButton': 'Add to organization',
  'components.organisms.TopMenu.button': 'Add Creator(s)',
  'components.organisms.TopMenu.buttonCampaign': 'Add to Campaign',
  'components.organisms.TopMenu.engagement_status': 'engagement_status',
  'components.organisms.TopMenu.status': 'Status',

  'components.organisms.creatorOverview.organization.name': 'Organization',
  'components.organisms.creatorOverview.pool.name': 'Pool Name',
  'components.organisms.creatorOverview.pool.scheduleDate': 'Schedule Date',

  'components.organisms.form.ReasonModal.cancelButton': 'Cancel',

  'components.organisms.forms.CheckEmailForm.backToLogInText': 'Back to log in',
  'components.organisms.forms.CheckEmailForm.doNotReceiveText':
    'Don’t receive the email?',
  'components.organisms.forms.CheckEmailForm.resendEmailButton': 'Resend email',
  'components.organisms.forms.CheckEmailForm.subTitleText':
    "We've sent a password reset link to",
  'components.organisms.forms.CheckEmailForm.titleText': 'Check your email',
  'components.organisms.forms.CreateAccountForm.alreadyHaveText':
    'Already have an account?',
  'components.organisms.forms.CreateAccountForm.createAccountButton':
    'Create account',
  'components.organisms.forms.CreateAccountForm.emailPlaceholderText':
    'Enter your email',
  'components.organisms.forms.CreateAccountForm.emailText': 'Email*',
  'components.organisms.forms.CreateAccountForm.logoText': 'HEY SEVA',
  'components.organisms.forms.CreateAccountForm.mustBeText':
    'Must be at least 8 characters.',
  'components.organisms.forms.CreateAccountForm.namePlaceholderText':
    'Enter your name',
  'components.organisms.forms.CreateAccountForm.nameText': 'Name*',
  'components.organisms.forms.CreateAccountForm.passwordPlaceholderText':
    'Create a password',
  'components.organisms.forms.CreateAccountForm.passwordText': 'Password*',
  'components.organisms.forms.CreateAccountForm.signInText': 'Sign in',
  'components.organisms.forms.CreateAccountForm.signInWithGoogleButton':
    'Sign in with Google',
  'components.organisms.forms.CreateAccountForm.subTitleText':
    'Start your 30-day free trial!',
  'components.organisms.forms.CreateAccountForm.titleText': 'Create an account',
  'components.organisms.forms.CreateCampaignForm.cancelButton': 'Cancel',
  'components.organisms.forms.CreateCampaignForm.characters':
    '275 characters left',
  'components.organisms.forms.CreateCampaignForm.createButton':
    'Create Campaign',
  'components.organisms.forms.CreateCampaignForm.description':
    'Description (Optional)',
  'components.organisms.forms.CreateCampaignForm.inputPlaceholder':
    'CFC Launch Seeding',
  'components.organisms.forms.CreateCampaignForm.inputProjectName':
    'Project Name',
  'components.organisms.forms.CreateCampaignForm.project': 'Project name*',
  'components.organisms.forms.CreateCampaignForm.subTitle':
    'Please enter a name for this project.',
  'components.organisms.forms.CreateCampaignForm.textAreaDescriptionPlaceholder':
    'Project Description',
  'components.organisms.forms.CreateCampaignForm.textAreaPlaceholder':
    'Seeding product for CFC launch',
  'components.organisms.forms.CreateCampaignForm.title': 'Create new campaign',
  'components.organisms.forms.CreatorForm.addLablesSearchText':
    'Search for label',
  'components.organisms.forms.CreatorForm.addLablesText': 'Tags',
  'components.organisms.forms.CreatorForm.adminTags': 'Admin Tags',
  'components.organisms.forms.CreatorForm.agencyText': 'Agency',
  'components.organisms.forms.CreatorForm.bioText': 'Bio',
  'components.organisms.forms.CreatorForm.cancelButton': 'Cancel',
  'components.organisms.forms.CreatorForm.countryText': 'Country',
  'components.organisms.forms.CreatorForm.emailAddressText': 'Email address',
  'components.organisms.forms.CreatorForm.estimatedRateSymbol': 'A$',
  'components.organisms.forms.CreatorForm.estimatedRateText': 'Estimated Rate',
  'components.organisms.forms.CreatorForm.firstNameText': 'First name',
  'components.organisms.forms.CreatorForm.instagramText': 'Instagram',
  'components.organisms.forms.CreatorForm.lastNameText': 'Last name',
  'components.organisms.forms.CreatorForm.orgTab': 'Organization',
  'components.organisms.forms.CreatorForm.profileTab': 'Profile',
  'components.organisms.forms.CreatorForm.secondaryEmail': 'Secondary Email',
  'components.organisms.forms.CreatorForm.selectCountry': 'country',
  'components.organisms.forms.CreatorForm.socialLink': 'Social Links',
  'components.organisms.forms.CreatorForm.socialText': 'Followers',
  'components.organisms.forms.CreatorForm.socialUserName': 'Social Username',
  'components.organisms.forms.CreatorForm.tiktokText': 'Tiktok',
  'components.organisms.forms.CreatorForm.updateButton': 'Update',
  'components.organisms.forms.CreatorForm.youtubeText': 'Youtube',
  'components.organisms.forms.EditEmailForm.EmailTitle': 'Email {index}',
  'components.organisms.forms.EditEmailForm.asReplyText': 'As a reply',
  'components.organisms.forms.EditEmailForm.charactersLeftText':
    '275 characters left',
  'components.organisms.forms.EditEmailForm.editingForAllText':
    'Editing for all',
  'components.organisms.forms.EditEmailForm.emailScheduledText':
    'Email scheduled for',
  'components.organisms.forms.EditEmailForm.firstName': '{{firstname}}',
  'components.organisms.forms.EditEmailForm.ifNoReplyText': 'If no reply',
  'components.organisms.forms.EditEmailForm.ig.followers': '{{ig_followers}}',
  'components.organisms.forms.EditEmailForm.lastName': '{{lastname}}',
  'components.organisms.forms.EditEmailForm.normalTextDropdown': 'Normal text',
  'components.organisms.forms.EditEmailForm.nowDropdown': 'now',
  'components.organisms.forms.EditEmailForm.othersDropdown': 'Others',
  'components.organisms.forms.EditEmailForm.personalizeText': 'Personalize',
  'components.organisms.forms.EditEmailForm.remove': 'Remove',
  'components.organisms.forms.EditEmailForm.sendMePreviewButton':
    'Send me preview',
  'components.organisms.forms.EditEmailForm.subjectPlaceholder': 'Subject:',
  'components.organisms.forms.EditEmailForm.tiktok.followers':
    '{{tt_followers}}',
  'components.organisms.forms.ForgotPasswordForm.backToLogInText':
    'Back to log in',
  'components.organisms.forms.ForgotPasswordForm.emailPlaceholderText':
    'Enter your email',
  'components.organisms.forms.ForgotPasswordForm.emailText': 'Email',
  'components.organisms.forms.ForgotPasswordForm.resetPasswordButton':
    'Reset password',
  'components.organisms.forms.ForgotPasswordForm.subTitleText':
    'No worries, enter your email address and we will send you instructions to reset your password.',
  'components.organisms.forms.ForgotPasswordForm.titleText': 'Forgot password?',
  'components.organisms.forms.LogInAccountForm.checkboxText':
    'Remember for 30 days',
  'components.organisms.forms.LogInAccountForm.emailPlaceholderText':
    'Enter your email',
  'components.organisms.forms.LogInAccountForm.emailText': 'Email',
  'components.organisms.forms.LogInAccountForm.forgotPasswordText':
    'Forgot password',
  'components.organisms.forms.LogInAccountForm.logoText': 'HEY SEVA',
  'components.organisms.forms.LogInAccountForm.notHaveAccountText':
    'Don’t have an account?',
  'components.organisms.forms.LogInAccountForm.orText': 'or',
  'components.organisms.forms.LogInAccountForm.passwordPlaceholderText':
    '••••••••',
  'components.organisms.forms.LogInAccountForm.passwordText': 'Password',
  'components.organisms.forms.LogInAccountForm.signInButton': 'Sign in',
  'components.organisms.forms.LogInAccountForm.signInWithGoogleButton':
    'Sign in with Google',
  'components.organisms.forms.LogInAccountForm.signUpText': 'Sign up',
  'components.organisms.forms.LogInAccountForm.subTitleText':
    'Welcome back! Please enter your details.',
  'components.organisms.forms.LogInAccountForm.titleText':
    'Log in to your account',
  'components.organisms.forms.PasswordResetForm.backToLogInText':
    'Back to log in',
  'components.organisms.forms.PasswordResetForm.continueButton': 'Continue',
  'components.organisms.forms.PasswordResetForm.subTitleText':
    'Your password has been successfully reset. Click below to log in magically.',
  'components.organisms.forms.PasswordResetForm.titleText': 'Password reset',
  'components.organisms.forms.SetPasswordForm.backToLogInText':
    'Back to log in',
  'components.organisms.forms.SetPasswordForm.confirmPasswordPlaceholderText':
    '••••••••',
  'components.organisms.forms.SetPasswordForm.confirmPasswordText':
    'Confirm password',
  'components.organisms.forms.SetPasswordForm.mustBeText':
    'Must be at least 8 characters.',
  'components.organisms.forms.SetPasswordForm.passwordPlaceholderText':
    'Create a new password',
  'components.organisms.forms.SetPasswordForm.passwordText': 'Password*',
  'components.organisms.forms.SetPasswordForm.resetPasswordButton':
    'Reset password',
  'components.organisms.forms.SetPasswordForm.subTitleText':
    'Your new password must be different to previously used passwords',
  'components.organisms.forms.SetPasswordForm.titleText': 'Set new password',
  'components.organisms.forms.creatorFilter.allOrganizations': 'Organization',
  'components.organisms.forms.creatorFilter.allTags': 'All Tags',
  'components.organisms.forms.creatorFilter.allTagsNotIn': 'Not In Tags',
  'components.organisms.forms.creatorFilter.followers': 'Followers',
  'components.organisms.forms.creatorFilter.notInOrganizations':
    'Not in Organization',
  'components.organisms.forms.creatorFilter.pool': 'Pool',
  'components.organisms.forms.creatorFilter.selectSocialMedia':
    'Social Platform',
  'components.organisms.forms.creatorFilter.selectTags': 'Select Tags',

  'components.organisms.modal.Confirmation.Title': 'Confirmation',
  'components.organisms.modal.Confirmation.activatePool.text':
    'Are you sure you want to activate this pool?',

  'components.routes.modals.ActivityFeedModal.activityMenuTitle': 'Activity',
  'components.routes.modals.ActivityFeedModal.attachButton': 'Attach file',
  'components.routes.modals.ActivityFeedModal.cancelButton': 'Cancel',
  'components.routes.modals.ActivityFeedModal.emailStageMenuTitle':
    'Email stage',
  'components.routes.modals.ActivityFeedModal.openedText': 'Opened',
  'components.routes.modals.ActivityFeedModal.repliedText': 'Replied',
  'components.routes.modals.ActivityFeedModal.sentText': 'Sent',
  'components.routes.modals.ActivityFeedModal.subTitle':
    'Upload and attach image to this campaign.',
  'components.routes.modals.ActivityFeedModal.timeMenuTitle': 'Time',
  'components.routes.modals.ActivityFeedModal.title':
    'Activity feed for {user}',
  'components.routes.modals.AddCampaignModal.cancelButton': 'Cancel',
  'components.routes.modals.AddCampaignModal.okButton': 'Ok',
  'components.routes.modals.AddCampaignModal.placeholder':
    'Search for campaign',
  'components.routes.modals.AddCampaignModal.success':
    'Success! {numberCreators} creators have been added to the {projectName} company. You can go to the company (this is a {link}) or continue adding creators.',
  'components.routes.modals.AddCampaignModal.title': 'Add to campaign',
  'components.routes.modals.AddOrgUser.emailLabel': 'Email',
  'components.routes.modals.AddOrgUser.emailPlaceholder': 'Enter Email',
  'components.routes.modals.AddOrganization.placeholder': 'Search organization',
  'components.routes.modals.AddOrganizationModal.placeholder':
    'Select organization',
  'components.routes.modals.AddPool.placeholder': 'Search pool',
  'components.routes.modals.AssignAdminTags.Placeholder': 'Select Tags',
  'components.routes.modals.AssignAdminTags.type.Placeholder': 'Select type',
  'components.routes.modals.AssignTagsModal.title': 'Assign Tags',
  'components.routes.modals.CreatePoolModal.descriptionLabel': 'Description',
  'components.routes.modals.CreatePoolModal.descriptionPlaceholder':
    'Enter Description',
  'components.routes.modals.CreatePoolModal.nameLabel': 'Name',
  'components.routes.modals.CreatePoolModal.namePlaceholder': 'Enter Name',
  'components.routes.modals.CreatePoolModal.scheduleDateLabel': 'Schedule Date',
  'components.routes.modals.CreatePoolModal.selectOrganization':
    'Select Organization',
  'components.routes.modals.CreatePoolModal.title': 'Create Pool',
  'components.routes.modals.CreateTagsModal.addLablesType': 'Select Type',
  'components.routes.modals.CreateTagsModal.title': 'Create Tags',
  'components.routes.modals.DuplicateEmailSequenceModal.cancelButton': 'Cancel',
  'components.routes.modals.DuplicateEmailSequenceModal.okButton': 'Ok',
  'components.routes.modals.DuplicateEmailSequenceModal.selectMenuText':
    'Search email sequence',
  'components.routes.modals.DuplicateEmailSequenceModal.title':
    'Duplicate Email Sequence',
  'components.routes.modals.EditCreatorModal.title': 'Edit Creator',
  'components.routes.modals.MoreFiltersModal.applyButton': 'Apply',
  'components.routes.modals.MoreFiltersModal.campaignText': 'Campaign',
  'components.routes.modals.MoreFiltersModal.cancelButton': 'Cancel',
  'components.routes.modals.MoreFiltersModal.emailSequenceText':
    'Email Sequence',
  'components.routes.modals.MoreFiltersModal.followersText': 'Followers',
  'components.routes.modals.MoreFiltersModal.fromDateDropdownText': 'From date',
  'components.routes.modals.MoreFiltersModal.lastPostText': 'Last Post',
  'components.routes.modals.MoreFiltersModal.leadAgentText': 'Lead Agent',
  'components.routes.modals.MoreFiltersModal.listOfCampaignDropdownText':
    'List of Campaign',
  'components.routes.modals.MoreFiltersModal.listOfEmailDropdownText':
    'List of Email sequences',
  'components.routes.modals.MoreFiltersModal.maximumInputText': 'Maximum',
  'components.routes.modals.MoreFiltersModal.minimumInputText': 'Minimum',
  'components.routes.modals.MoreFiltersModal.nameOfTeamDropdownText':
    'Name of team members',
  'components.routes.modals.MoreFiltersModal.reachText': 'Reach',
  'components.routes.modals.MoreFiltersModal.selectTagsDropdownText':
    'Select tags',
  'components.routes.modals.MoreFiltersModal.selfTagsText': 'Self Tags',
  'components.routes.modals.MoreFiltersModal.title': 'More Filters',
  'components.routes.modals.MoreFiltersModal.toDateDropdownText': 'To date',
  'components.routes.modals.NewSequenceModal.backButton': 'Back',
  'components.routes.modals.NewSequenceModal.closeButton': 'Close',
  'components.routes.modals.NewSequenceModal.nextButton': 'Next',
  'components.routes.modals.NewSequenceModal.submitButton':
    'Send Message & Save',
  'components.routes.modals.SeedingEmailModal.cloneToMySequenceButton':
    'Clone to My Sequence',
  'components.routes.modals.SeedingEmailModal.closeButton': 'Close',
  'components.routes.modals.TextAreaModal.cancelButton': 'Cancel',
  'components.routes.modals.TextAreaModal.okButton': 'Ok',
  'components.routes.modals.TextAreaModal.title': 'Edit Notes',
  'components.routes.modals.UploadImageModal.attachButton': 'Attach file',
  'components.routes.modals.UploadImageModal.cancelButton': 'Cancel',
  'components.routes.modals.UploadImageModal.subTitle':
    'Upload and attach image to this campaign.',
  'components.routes.modals.UploadImageModal.title': 'Upload campaign image',

  'components.routes.pages.Access.title': 'Login',
  'components.routes.pages.Brand.title': 'Brands',
  'components.routes.pages.Campaign.title': 'Campaign',
  'components.routes.pages.Campaigns.button': 'New Campaign',
  'components.routes.pages.Campaigns.title': 'Campaigns',
  'components.routes.pages.Creators.title': 'Creator Search',
  'components.routes.pages.EmailSequence.button': 'New Sequence',
  'components.routes.pages.EmailSequence.title': 'Email Sequence',
  'components.routes.pages.Error404.body': 'Nothing found :(',
  'components.routes.pages.Error404.title': '404 Not found',
  'components.routes.pages.Home.title': 'Home',
  'components.routes.pages.Main.title': 'Project name',
  'components.routes.pages.Organizations.title': 'Organizations',
  'components.routes.pages.Payment.title': 'Payment',
  'components.routes.pages.Pool.metaTitle': '{value} | Pool',
  'components.routes.pages.Pool.title': 'Pool',
  'components.routes.pages.ReviewSequence.backButton': 'Back',
  'components.routes.pages.ReviewSequence.closeButton': 'Close',
  'components.routes.pages.ReviewSequence.discardDraftButton': 'Discard draft',
  'components.routes.pages.ReviewSequence.errorWeFoundText':
    'We found potential issues with your sequence. Review the warning before saving.',
  'components.routes.pages.ReviewSequence.nextStepText': 'Next Step:',
  'components.routes.pages.ReviewSequence.savedDraftButton': 'Saved draft',
  'components.routes.pages.ReviewSequence.sendMessageButton':
    'Send Message & Save',
  'components.routes.pages.ReviewSequence.title': 'Review Sequence',
  'components.routes.pages.Sequence.title': 'Email Sequence',
  'components.routes.pages.Settings.title': 'Settings',
  'components.routes.pages.Support.title': 'Support',
  'components.routes.pages.Tags.title': 'Tags',
  'components.routes.pages.Users.title': 'Users',

  'components.routes.sidebar.Pool.title': 'Pool',
  'components.routes.sidebar.Tags.title': 'Global Tags',
};
