// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__Se\\+28 {
  width: var(--PAGE_MAX_WIDTH);
  min-height: max-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--COLOR_FOREGROUND);
  border-radius: var(--RADIUS_12);
}

.styles_headerBlock__h\\+djI {
  width: 100%;
  display: flex;
  align-items: center;
  padding: var(--PADDING_24);
}

.styles_titleStyle__zk7Ce {
  font-family: var(--FONT_SEMIBOLD);
  font-style: normal;
  font-weight: var(--WEIGHT_600);
  font-size: var(--FONT_SIZE_TEXT_TITLE);
  line-height: var(--LINE_HEIGHT_28);
}

.styles_mainBlock__M7hlz {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--PADDING_8) var(--PADDING_24);
}

.styles_selectMenuBlock__cu8kK {
  width: 100%;
  height: max-content;
  border-radius: var(--RADIUS_8);
  box-shadow: 0 var(--SIZE_1) var(--SIZE_2) var(--COLOR_GRAY_900_LIGHT),
    0 0 0 var(--SIZE_4) var(--COLOR_PRIMARY_100);
}

.styles_footerBlock__5T0IS {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: var(--PADDING_24);
}

.styles_buttonCancelStyle__oDhNR {
  width: 100%;
  padding-right: var(--PADDING_12);
}

.styles_buttonCreateStyle__BcUiV {
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/routes/modals/AssignPoolModal/styles.module.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,mCAAmC;EACnC,+BAA+B;AACjC;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,iCAAiC;EACjC,kBAAkB;EAClB,8BAA8B;EAC9B,sCAAsC;EACtC,kCAAkC;AACpC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,2CAA2C;AAC7C;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,8BAA8B;EAC9B;gDAC8C;AAChD;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,0BAA0B;AAC5B;;AAEA;EACE,WAAW;EACX,gCAAgC;AAClC;;AAEA;EACE,WAAW;AACb","sourcesContent":[".container {\n  width: var(--PAGE_MAX_WIDTH);\n  min-height: max-content;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  background: var(--COLOR_FOREGROUND);\n  border-radius: var(--RADIUS_12);\n}\n\n.headerBlock {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  padding: var(--PADDING_24);\n}\n\n.titleStyle {\n  font-family: var(--FONT_SEMIBOLD);\n  font-style: normal;\n  font-weight: var(--WEIGHT_600);\n  font-size: var(--FONT_SIZE_TEXT_TITLE);\n  line-height: var(--LINE_HEIGHT_28);\n}\n\n.mainBlock {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  padding: var(--PADDING_8) var(--PADDING_24);\n}\n\n.selectMenuBlock {\n  width: 100%;\n  height: max-content;\n  border-radius: var(--RADIUS_8);\n  box-shadow: 0 var(--SIZE_1) var(--SIZE_2) var(--COLOR_GRAY_900_LIGHT),\n    0 0 0 var(--SIZE_4) var(--COLOR_PRIMARY_100);\n}\n\n.footerBlock {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  padding: var(--PADDING_24);\n}\n\n.buttonCancelStyle {\n  width: 100%;\n  padding-right: var(--PADDING_12);\n}\n\n.buttonCreateStyle {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__Se+28`,
	"headerBlock": `styles_headerBlock__h+djI`,
	"titleStyle": `styles_titleStyle__zk7Ce`,
	"mainBlock": `styles_mainBlock__M7hlz`,
	"selectMenuBlock": `styles_selectMenuBlock__cu8kK`,
	"footerBlock": `styles_footerBlock__5T0IS`,
	"buttonCancelStyle": `styles_buttonCancelStyle__oDhNR`,
	"buttonCreateStyle": `styles_buttonCreateStyle__BcUiV`
};
export default ___CSS_LOADER_EXPORT___;
