import { message } from 'antd';
import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { ReduxState } from 'src/reducers';

type Props = {
  children: ReactNode;
};

export const NotificationsProvider: React.FC<Props> = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const dispatch = useDispatch();

  const { isShown, config } = useSelector(({ notifications }: ReduxState) => ({
    config: notifications.config,
    isShown: notifications.isShown,
  }));

  useEffect(() => {
    if (isShown && config) {
      messageApi.open({
        onClose: () => dispatch(actions.ui.notifications.deleteNotification()),
        ...config,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShown]);

  return (
    <>
      {contextHolder}
      {children}
    </>
  );
};
