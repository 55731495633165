// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_columnList__i4hz- {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: var(--MARGIN_10);
}

.styles_columnLabel__w1A-W {
  font-size: var(--FONT_SIZE_TEXT);
  font-family: var(--FONT_MEDIUM);
  line-height: var(--LINE_HEIGHT_20);
}

.styles_radiobutton__xbfIB {
  margin-left: var(--MARGIN_10);
}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/ColumnView/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,8BAA8B;EAC9B,4BAA4B;AAC9B;;AAEA;EACE,gCAAgC;EAChC,+BAA+B;EAC/B,kCAAkC;AACpC;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".columnList {\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n  margin-top: var(--MARGIN_10);\n}\n\n.columnLabel {\n  font-size: var(--FONT_SIZE_TEXT);\n  font-family: var(--FONT_MEDIUM);\n  line-height: var(--LINE_HEIGHT_20);\n}\n\n.radiobutton {\n  margin-left: var(--MARGIN_10);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columnList": `styles_columnList__i4hz-`,
	"columnLabel": `styles_columnLabel__w1A-W`,
	"radiobutton": `styles_radiobutton__xbfIB`
};
export default ___CSS_LOADER_EXPORT___;
