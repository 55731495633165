import { ConfigProvider } from 'antd';
import React from 'react';

import { Provider } from 'src/components/providers/Provider';
import { Router } from 'src/navigation/Router';
import { theme } from 'src/styles/antTheme';
import { Sidebar } from 'src/utils/Sidebar';

//import logo from 'src/assets/images/logo.svg';
//import logo from 'src/assets/images/logo';
//import logo from './logo.svg';
//import './App.css';

export const App: React.FC = () => {
  return (
    <ConfigProvider theme={theme}>
      <Provider>
        <div className="app" data-testid="page">
          <Router />
        </div>
        <Sidebar />
      </Provider>
    </ConfigProvider>
  );
};
