import { API_HOST, PUBLIC_ENV } from 'src/constants/env';
import { BasicExtraParams } from 'src/types';

export const getAPIUrl = (protocol: 'https' | 'wss') => {
  const API_PROTOCOL =
    PUBLIC_ENV === 'local' ? protocol.slice(0, -1) : protocol;

  return `${API_PROTOCOL}://${API_HOST}`;
};

export const removeAdditionalParams = <T extends Partial<BasicExtraParams>>(
  params: T
) => {
  const extra = { ...params };

  delete extra?.notificationsParams;

  return extra || {};
};

export const makeFormData = (params: Record<string, string>): FormData => {
  const formData = new FormData();
  const keys = Object.keys(params);

  keys.forEach((key) => {
    const value = params[key];

    if (value !== undefined) {
      formData.append(
        key,
        Array.isArray(value) || (typeof value === 'object' && key !== 'file')
          ? JSON.stringify(value)
          : value
      );
    }
  });

  return formData;
};

export const debounce = (func: (value: string) => void, delay: number) => {
  let timer: ReturnType<typeof setTimeout> | null = null;

  return (value: string) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      func(value);
      timer = null;
    }, delay);
  };
};

export const addDotsToText = (text?: string, characters = 25): string => {
  if (!text) {
    return '';
  }

  return text?.length <= characters
    ? text
    : `${text?.slice(0, characters - 3)}...`;
};

export const getIsActiveAction = (
  reduxActions: string[],
  actionTypes: string[]
): boolean =>
  !!reduxActions.filter((action) => actionTypes.includes(action)).length;

export const generateID = () => Date.now().toString();
