import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'src/actions';
import { handleActions } from 'src/utils/reducer';

export type State = {
  actions: string[];
};

const initialState: State = {
  actions: [],
};

const reducer = reducerWithInitialState<State>(initialState)
  .case(
    actions.ui.currentActiveActions.setActions,
    (state, payload): State => ({
      ...state,
      actions: [...state.actions, ...payload.actions],
    })
  )
  .case(
    actions.ui.currentActiveActions.removeActions,
    (state, payload): State => ({
      actions: handleActions(state.actions, payload.actions),
    })
  );

export const currentActiveActions = { initialState, reducer };
