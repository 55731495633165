import { AllEffect, CallEffect, all, call } from 'redux-saga/effects';

import { accessSaga } from 'src/sagas/access';
import { ajaxSaga } from 'src/sagas/ajax';
import { brandSaga } from 'src/sagas/brand';
import { influencersSaga } from 'src/sagas/influencers';
import { organizationSaga } from 'src/sagas/organizations';
import { poolSaga } from 'src/sagas/pool';
import { tagsSaga } from 'src/sagas/tags';

//import { webSocketSaga } from 'src/sagas/webSocket';

//const sagas = [ajaxSaga, webSocketSaga];
const sagas = [
  ajaxSaga,
  accessSaga,
  influencersSaga,
  tagsSaga,
  poolSaga,
  brandSaga,
  organizationSaga,
];

export function* rootSaga(): Generator<
  AllEffect<CallEffect<unknown>>,
  void,
  unknown
> {
  yield all(sagas.map((saga) => call(saga)));
}
