import { Select, SelectProps } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { Button } from 'src/components/atoms/Button';
import { Msg, msg } from 'src/i18n/Msg';
import { ReduxState } from 'src/reducers';
import { Tags } from 'src/types';
import { getIsActiveAction } from 'src/utils/lib';

import styles from 'src/components/routes/modals/AssignTagsModal/styles.module.css';

// export type Props = {
//   influencerIds?: string[];
// };

let options: SelectProps['options'] = [];
const typeOptions: SelectProps['options'] = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Global',
    value: 'global',
  },
];

export const AssignTagsModal: React.FC = () => {
  const dispatch = useDispatch();

  const [select, setSelect] = useState<string[]>();
  const [type, setType] = useState<string>('admin');
  const intl = useIntl();

  const onClickCancel = useCallback(
    () => dispatch(actions.ui.modal.hide()),
    [dispatch]
  );

  const { activeActions, influencers, tags } = useSelector(
    ({
      organization,
      currentActiveActions,
      influencers,
      tags,
    }: ReduxState) => ({
      organizations: organization.data,
      influencers: influencers.selectedInfluencers,
      activeActions: currentActiveActions.actions,
      tags: tags.data,
    })
  );

  const isFetching = getIsActiveAction(activeActions, [
    actions.api.tags.assignTags.started.type,
  ]);

  const isDone = getIsActiveAction(activeActions, [
    actions.api.tags.assignTags.done.type,
  ]);

  options = useMemo(
    () =>
      tags
        ? tags?.map((x: Tags) => ({
            value: x._id || '',
            label: x.name || '',
          }))
        : [],
    [tags]
  );

  const onCreate = () => {
    dispatch(
      actions.api.tags.assignTags.started({
        influencerIds: influencers || [],
        tags: select || [],
        type,
        extra: {
          notificationsParams: {
            content: 'Successfully Added',
            type: 'success',
          },
        },
      })
    );
  };

  useEffect(() => {
    dispatch(
      actions.api.tags.getTags.started({
        page: 1,
        limit: 20,
        search: 'all',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value: string[]) => {
    setSelect(value);
  };

  return (
    <div className={classNames('shadowLight', styles.container)}>
      <div className={styles.headerBlock}>
        <p className={styles.titleStyle}>
          <Msg id="components.routes.modals.AssignTagsModal.title" />
        </p>
      </div>

      <div className={styles.mainBlock}>
        <p className={styles.menuTitleText}>
          <Msg id="components.routes.modals.CreateTagsModal.addLablesType" />
        </p>
        <div
          className={styles.selectMenuBlock}
          style={{
            marginBottom: 'var(--MARGIN_20)',
          }}
        >
          <Select
            size="large"
            placeholder={msg(intl, {
              id: 'components.routes.modals.AssignAdminTags.type.Placeholder',
            })}
            onChange={setType}
            value={type}
            style={{ width: '100%' }}
            dropdownStyle={{ zIndex: 10000 }}
            options={typeOptions}
          />
        </div>

        <p className={styles.menuTitleText}>
          <Msg id="components.organisms.forms.CreatorForm.addLablesText" />
        </p>
        <div className={styles.selectMenuBlock}>
          <Select
            mode="tags"
            size="large"
            placeholder={msg(intl, {
              id: 'components.routes.modals.AssignAdminTags.Placeholder',
            })}
            onChange={handleChange}
            style={{ width: '100%' }}
            dropdownStyle={{ zIndex: 10000 }}
            options={options}
          />
        </div>
      </div>

      <div className={styles.footerBlock}>
        <div className={styles.buttonCancelStyle}>
          <Button
            onClick={onClickCancel}
            text={{
              id: 'components.routes.modals.AddCampaignModal.cancelButton',
            }}
            variant="cancel"
          />
        </div>

        <div className={styles.buttonCreateStyle}>
          <Button
            preloader={isFetching}
            successLoader={isDone}
            onClick={onCreate}
            disabled={!select?.length}
            text={{
              id: 'components.routes.modals.AddCampaignModal.okButton',
            }}
            variant="create"
          />
        </div>
      </div>
    </div>
  );
};
