import { useDispatch } from 'react-redux';

import { actions } from 'src/actions';
import { Close } from 'src/components/atoms/icons/interactions/Close';
import { ColumnList } from 'src/components/molecules/ColumnList';
import { ColumnMenuClick, ColumnsSetting } from 'src/types';

import style from 'src/components/routes/modals/ColumnListModel/styles.module.css';

export type Props = {
  columns: ColumnsSetting;
  onColumnMenuClicked: ColumnMenuClick;
};

export const ColumnListModel: React.FC<Props> = ({
  columns,
  onColumnMenuClicked,
}) => {
  const dispatch = useDispatch();
  const onClickCancel = () => dispatch(actions.ui.modal.hide());
  return (
    <div className={style.container}>
      <div className={style.headerBlock}>
        <button onClick={onClickCancel} className={style.closeStyle}>
          <Close />
        </button>
      </div>
      <ColumnList columns={columns} onColumnMenuClicked={onColumnMenuClicked} />
    </div>
  );
};
