import TextArea from 'antd/lib/input/TextArea';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { Button } from 'src/components/atoms/Button';
import { Input } from 'src/components/atoms/Input';
import { SelectWithSearch } from 'src/components/atoms/SelectWithSearch';
import { useFormik } from 'src/hooks/useFormik';
import { Msg, msg } from 'src/i18n/Msg';
import { ReduxState } from 'src/reducers';
import { getIsActiveAction } from 'src/utils/lib';
import { validators } from 'src/utils/validation';

// import { validators } from 'src/utils/validation';
import styles from 'src/components/routes/modals/CreatePoolModel/styles.module.css';

export const CreatePoolModel: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const onClickCancel = useCallback(
    () => dispatch(actions.ui.modal.hide()),
    [dispatch]
  );

  const { activeActions, organizations, user } = useSelector(
    ({ organization, currentActiveActions, access }: ReduxState) => ({
      organizations: organization.data || [],
      activeActions: currentActiveActions.actions,
      user: access.profile,
    })
  );

  const onChange = (value: string) => {
    dispatch(
      actions.api.organization.getOrganization.started({
        limit: 20,
        search: value,
        page: 1,
      })
    );
  };

  useEffect(() => {
    dispatch(
      actions.api.organization.getOrganization.started({
        page: 1,
        limit: 20,
        search: '',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isFetching = getIsActiveAction(activeActions, [
    actions.api.tags.assignTags.started.type,
  ]);

  const isDone = getIsActiveAction(activeActions, [
    actions.api.tags.assignTags.done.type,
  ]);

  const { handleSubmit, handleChange, values } = useFormik({
    initialValues: {
      name: '',
      description: '',
      orgId: '',
      scheduleDate: new Date() as unknown as string,
    },

    validationSchema: validators.pool,

    onSubmit: ({ name, description, orgId, scheduleDate }) => {
      dispatch(
        actions.api.pool.createPool.started({
          name,
          description,
          orgId,
          scheduleDate,
          createdBy: user?._id,
          extra: {
            notificationsParams: {
              type: 'success',
              content: 'Pool created successfully ✅',
            },
          },
        })
      );
    },
  });

  const options = organizations?.map((el: { name: string; _id: string }) => ({
    label: el.name,
    value: el._id,
  }));

  return (
    <div className={classNames('shadowLight', styles.container)}>
      <div className={styles.headerBlock}>
        <p className={styles.titleStyle}>
          <Msg id="components.routes.modals.CreatePoolModal.title" />
        </p>
      </div>

      <div className={styles.mainBlock}>
        <p className={styles.menuTitleText}>
          <Msg id="components.routes.modals.CreatePoolModal.selectOrganization" />
        </p>
        <div className={styles.selectMenuBlock}>
          <SelectWithSearch
            onChange={onChange}
            onSelect={handleChange('orgId')}
            options={options}
            isDebounce
            dropdownStyle={{ zIndex: 10000 }}
            placeholder={{
              id: 'components.routes.modals.AddOrganization.placeholder',
            }}
          />
        </div>

        <p className={styles.menuTitleText}>
          <Msg id="components.routes.modals.CreatePoolModal.nameLabel" />
        </p>
        <Input
          onChange={handleChange('name')}
          className={styles.inputStyle}
          defaultValue={values.name}
          placeholder={{
            id: 'components.routes.modals.CreatePoolModal.namePlaceholder',
          }}
        />

        <p className={styles.menuTitleText}>
          <Msg id="components.routes.modals.CreatePoolModal.descriptionLabel" />
        </p>

        <TextArea
          style={{ height: 128 }}
          onChange={handleChange('description')}
          className={styles.textAreaStyle}
          value={values.description}
          maxLength={275}
          placeholder={msg(intl, {
            id: 'components.routes.modals.CreatePoolModal.descriptionPlaceholder',
          })}
        />

        <p className={styles.menuTitleText}>
          <Msg id="components.routes.modals.CreatePoolModal.scheduleDateLabel" />
        </p>

        <Input
          onChange={handleChange('scheduleDate')}
          className={styles.inputStyle}
          defaultValue={values.scheduleDate}
          type="date"
          placeholder={{
            id: 'components.routes.modals.CreatePoolModal.namePlaceholder',
          }}
        />
      </div>

      <div className={styles.footerBlock}>
        <div className={styles.buttonCancelStyle}>
          <Button
            onClick={onClickCancel}
            text={{
              id: 'components.routes.modals.AddCampaignModal.cancelButton',
            }}
            variant="cancel"
          />
        </div>

        <div className={styles.buttonCreateStyle}>
          <Button
            preloader={isFetching}
            successLoader={isDone}
            onClick={() => handleSubmit()}
            text={{
              id: 'components.routes.modals.AddCampaignModal.okButton',
            }}
            variant="create"
          />
        </div>
      </div>
    </div>
  );
};
