import { combineReducers } from 'redux';

import { State as StateAccess, access } from 'src/reducers/access';
import { State as StateBrand, brand } from 'src/reducers/brand';
import {
  State as StateCurrentActiveActions,
  currentActiveActions,
} from 'src/reducers/currentActiveActions';
import {
  State as StateInfluencers,
  influencers,
} from 'src/reducers/influencers';
import { State as StateModals, modals } from 'src/reducers/modals';
import {
  State as StateNotifications,
  notifications,
} from 'src/reducers/notifications';
import {
  State as StateOrganization,
  organization,
} from 'src/reducers/organization';
import { State as StatePool, pool } from 'src/reducers/pool';
import { State as StateSidebar, sidebars } from 'src/reducers/sidebar';
import { State as StateTags, tags } from 'src/reducers/tags';

//import { State as StateTodo, todo } from 'src/reducers/todo';

export interface ReduxState {
  access: StateAccess;
  influencers: StateInfluencers;
  currentActiveActions: StateCurrentActiveActions;
  tags: StateTags;
  sidebar: StateSidebar;
  notifications: StateNotifications;
  organization: StateOrganization;
  modals: StateModals;
  pool: StatePool;
  brand: StateBrand;
}

export const initialState = {
  access: access.initialState,
  influencers: influencers.initialState,
  currentActiveActions: currentActiveActions.initialState,
  tags: tags.initialState,
  sidebar: sidebars.initialState,
  notifications: notifications.initialState,
  organization: organization.initialState,
  modals: modals.initialState,
  pool: pool.initialState,
  brand: brand.initialState,
};

export const rootReducer = combineReducers<ReduxState>({
  access: access.reducer,
  influencers: influencers.reducer,
  currentActiveActions: currentActiveActions.reducer,
  tags: tags.reducer,
  sidebar: sidebars.reducer,
  notifications: notifications.reducer,
  organization: organization.reducer,
  modals: modals.reducer,
  pool: pool.reducer,
  brand: brand.reducer,
});
