import { ConfigProvider, Drawer, Layout } from 'antd';
import React from 'react';

import { Close } from 'src/components/atoms/icons/interactions/Close';
import { theme } from 'src/styles/antTheme';

import styles from 'src/components/organisms/SideBar/styles.module.css';

type Props = {
  children: React.ReactNode;
  visible: boolean;
  dismiss: () => void;
  title?: string;
};

const SideBar: React.FC<Props> = ({
  children,
  visible,
  dismiss,
  title = '',
}) => {
  const onClose = () => {
    dismiss();
  };
  return (
    <ConfigProvider theme={theme}>
      <Drawer
        title={title}
        placement="right"
        closable={false}
        extra={
          <div role="button" onClick={onClose}>
            <Close />
          </div>
        }
        onClose={onClose}
        open={visible}
        headerStyle={{ display: 'flex' }}
        contentWrapperStyle={{
          maxWidth: '550px',
          width: '100%',
        }}
      >
        <Layout.Sider
          className={styles.wrapper}
          breakpoint={'lg'}
          collapsedWidth={0}
          trigger={null}
        >
          {children}
        </Layout.Sider>
      </Drawer>
    </ConfigProvider>
  );
};
export default SideBar;
