import React from 'react';

import { VerifiedTick } from 'src/components/atoms/icons/interactions/VerifiedTick';

import styles from 'src/components/atoms/EditCreatorAvatar/styles.module.css';

type Props = {
  src: string;
};

export const EditCreatorAvatar: React.FC<Props> = ({ src }) => (
  <div className={styles.avatar__wrapper}>
    <img src={src} className={styles.avatar__image} alt="User Avatar" />

    <div className={styles.avatar__verifiedTick}>
      <VerifiedTick />
    </div>
  </div>
);
