// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__NwtLH {
  min-height: var(--MAX_VIEW_HEIGHT);
  margin-right: var(--MARGIN_24);
}
`, "",{"version":3,"sources":["webpack://./src/components/organisms/SideBar/styles.module.css"],"names":[],"mappings":"AAAA;EACE,kCAAkC;EAClC,8BAA8B;AAChC","sourcesContent":[".wrapper {\n  min-height: var(--MAX_VIEW_HEIGHT);\n  margin-right: var(--MARGIN_24);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__NwtLH`
};
export default ___CSS_LOADER_EXPORT___;
