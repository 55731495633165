import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'src/actions';
import { Sidebars } from 'src/components/routes/sidebar';

export type State = Sidebars | null;

const initialState: State = null;

const reducer = reducerWithInitialState<State>(initialState)
  .case(actions.ui.sidebar.hide, (): State => null)
  .case(actions.ui.sidebar.show, (_, payload): State => payload);

export const sidebars = { initialState, reducer };
