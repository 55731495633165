import { Checkbox, Select, SelectProps } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { AddLablesDisplay } from 'src/components/atoms/AddLablesDisplay';
import { Button } from 'src/components/atoms/Button';
import { Input } from 'src/components/atoms/Input';
import { InputIcon } from 'src/components/atoms/InputIcon';
import { MenuEmailSequence } from 'src/components/atoms/icons/interactions/MenuEmailSequence';
import { useFormik } from 'src/hooks/useFormik';
import { Msg } from 'src/i18n/Msg';
import { ReduxState } from 'src/reducers';
import { Influencer, TagItem } from 'src/types';
import { getIsActiveAction } from 'src/utils/lib';
import { validators } from 'src/utils/validation';

import styles from 'src/components/organisms/forms/CreatorForm/styles.module.css';

type Props = {
  influencer: Influencer;
};

export const CreatorForm: React.FC<Props> = ({ influencer }) => {
  const dispatch = useDispatch();

  const { activeActions, allInfluencer } = useSelector(
    ({ currentActiveActions, influencers }: ReduxState) => ({
      activeActions: currentActiveActions.actions,
      allInfluencer: influencers.data,
    })
  );

  const currentInfluencer = allInfluencer?.find(
    (x) => x._id === influencer._id
  );

  const onClickCancel = () => {
    dispatch(actions.ui.sidebar.hide());
  };

  // tags, searchLables
  const saveTags = (value: string, key: string) => {
    if (value && value.trim() && key === 'tags') {
      [value.toLowerCase()].forEach((item: string) => {
        if (!values.tags.find((x) => x.name === item.trim())) {
          values.tags.push({
            name: item,
            id: undefined,
            isGlobal: undefined,
          });
        }
      });
      setFieldValue('tags', values.tags);
      setFieldValue('searchLables', '');
    } else if (value && value.trim() && key === 'admin_tags') {
      [value.toLowerCase()].forEach((item: string) => {
        if (!values.admin_tags.find((x) => x.name === item.trim())) {
          values.admin_tags.push({
            name: item,
            id: undefined,
          });
        }
      });
      setFieldValue('admin_tags', values.admin_tags);
      setFieldValue('searchAdminLables', '');
    }
  };

  const countryList: SelectProps['options'] = [
    {
      label: 'United States',
      value: 'US',
    },
    {
      label: 'Australia',
      value: 'AU',
    },
    {
      label: 'Canada',
      value: 'CA',
    },
    {
      label: 'New Zealand',
      value: 'NZ',
    },
    {
      label: 'Singapore',
      value: 'SG',
    },
    {
      label: 'United Kingdom',
      value: 'GK',
    },
  ];

  console.log('currentInfluencer----', currentInfluencer);

  const { handleSubmit, handleChange, values, errors, setFieldValue } =
    useFormik({
      initialValues: {
        firstName: currentInfluencer
          ? currentInfluencer?.firstName
          : influencer?.firstName
          ? influencer?.firstName
          : '',
        lastName: currentInfluencer?.lastName
          ? currentInfluencer?.lastName
          : influencer?.lastName
          ? influencer?.lastName
          : '',
        email: currentInfluencer?.email
          ? currentInfluencer?.email
          : influencer?.email
          ? influencer?.email
          : '',
        sourcer: currentInfluencer?.sourcer
          ? currentInfluencer?.sourcer
          : influencer?.sourcer
          ? influencer?.sourcer
          : '',
        country: currentInfluencer?.country ?? influencer?.country,
        instagramFollowers: currentInfluencer?.instagramFollowers,
        tiktokFollowers: currentInfluencer?.tiktokFollowers,
        youtubeFollowers: currentInfluencer?.youtubeFollowers,
        tt_username: currentInfluencer?.tt_username ?? influencer?.tt_username,
        ig_username: currentInfluencer?.ig_username ?? influencer?.ig_username,
        tt_verified: currentInfluencer?.tt_verified ?? influencer?.tt_verified,
        ig_verified: currentInfluencer?.ig_verified ?? influencer?.ig_verified,
        tt_link: currentInfluencer?.tt_link ?? influencer?.tt_link,
        ig_link: currentInfluencer?.ig_link ?? influencer?.ig_link,
        youtube_link:
          currentInfluencer?.youtube_link ?? influencer?.youtube_link,
        secondary_email: currentInfluencer?.secondary_email
          ? currentInfluencer?.secondary_email
          : influencer?.secondary_email
          ? influencer?.secondary_email
          : '',
        //engRate: influencer.engRate,
        //personaTags: influencer.personaTags,
        tags: currentInfluencer?.tags
          ? currentInfluencer.tags.map((item) => {
              return {
                name: item.name as string,
                id: item._id,
                isGlobal: item.isGlobal,
              };
            })
          : [],

        //lastActivity: influencer.lastActivity,
        notes: currentInfluencer?.notes,
        estimatedRate: currentInfluencer?.estimatedRate,
        agency: currentInfluencer?.agency,
        bio: currentInfluencer?.bio || undefined,
        searchLables: '',
        admin_tags: currentInfluencer?.admin_tags
          ? currentInfluencer.admin_tags.map((item) => {
              return {
                name: item.name as string,
                id: item._id,
              };
            })
          : [],
        searchAdminLables: '',
      },

      validationSchema: validators.influencer,

      onSubmit: ({
        bio,
        firstName,
        secondary_email,
        email,
        lastName,
        instagramFollowers,
        tiktokFollowers,
        youtubeFollowers,
        tt_username,
        ig_username,
        tt_link,
        country,
        ig_link,
        youtube_link,
        tags,
        admin_tags,
      }) => {
        console.log('instagramFollowers=====', instagramFollowers, bio);
        if (instagramFollowers && typeof instagramFollowers === 'string') {
          instagramFollowers = parseInt(instagramFollowers);
        }
        console.log('instagramFollowers++++++', instagramFollowers);

        if (tiktokFollowers && typeof tiktokFollowers === 'string') {
          tiktokFollowers = parseInt(tiktokFollowers);
        }

        if (youtubeFollowers && typeof youtubeFollowers === 'string') {
          youtubeFollowers = parseInt(youtubeFollowers);
        }

        dispatch(
          actions.api.influencers.updateInfluencer.started({
            firstname: firstName,
            lastname: lastName,
            ig_followers: instagramFollowers,
            tt_followers: tiktokFollowers,
            ig_link,
            tt_link,
            yt_followers: youtubeFollowers,
            tt_username,
            ig_username,
            youtube_link,
            email,
            bio,
            country,
            secondary_email,
            tags,
            admin_tags,
            extra: {
              influencerId: influencer.id,
              notificationsParams: {
                type: 'success',
                content: 'Influencer Updated ✅',
              },
            },
          })
        );
      },
    });

  const isFetching = getIsActiveAction(activeActions, [
    actions.api.influencers.updateInfluencer.started.type,
  ]);

  const isDone = getIsActiveAction(activeActions, [
    actions.api.influencers.updateInfluencer.done.type,
  ]);

  console.log('values', values);

  return (
    <>
      <div className={styles.mainBlock}>
        <div className={styles.inputsBlock}>
          <div className={styles.firstInputFromBlock}>
            <p className={styles.menuTitleText}>
              <Msg id="components.organisms.forms.CreatorForm.firstNameText" />
            </p>

            <Input
              onChange={handleChange('firstName')}
              className={styles.nameInputStyle}
              defaultValue={values.firstName}
              error={errors.firstName}
            />
          </div>

          <div className={styles.secondInputFromBlock}>
            <p className={styles.menuTitleText}>
              <Msg id="components.organisms.forms.CreatorForm.lastNameText" />
            </p>

            <Input
              onChange={handleChange('lastName')}
              className={styles.nameInputStyle}
              defaultValue={values.lastName}
              error={errors.lastName}
            />
          </div>
        </div>

        <p className={styles.menuTitleText}>
          <Msg id="components.organisms.forms.CreatorForm.emailAddressText" />
        </p>

        <div className={styles.emailInputBlock}>
          <InputIcon
            onChange={handleChange('email')}
            icon={<MenuEmailSequence width={18} height={14} />}
            value={influencer.email}
            error={errors.email}
          />
        </div>

        <p className={styles.menuTitleText}>
          <Msg id="components.organisms.forms.CreatorForm.secondaryEmail" />
        </p>

        <div className={styles.emailInputBlock}>
          <InputIcon
            onChange={handleChange('secondary_email')}
            icon={<MenuEmailSequence width={18} height={14} />}
            value={influencer.secondary_email || ''}
            error={errors.secondary_email}
          />
        </div>

        <p className={styles.menuTitleText}>
          <Msg id="components.organisms.forms.CreatorForm.socialLink" />
        </p>

        <div className={styles.upSocialInputBlock}>
          <div className={styles.contentTitleFromSocialBlock}>
            <p className={styles.contentTitleFromSocialStyle}>
              <Msg id="components.organisms.forms.CreatorForm.instagramText" />
            </p>
          </div>

          <Input
            onChange={handleChange('ig_link')}
            className={styles.socialInputStyle}
            defaultValue={influencer.ig_link}
            error={errors.ig_link}
          />
          <div className={styles.verifiedBlock}>
            <Checkbox
              name="ig_verified"
              checked={values.ig_verified}
              onChange={(e) => handleChange(e)}
            >
              Verified
            </Checkbox>
          </div>
        </div>
        <div className={styles.upSocialInputBlock}>
          <div className={styles.contentTitleFromSocialBlock}>
            <p className={styles.contentTitleFromSocialStyle}>
              <Msg id="components.organisms.forms.CreatorForm.tiktokText" />
            </p>
          </div>

          <Input
            onChange={handleChange('tt_link')}
            className={styles.socialInputStyle}
            defaultValue={influencer.tt_link}
            error={errors.tt_link}
          />
          <div className={styles.verifiedBlock}>
            <Checkbox
              name="tt_verified"
              checked={values.tt_verified}
              onChange={(e) => handleChange(e)}
            >
              Verified
            </Checkbox>
          </div>
        </div>
        <div className={styles.upSocialInputBlock}>
          <div className={styles.contentTitleFromSocialBlock}>
            <p className={styles.contentTitleFromSocialStyle}>
              <Msg id="components.organisms.forms.CreatorForm.youtubeText" />
            </p>
          </div>

          <Input
            onChange={handleChange('youtube_link')}
            className={styles.socialInputStyle}
            defaultValue={influencer.youtube_link}
            error={errors.youtube_link}
          />
        </div>

        <p className={styles.menuTitleText}>
          <Msg id="components.organisms.forms.CreatorForm.socialText" />
        </p>

        <div className={styles.upSocialInputBlock}>
          <div className={styles.contentTitleFromSocialBlock}>
            <p className={styles.contentTitleFromSocialStyle}>
              <Msg id="components.organisms.forms.CreatorForm.instagramText" />
            </p>
          </div>

          <Input
            type="number"
            onChange={handleChange('instagramFollowers')}
            className={styles.socialInputStyle}
            defaultValue={String(influencer.instagramFollowers)}
            error={errors.instagramFollowers}
          />
        </div>

        <div className={styles.centerSocialInputBlock}>
          <div className={styles.contentTitleFromSocialBlock}>
            <p className={styles.contentTitleFromSocialStyle}>
              <Msg id="components.organisms.forms.CreatorForm.tiktokText" />
            </p>
          </div>

          <Input
            type="number"
            onChange={handleChange('tiktokFollowers')}
            className={styles.socialInputStyle}
            defaultValue={String(influencer.tiktokFollowers)}
            error={errors.tiktokFollowers}
          />
        </div>

        <div className={styles.downSocialInputBlock}>
          <div className={styles.contentTitleFromSocialBlock}>
            <p className={styles.contentTitleFromSocialStyle}>
              <Msg id="components.organisms.forms.CreatorForm.youtubeText" />
            </p>
          </div>

          <Input
            type="number"
            onChange={handleChange('youtubeFollowers')}
            className={styles.socialInputStyle}
            defaultValue={String(influencer.youtubeFollowers)}
            error={errors.youtubeFollowers}
          />
        </div>

        {/* <p className={styles.menuTitleText}>
          <Msg id="components.organisms.forms.CreatorForm.estimatedRateText" />
        </p>

        <div className={styles.downSocialInputBlock}>
          <div className={styles.contentTitleFromSocialBlock}>
            <p className={styles.contentTitleFromSocialStyle}>
              <Msg id="components.organisms.forms.CreatorForm.estimatedRateSymbol" />
            </p>
          </div>

          <Input
            type="number"
            onChange={handleChange('estimatedRate')}
            className={styles.socialInputStyle}
            defaultValue={influencer.estimatedRate}
            error={errors.estimatedRate}
          />
        </div> */}

        <p className={styles.menuTitleText}>
          <Msg id="components.organisms.forms.CreatorForm.agencyText" />
        </p>

        <Input
          onChange={handleChange('agency')}
          className={styles.agencyInputStyle}
          defaultValue={influencer.firstName}
          error={errors.agency}
        />

        <p className={styles.menuTitleText}>
          <Msg id="components.organisms.forms.CreatorForm.selectCountry" />
        </p>

        <Select
          style={{ width: '100%', marginBottom: 'var(--MARGIN_24)' }}
          placeholder={'Select country'}
          defaultValue={values.country}
          onChange={(value: string) => {
            setFieldValue('country', value);
          }}
          optionLabelProp="label"
          options={countryList}
          popupClassName={styles.selectPopupStyle}
          className={styles.selectStyle}
        />

        <p className={styles.menuTitleText}>
          <Msg id="components.organisms.forms.CreatorForm.addLablesText" />
        </p>

        <Input
          value={values.searchLables}
          onPressEnter={(value: string) => {
            saveTags(value, 'tags');
          }}
          onBlur={(value) => {
            saveTags(value, 'tags');
          }}
          onChange={handleChange('searchLables')}
          className={styles.addLablesInputStyle}
          placeholder={{
            id: 'components.organisms.forms.CreatorForm.addLablesSearchText',
          }}
          error={errors.searchLables}
        />

        <div className={styles.addLablesBlock}>
          <AddLablesDisplay
            tags={values.tags}
            canRemove={true}
            onRemove={(val: TagItem | string) => {
              const name = typeof val === 'string' ? val : val.name;
              const tags = values.tags.filter((x) => x.name !== name);
              setFieldValue('tags', tags);
            }}
          />
        </div>

        <p className={styles.menuTitleText}>
          <Msg id="components.organisms.forms.CreatorForm.adminTags" />
        </p>

        <Input
          value={values.searchAdminLables}
          onPressEnter={(value: string) => {
            saveTags(value, 'admin_tags');
          }}
          onBlur={(value) => {
            saveTags(value, 'admin_tags');
          }}
          onChange={handleChange('searchAdminLables')}
          className={styles.addLablesInputStyle}
          placeholder={{
            id: 'components.organisms.forms.CreatorForm.addLablesSearchText',
          }}
          error={errors.searchAdminLables}
        />

        <div className={styles.addLablesBlock}>
          <AddLablesDisplay
            tags={values.admin_tags}
            canRemove={true}
            onRemove={(val: TagItem | string) => {
              const name = typeof val === 'string' ? val : val.name;
              const tags = values.admin_tags.filter((x) => x.name !== name);
              setFieldValue('admin_tags', tags);
            }}
          />
        </div>

        <p className={styles.menuTitleText}>
          <Msg id="components.organisms.forms.CreatorForm.bioText" />
        </p>

        <TextArea
          onChange={handleChange('bio')}
          style={{ height: 160 }}
          className={styles.textAreaStyle}
          maxLength={275}
          defaultValue={influencer.bio}
        />
      </div>

      <div className={styles.footerBlock}>
        <div className={styles.buttonCancelStyle}>
          <Button
            onClick={onClickCancel}
            text={{
              id: 'components.organisms.forms.CreatorForm.cancelButton',
            }}
            variant="cancel"
          />
        </div>

        <div className={styles.buttonCreateStyle}>
          <Button
            preloader={isFetching}
            successLoader={isDone}
            onClick={() => handleSubmit()}
            disabled={isFetching}
            text={{
              id: 'components.organisms.forms.CreatorForm.updateButton',
            }}
            variant="create"
          />
        </div>
      </div>
    </>
  );
};
