import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'src/actions';
import { Organization } from 'src/types';

export type State = {
  data?: Organization[];
  total?: number;
};

const initialState: State = {
  data: [],
  total: 0,
};

const reducer = reducerWithInitialState<State>(initialState).cases(
  [actions.api.organization.getOrganization.done],
  (state, payload): State => ({
    ...state,
    data: [...payload.result.data],
    //data: getNormalizedInfluencers(payload.result.result),
    total: payload.result.count,
  })
);

export const organization = { initialState, reducer };
