// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__GG1ld {
  width: 100%;
  min-height: var(--SIZE_24);
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.styles_contentBlock__X4YFn {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: var(--MARGIN_8);
  padding-left: var(--PADDING_10);
  padding-right: var(--PADDING_11);
  border-radius: var(--RADIUS_16);
  background-color: var(--COLOR_PRIMARY_100);
  margin-bottom: var(--MARGIN_10);
}

.styles_textBlock__M0XlR {
  margin-right: var(--MARGIN_7);
  font-family: var(--FONT_MEDIUM);
  font-style: normal;
  font-weight: var(--WEIGHT_NORMAL);
  font-size: var(--FONT_SIZE_TEXT);
  line-height: var(--LINE_HEIGHT_20);
  color: var(--COLOR_PRIMARY_600);
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/AddLablesDisplay/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,0BAA0B;EAC1B,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,6BAA6B;EAC7B,+BAA+B;EAC/B,gCAAgC;EAChC,+BAA+B;EAC/B,0CAA0C;EAC1C,+BAA+B;AACjC;;AAEA;EACE,6BAA6B;EAC7B,+BAA+B;EAC/B,kBAAkB;EAClB,iCAAiC;EACjC,gCAAgC;EAChC,kCAAkC;EAClC,+BAA+B;AACjC","sourcesContent":[".wrapper {\n  width: 100%;\n  min-height: var(--SIZE_24);\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  flex-wrap: wrap;\n}\n\n.contentBlock {\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  margin-right: var(--MARGIN_8);\n  padding-left: var(--PADDING_10);\n  padding-right: var(--PADDING_11);\n  border-radius: var(--RADIUS_16);\n  background-color: var(--COLOR_PRIMARY_100);\n  margin-bottom: var(--MARGIN_10);\n}\n\n.textBlock {\n  margin-right: var(--MARGIN_7);\n  font-family: var(--FONT_MEDIUM);\n  font-style: normal;\n  font-weight: var(--WEIGHT_NORMAL);\n  font-size: var(--FONT_SIZE_TEXT);\n  line-height: var(--LINE_HEIGHT_20);\n  color: var(--COLOR_PRIMARY_600);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__GG1ld`,
	"contentBlock": `styles_contentBlock__X4YFn`,
	"textBlock": `styles_textBlock__M0XlR`
};
export default ___CSS_LOADER_EXPORT___;
