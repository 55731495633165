export enum PAGES {
  HOME = '/',
  LOGIN = '/login',
  CREATORS = '/creators',
  ERROR_404 = '*',
  TAGS = '/tags',
  POOL = '/pool',
  POOL_ID = '/pool/:id',
  BRANDS = '/brands',
  ORGANIZATIONS = '/organizations',

  //PAGE = '/',
  //PAGE_WITH_ID = '/pages/todo/:id',
  // INFLUENCER = '/influencer',
}

export const routes = Object.values(PAGES)
  .filter((page) => page !== PAGES.ERROR_404)
  .map((path) => ({ path }));
