import { Select, SelectProps } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { AddLablesDisplay } from 'src/components/atoms/AddLablesDisplay';
import { Button } from 'src/components/atoms/Button';
import { Input } from 'src/components/atoms/Input';
import { useFormik } from 'src/hooks/useFormik';
import { Msg, msg } from 'src/i18n/Msg';
import { ReduxState } from 'src/reducers';
import { TagItem } from 'src/types';
import { getIsActiveAction } from 'src/utils/lib';
import { validators } from 'src/utils/validation';

// import { validators } from 'src/utils/validation';
import styles from 'src/components/routes/modals/CreateTagModel/styles.module.css';

const options: SelectProps['options'] = [
  {
    label: 'Global',
    value: 'global',
  },
  {
    label: 'Admin',
    value: 'admin',
  },
];

export const CreateTagModel: React.FC = () => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const onClickCancel = useCallback(
    () => dispatch(actions.ui.modal.hide()),
    [dispatch]
  );

  const { activeActions } = useSelector(
    ({ organization, currentActiveActions }: ReduxState) => ({
      organizations: organization.data,
      activeActions: currentActiveActions.actions,
    })
  );

  const { handleSubmit, handleChange, values, errors, setFieldValue } =
    useFormik({
      initialValues: {
        searchLables: '',
        tags: [],
        type: 'global',
      },

      validationSchema: validators.tags,

      onSubmit: ({ type, tags }) => {
        dispatch(
          actions.api.tags.createTags.started({
            tags,
            type,
            extra: {
              notificationsParams: {
                content: 'Successfully Added',
                type: 'success',
              },
            },
          })
        );
      },
    });

  const isFetching = getIsActiveAction(activeActions, [
    actions.api.tags.assignTags.started.type,
  ]);

  const isDone = getIsActiveAction(activeActions, [
    actions.api.tags.assignTags.done.type,
  ]);

  const saveTags = (value: string) => {
    if (value && value.trim()) {
      if (!values.tags.some((x) => x === value)) {
        setFieldValue('tags', [...values.tags, value]);
      }
      setFieldValue('searchLables', '');
    }
  };

  useEffect(() => {
    dispatch(
      actions.api.tags.getTags.started({
        page: 1,
        limit: 20,
        search: 'all',
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={classNames('shadowLight', styles.container)}>
      <div className={styles.headerBlock}>
        <p className={styles.titleStyle}>
          <Msg id="components.routes.modals.CreateTagsModal.title" />
        </p>
      </div>

      <div className={styles.mainBlock}>
        <p className={styles.menuTitleText}>
          <Msg id="components.routes.modals.CreateTagsModal.addLablesType" />
        </p>
        <div className={styles.selectMenuBlock}>
          <Select
            size="large"
            placeholder={msg(intl, {
              id: 'components.routes.modals.AssignAdminTags.Placeholder',
            })}
            onChange={handleChange('type')}
            style={{ width: '100%' }}
            dropdownStyle={{ zIndex: 10000 }}
            options={options}
          />
        </div>

        <p className={styles.menuTitleText}>
          <Msg id="components.organisms.forms.CreatorForm.addLablesText" />
        </p>

        <Input
          value={values.searchLables}
          onChange={handleChange('searchLables')}
          onPressEnter={(value: string) => {
            saveTags(value.trim());
          }}
          onBlur={(value) => {
            saveTags(value.trim());
          }}
          className={styles.addLablesInputStyle}
          placeholder={{
            id: 'components.organisms.forms.CreatorForm.addLablesSearchText',
          }}
          error={errors.searchLables}
        />

        <div className={styles.addLablesBlock}>
          <AddLablesDisplay
            tags={values.tags}
            canRemove={true}
            onRemove={(val: string | TagItem) => {
              const tags = values.tags.filter((x) => x !== val);
              setFieldValue('tags', tags);
            }}
          />
        </div>
      </div>

      <div className={styles.footerBlock}>
        <div className={styles.buttonCancelStyle}>
          <Button
            onClick={onClickCancel}
            text={{
              id: 'components.routes.modals.AddCampaignModal.cancelButton',
            }}
            variant="cancel"
          />
        </div>

        <div className={styles.buttonCreateStyle}>
          <Button
            preloader={isFetching}
            successLoader={isDone}
            onClick={() => handleSubmit()}
            text={{
              id: 'components.routes.modals.AddCampaignModal.okButton',
            }}
            variant="create"
          />
        </div>
      </div>
    </div>
  );
};
