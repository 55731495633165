import React from 'react';

import { IconBaseProps } from 'src/types';

export const VerifiedTick: React.FC<IconBaseProps> = ({
  width = 25,
  height = 24,
  fill = 'none',
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
  >
    <g clipPath="url(#a)">
      <path
        fill="#0788F5"
        d="M19.2749 4.25109c.2279.55127.6655.98946 1.2164 1.21823l1.9318.80019c.5513.22836.9894.66638 1.2177 1.21769.2284.55132.2284 1.17076 0 1.72208l-.7996 1.93042c-.2285.5516-.2288 1.1717.0007 1.7229l.7982 1.9299c.1133.2731.1715.5658.1716.8614 0 .2956-.0582.5883-.1713.8614s-.2789.5212-.488.7302c-.209.209-.4572.3747-.7303.4877l-1.9305.7996c-.5513.228-.9894.6655-1.2182 1.2164l-.8002 1.9319c-.2284.5513-.6664.9893-1.2177 1.2177-.5513.2283-1.1708.2283-1.7221 0l-1.9304-.7996c-.5513-.2278-1.1706-.2274-1.7216.0012l-1.93178.7991c-.55101.2278-1.16992.2276-1.72079-.0005-.55086-.2282-.98863-.6657-1.21715-1.2164l-.80043-1.9325c-.22794-.5512-.66547-.9894-1.2164-1.2182l-1.93183-.8002c-.55107-.2282-.98896-.666-1.2174-1.217-.22845-.551-.22876-1.1701-.00087-1.7213l.79962-1.9305c.2278-.5513.22734-1.1706-.00129-1.7215l-.79847-1.93325c-.11321-.27306-.1715-.56575-.17155-.86135-.00005-.29559.05815-.5883.17127-.8614.11312-.27309.27895-.52122.488-.7302.20905-.20899.45723-.37473.73036-.48776l1.93045-.79962c.55079-.22774.98871-.66472 1.21765-1.21501l.80019-1.93183c.22836-.55132.66638-.98933 1.21769-1.21769.55132-.228366 1.17077-.228366 1.72208 0l1.93045.79961c.5513.2278 1.1705.22734 1.7215-.00129l1.9327-.79708c.5512-.228238 1.1705-.228191 1.7217.00012.5512.22832.9892.66621 1.2176 1.21738l.8004 1.93241-.0002-.00335Z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M17.4421 8.8551c.1603-.25182.214-.55702.1493-.84845-.0647-.29143-.2425-.54522-.4943-.70555-.2518-.16032-.557-.21404-.8485-.14934-.2914.0647-.5452.24252-.7055.49434l-4.4055 6.9225-2.01602-2.52c-.18638-.2331-.45774-.3827-.75437-.4157-.29664-.0331-.59426.0531-.82738.2395-.23313.1863-.38266.4577-.41572.7543-.03305.2967.05309.5943.23947.8274l3.00002 3.75c.1118.1399.2554.2511.4188.3244.1635.0732.3421.1064.5209.0967.1789-.0097.3528-.0619.5074-.1524.1546-.0904.2853-.2165.3814-.3677l5.25-8.25Z"
        clipRule="evenodd"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M.742188 0h24v24h-24z" />
      </clipPath>
    </defs>
  </svg>
);
