import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(customParseFormat);
dayjs.extend(timezone);

export const isValidEntity = (entity: string, format: string) =>
  dayjs(entity, format).isValid();

export const isValid = (date?: string | number | Date): boolean =>
  dayjs(date).isValid();

export const getTimeZone = () => dayjs.tz.guess();

export const isDateBeforeCurrentDate = (date: string): boolean => {
  const givenDate = dayjs(isValid(date) ? date : undefined);
  const currentDate = dayjs();
  return givenDate.isBefore(currentDate, 'seconds');
};

export const getDateTimeByTimezone = (
  date: string,
  time: string,
  format: string,
  timeZone: string
) => {
  if (!isValidEntity(`${date}, ${time}`, 'DD-MM-YYYY, HH:mm:ss') || !timeZone) {
    return dayjs().format(format);
  }

  return dayjs.tz(`${date} ${time}`, timeZone).format(format);
};
export const getCurrentTime = () => dayjs().format('HH:mm:ss');

export const getCurrentDate = (days: number) =>
  dayjs().add(days, 'day').format('YYYY-MM-DD');

export const getDayjsObject = (value: string, format = 'HH:mm:ss') => {
  //console.log('getDayjsObject', value);
  return dayjs(value, format);
};

export const getFormattedDate = (date: string) =>
  dayjs(isValid(date) ? date : undefined).format('DD MMM YYYY');

export const getFormattedDateTime = (date: string, format: string) =>
  dayjs(isValid(date) ? date : undefined).format(format);

export const getdayFromDate = (date: string) => {
  const target = dayjs(isValid(date) ? date : undefined).format('YYYY-MM-DD');
  const targetDate = dayjs(target);
  const now = dayjs().format('YYYY-MM-DD');
  const nowDate = dayjs(now);
  return targetDate.diff(nowDate, 'day');
};

export const getCurrentDateWithAddedDays = (
  number: number,
  businessDay = false,
  format = 'YYYY-MM-DD'
) => {
  let date = dayjs().add(number, 'day');

  if (businessDay) {
    date = dayjs();

    const initialNumber = date.day() === 6 || date.day() === 0 ? 0 : 1;

    for (let i = initialNumber; i <= number; i++) {
      switch (date.day()) {
        case 6:
          date = date.add(2, 'day');
          break;

        case 5:
          date = date.add(3, 'day');
          break;

        default:
          date = date.add(1, 'day');
      }
    }
  }

  return date.format(format);
};

export const getTimeAgoString = (
  date: string,
  timezone?: string | undefined
): string => {
  let now = dayjs();
  let targetDate = dayjs(isValid(date) ? date : undefined);

  if (timezone) {
    now = dayjs().utc().tz(timezone);
    targetDate = targetDate.utc().tz(timezone);
  }

  const diffInMinutes = now.diff(targetDate, 'minute');
  const diffInHours = now.diff(targetDate, 'hour');
  const diffInDays = now.diff(targetDate, 'day');
  const diffInWeeks = now.diff(targetDate, 'week');
  const diffInMonths = now.diff(targetDate, 'month');
  const diffInYears = now.diff(targetDate, 'year');
  if (diffInMinutes < 1) {
    return 'Just now';
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minutes ago`;
  } else if (diffInHours === 1) {
    return '1 hour ago';
  } else if (diffInHours === 3 || diffInHours === 12) {
    return `${diffInHours} hours ago`;
  } else if (targetDate.minute() === 0) {
    return targetDate.format('h:mm a');
  } else if (diffInDays === 1) {
    return '1 day ago';
  } else if (diffInDays === 2) {
    return '2 days ago';
  } else if (diffInDays >= 3 && diffInDays <= 7) {
    return `${diffInDays} days ago`;
  } else if (diffInWeeks === 1) {
    return '1 week ago';
  } else if (diffInWeeks === 2) {
    return '2 weeks ago';
  } else if (diffInWeeks >= 3 && diffInWeeks <= 4) {
    return `${diffInWeeks} weeks ago`;
  } else if (diffInMonths === 1) {
    return '1 month ago';
  } else if (diffInMonths === 2) {
    return '2 months ago';
  } else if (diffInMonths >= 3 && diffInMonths <= 12) {
    return `${diffInMonths} months ago`;
  } else if (diffInYears === 1) {
    return '1 year ago';
  } else if (diffInYears === 2) {
    return '2 years ago';
  } else if (diffInYears >= 3 && diffInYears <= 4) {
    return `${diffInYears} years ago`;
  } else {
    return targetDate.format('DD MMM YYYY');
  }
};

export const getTimeBeforeString = (
  date: string,
  timezone?: string | undefined
): string => {
  let targetDate = dayjs(isValid(date) ? date : undefined).utc();

  let now = dayjs(
    dayjs()
      .hour(targetDate.get('h'))
      .minute(targetDate.get('m'))
      .second(targetDate.get('s'))
  ).utc();

  if (timezone) {
    now = dayjs().utc().tz(timezone);
    targetDate = targetDate.utc().tz(timezone);
  }

  const futureDate = targetDate.isAfter(now);

  const diffInMinutes = futureDate
    ? targetDate.diff(now, 'minute')
    : now.diff(targetDate, 'minute');
  const diffInHours = futureDate
    ? targetDate.diff(now, 'hour')
    : now.diff(targetDate, 'hour');
  const diffInDays = futureDate
    ? targetDate.diff(now, 'day')
    : now.diff(targetDate, 'day');
  const diffInWeeks = futureDate
    ? targetDate.diff(now, 'week')
    : now.diff(targetDate, 'week');
  const diffInMonths = futureDate
    ? targetDate.diff(now, 'month')
    : now.diff(targetDate, 'month');
  const diffInYears = futureDate
    ? targetDate.diff(now, 'year')
    : now.diff(targetDate, 'year');

  const getTimeAgo = (unit: string, count: number) => {
    return futureDate
      ? `${count} ${unit}${count === 1 ? '' : 's'}`
      : `${count} ${unit}${count === 1 ? '' : 's'} ago`;
  };
  if (diffInYears === 1) {
    return `Sends in ${getTimeAgo('year', 1)}`;
  } else if (diffInYears > 1) {
    return `Sends in ${getTimeAgo('year', diffInYears)}`;
  } else if (diffInMonths === 1) {
    return `Sends in ${getTimeAgo('month', 1)}`;
  } else if (diffInMonths > 1) {
    return `Sends in ${getTimeAgo('month', diffInMonths)}`;
  } else if (diffInWeeks === 1) {
    return `Sends in ${getTimeAgo('week', 1)}`;
  } else if (diffInWeeks > 1) {
    return `Sends in ${getTimeAgo('week', diffInWeeks)}`;
  } else if (diffInDays === 1) {
    return `Sends in ${getTimeAgo('day', 1)}`;
  } else if (diffInDays > 1) {
    return `Sends in ${getTimeAgo('day', diffInDays)}`;
  } else if (targetDate.minute() === 0) {
    return `Sends on ${targetDate.format('DD MMM YYYY')} at ${targetDate.format(
      'h:mm a'
    )}`;
  } else if (diffInHours === 1) {
    return `Sends in ${getTimeAgo('hour', 1)}`;
  } else if (diffInHours > 1) {
    return `Sends in ${getTimeAgo('hour', diffInHours)}`;
  } else if (diffInMinutes > 0) {
    return `Sends in ${getTimeAgo('minute', diffInMinutes)}`;
  } else {
    return `Sends on ${targetDate.format('DD MMM YYYY')}`;
  }
};

export const getDateWithTime = (date: string) => {
  const targetDate = dayjs(isValid(date) ? date : undefined);
  return `${targetDate.format('DD MMM YYYY')} at ${targetDate.format(
    'h:mm a'
  )}`;
};

export const getDateWithoutTime = (date: string) => {
  const targetDate = dayjs(isValid(date) ? date : undefined);
  return `${targetDate.format('DD MMM YYYY')}`;
};

export const getDateWithTimeZone = (
  date: string,
  timezone?: string | undefined
) => {
  let targetDate = dayjs(isValid(date) ? date : undefined).utc();
  if (timezone) {
    targetDate = targetDate.utc().tz(timezone);
  }
  return `${targetDate.format('DD MMM YYYY')} at ${targetDate.format(
    'h:mm a'
  )}`;
};
