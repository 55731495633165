import { takeEvery } from 'redux-saga/effects';
import { Action, Success } from 'typescript-fsa';

import { actions } from 'src/actions';
import { Actions } from 'src/types';

function onSignInUpWithStarted(
  action: Action<
    Success<
      Actions.api.access.signInWithEmail.started,
      Actions.api.access.signInWithEmail.done
    >
  >
) {
  const result = action.payload.result;
  console.log('result==,result', result);

 // window.location = '/' as never;
}

export function* accessSaga(): Generator {
  yield takeEvery(
    actions.api.access.signInWithEmail.done.type,
    onSignInUpWithStarted
  );
}
