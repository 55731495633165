/* eslint-disable @typescript-eslint/no-explicit-any */
import { generatePath } from 'react-router-dom';
import { call, put, takeEvery } from 'redux-saga/effects';
import { Action } from 'typescript-fsa';

import { actions } from 'src/actions';
import { registerAsyncActions } from 'src/utils/factories';
import { getAPIUrl, makeFormData, removeAdditionalParams } from 'src/utils/lib';
import { showNotification } from 'src/utils/notification';

const { events, eventsToActionCreators } = registerAsyncActions(actions.api);
const baseURL = getAPIUrl('https');

function* ajaxGenerator(action: Action<any>): Generator {
  const actionCreator = eventsToActionCreators[action.type];
  const params = action.payload;

  const notificationsParams = params?.extra?.notificationsParams;

  const extraParams = removeAdditionalParams(params?.extra);

  const patchedParams = {
    ...params,
    extra: undefined,
    onUploadProgress: undefined,
    onFailure: undefined,
    trackingIndex: undefined,
  };

  const isFileUpload =
    actionCreator.options &&
    actionCreator.options.headers &&
    actionCreator.options.headers['Content-Type'] === 'multipart/form-data';

  const onUploadProgress = action.payload?.onUploadProgress;
  const onFailure = action.payload?.onFailure;
  const onComplete = action.payload?.onComplete;
  const onFailed = action.payload?.onFailed;
  if (onComplete) {
    patchedParams.onComplete = undefined;
  }
  if (onFailed) {
    patchedParams.onFailed = undefined;
  }

  try {
    const response: any = yield call(
      actionCreator.handler,
      isFileUpload ? makeFormData(patchedParams) : patchedParams,
      {
        baseURL,
        url: generatePath(actionCreator.options.url, extraParams),
        onUploadProgress: (progress) => {
          if (progress.total && onUploadProgress) {
            onUploadProgress(
              Math.round((progress.loaded * 100) / progress.total)
            );
          }
        },
      }
    );

    yield put(
      actionCreator.done({
        params,
        result: response.data,
      })
    );

    if (
      !actionCreator.options.extra?.hideSuccessNotification &&
      !params?.extra?.hideSuccessNotification
    ) {
      showNotification({
        type: 'success',
        ...notificationsParams,
      });
      // yield put(
      //   actions.ui.notifications.addNotification({
      //     type: 'success',
      //     ...notificationsParams,
      //   })
      // );
    }
    onComplete && onComplete(response.data);
  } catch (rawError: any) {
    const data = rawError?.response?.data;
    const apiError = data?.error || data?.message || data || rawError?.message;

    const error = apiError || 'Unknown Error';

    showNotification({
      type: 'error',
      content: error,
    });

    const logoutErrors = [
      'User not found',
      'User session is not found',
      'You need to log in',
    ];

    if (logoutErrors.includes(error)) {
      yield put(actions.api.access.signOut.done({ result: { status: true } }));
    }

    if (onFailure) {
      onFailure();
    }

    console.error(error);
    onFailed && onFailed();

    yield put(actionCreator.failed({ params, error }));
  }
}

export function* ajaxSaga(): Generator {
  yield takeEvery(events, ajaxGenerator);
}
