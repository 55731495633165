import React from 'react';

import { IconBaseProps } from 'src/types';

export const WarningScreamer: React.FC<IconBaseProps> = ({
  width = 20,
  height = 19,
  fill = 'none',
  stroke = 'var(--COLOR_FAILED_25)',
}) => (
  <svg width={width} height={height} viewBox="0 0 20 19" fill={fill}>
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.66667"
      d="M9.88411 6.34033v3.33334m0 3.33333h.00834m8.32495-3.33333c0 4.60233-3.7309 8.33333-8.33329 8.33333-4.60237 0-8.33333-3.731-8.33333-8.33333 0-4.60238 3.73096-8.33334 8.33333-8.33334 4.60239 0 8.33329 3.73096 8.33329 8.33334Z"
    />
  </svg>
);
