import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'src/actions';
import { Tags } from 'src/types';
import { getNormalizedTags } from 'src/utils/reducer';

export type State = {
  data?: Tags[];
  count: number;
  searchSettings?: {
    searchWord: string;
    limitElements: number;
    currentPage: number;
  };
};

const initialState: State = {
  data: [],
  count: 0,
  searchSettings: {
    searchWord: '',
    limitElements: 50,
    currentPage: 1,
  },
};

const reducer = reducerWithInitialState<State>(initialState)
  .cases(
    [actions.api.tags.getTags.done],
    (state, payload): State => ({
      ...state,
      data: getNormalizedTags(payload.result.result),
      count: payload.result.count,
    })
  )
  .cases(
    [actions.ui.tags.setSearchSettings],
    (state, payload): State => ({
      ...state,
      searchSettings: {
        ...state.searchSettings,
        ...payload,
      },
    })
  );

export const tags = { reducer, initialState };
