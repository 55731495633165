import { Checkbox, Radio } from 'antd';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions } from 'src/actions';
import { Button } from 'src/components/atoms/Button';
import { MultiSelectTags } from 'src/components/atoms/MultiSelectTags';
import { SelectWithSearch } from 'src/components/atoms/SelectWithSearch';
import { Close } from 'src/components/atoms/icons/interactions/Close';
import { InstagramGrey } from 'src/components/atoms/icons/interactions/InstagramGrey';
import { TiktokGrey } from 'src/components/atoms/icons/interactions/TiktokGrey';
import { YoutubeGrey } from 'src/components/atoms/icons/interactions/YoutubeGrey';
import { Msg } from 'src/i18n/Msg';
import { ReduxState } from 'src/reducers';

import styles from 'src/components/organisms/forms/CreatorFilterForm/styles.module.css';

type Props = {
  onCancel?: () => void;
};

type FilterTags = {
  name: string;
  id: string;
};

export const CreatorFilterForm: React.FC<Props> = ({ onCancel }) => {
  const {
    tagOptions,
    selectedTags,
    notInSelectedTags,
    searchSetting,
    defaultSelectedOrganization,
    defaultNotInSelectedOrganization,
    Organizations,
    pool,
    defaultSelectedPool,
  } = useSelector(({ tags, influencers, organization, pool }: ReduxState) => ({
    tagOptions: tags?.data?.map((x: { name: string; _id: string }) => ({
      value: x.name,
      label: x.name,
      id: x._id,
    })),
    selectedTags: influencers?.influencerSearchSettings?.selectedTags,
    notInSelectedTags: influencers?.influencerSearchSettings?.notInSelectedTags,
    searchSetting: influencers?.influencerSearchSettings,
    defaultSelectedOrganization:
      influencers?.influencerSearchSettings.selectedOrganization,
    defaultNotInSelectedOrganization:
      influencers?.influencerSearchSettings.notInSelectedOrganization,
    defaultSelectedPool: influencers?.influencerSearchSettings.selectedPool,
    Organizations: organization?.data,
    pool: pool?.data,
  }));

  useEffect(() => {
    dispatch(
      actions.api.pool.getPools.started({ limit: 20, page: 1, search: 'all' })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectedOrganization, setSelectedOrganization] = useState<
    | {
        id: string;
        name: string;
      }
    | undefined
  >(defaultSelectedOrganization);

  const [notInSelectedOrganization, setNotInSelectedOrganization] = useState<
    | {
        id: string;
        name: string;
      }
    | undefined
  >(defaultNotInSelectedOrganization);

  const [selectedPool, setSelectedPool] = useState<
    | {
        id: string;
        name: string;
      }
    | undefined
  >(defaultSelectedPool);

  const [selectTags, setSelectedTags] = useState<FilterTags[]>(
    selectedTags || []
  );

  const [notInSelectTags, setNotInSelectedTags] = useState<FilterTags[]>(
    notInSelectedTags || []
  );

  const [selectFollowers, setSelectedFollowers] = useState<string>(
    searchSetting?.selectedFollowerTag || ''
  );
  const [minFollowers, setMinFollowers] = useState<number | undefined>(
    searchSetting?.minFollowers
  );
  const [maxFollowers, setMaxFollowers] = useState<number | undefined>(
    searchSetting?.maxFollowers
  );
  const [socialMedia, setSocialMedia] = useState<
    CheckboxValueType[] | undefined
  >(searchSetting?.socialPlatform);

  const dispatch = useDispatch();

  const applyFilter = () => {
    dispatch(
      actions.ui.influencers.setInfluencerSearchSettings({
        currentPage: 1,
        selectedTags: selectTags,
        notInSelectedTags: notInSelectTags,
        socialPlatform: socialMedia as string[],
        minFollowers: minFollowers,
        maxFollowers: maxFollowers,
        selectedFollowerTag: selectFollowers,
        selectedOrganization,
        notInSelectedOrganization,
        selectedPool,
      })
    );
    onCancel && onCancel();
  };

  const resetFilter = () => {
    dispatch(
      actions.ui.influencers.setInfluencerSearchSettings({
        currentPage: 1,
        selectedTags: undefined,
        notInSelectedTags: undefined,
        socialPlatform: [] as string[],
        minFollowers: undefined,
        maxFollowers: undefined,
        selectedFollowerTag: undefined,
        selectedOrganization: undefined,
        notInSelectedOrganization: undefined,
        selectedPool: undefined,
      })
    );
    onCancel && onCancel();
  };

  const onChange = (value: string) => {
    dispatch(
      actions.api.organization.getOrganization.started({
        limit: 20,
        search: value,
        page: 1,
      })
    );
  };

  const OrganizationOption = Organizations?.map((el) => ({
    label: el.name,
    value: el._id,
    id: el._id,
  }));

  OrganizationOption?.unshift({
    label: 'None',
    value: 'none',
    id: 'none',
  });

  const poolOptions = pool?.map((el) => ({
    label: `${el.name} - ${el.orgName}`,
    value: el._id,
    id: el._id,
  }));

  const parseDefaultValues = (data: FilterTags[]) => {
    return !data?.length
      ? []
      : data?.map(
          (x: FilterTags) =>
            tagOptions?.find((y: { id: string }) => y.id === x.id)?.label || ''
        );
  };

  const onChangeRadioButton = (selected: string) => {
    if (selected.length > 0) {
      switch (selected) {
        case 'nano':
          setMinFollowers(0);
          setMaxFollowers(10000);
          break;
        case 'micro':
          setMinFollowers(10000);
          setMaxFollowers(50000);
          break;
        case 'mid':
          setMinFollowers(50000);
          setMaxFollowers(500000);
          break;
        case 'macro':
          setMinFollowers(500000);
          setMaxFollowers(1000000);
          break;
        case 'mega':
          setMinFollowers(1000000);
          setMaxFollowers(undefined);
          break;
      }
    }
  };

  return (
    <div className={styles.contentFormBlock}>
      <div className={styles.headerBlock}>
        <div className={styles.iconAndTextBlock}>
          <p className={styles.titleStyle}>
            <Msg id="components.routes.modals.MoreFiltersModal.title" />
          </p>
        </div>

        {onCancel && (
          <button onClick={onCancel} className={styles.closeStyle}>
            <Close />
          </button>
        )}
      </div>
      <div className={styles.mainBlock}>
        <div className={styles.filterBlock}>
          <p className={styles.tagLabelStyle}>
            <Msg id="components.organisms.forms.creatorFilter.selectSocialMedia" />
          </p>
          <Checkbox.Group
            className={styles.checkboxGroupStyle}
            options={[
              {
                value: 'instagram',
                label: (
                  <div className={styles.allTagsOptionLabelBlock}>
                    <InstagramGrey width={15} height={15} />{' '}
                    <p className={styles.labelStyle}>
                      <Msg id="components.organisms.SelectSocialChannel.instagram" />
                    </p>
                  </div>
                ),
              },
              {
                value: 'tiktok',
                label: (
                  <div className={styles.allTagsOptionLabelBlock}>
                    <TiktokGrey width={15} height={15} />{' '}
                    <p className={styles.labelStyle}>
                      <Msg id="components.organisms.SelectSocialChannel.tiktok" />
                    </p>
                  </div>
                ),
              },
              {
                value: 'youtube',
                label: (
                  <div className={styles.allTagsOptionLabelBlock}>
                    <YoutubeGrey width={15} height={15} />{' '}
                    <p className={styles.labelStyle}>
                      <Msg id="components.organisms.SelectSocialChannel.youtube" />
                    </p>
                  </div>
                ),
              },
            ]}
            defaultValue={socialMedia}
            onChange={(ele) => setSocialMedia(ele)}
          />
        </div>

        <div className={styles.filterBlock}>
          <p className={styles.tagLabelStyle}>
            <Msg id="components.organisms.forms.creatorFilter.followers" />
          </p>
          <Radio.Group
            onChange={(val) => {
              setSelectedFollowers(val.target.value);
              onChangeRadioButton(val.target.value);
            }}
            value={selectFollowers}
          >
            <div className={styles.allTagsOptionBlock}>
              <Radio disabled={socialMedia?.length === 0} value={'nano'}>
                Nano: 0-10K
              </Radio>
              <Radio disabled={socialMedia?.length === 0} value={'micro'}>
                Micro: 10K-50K
              </Radio>
              <Radio disabled={socialMedia?.length === 0} value={'mid'}>
                Mid: 50-500K
              </Radio>
              <Radio disabled={socialMedia?.length === 0} value={'macro'}>
                Macro: 500K-1M
              </Radio>
              <Radio disabled={socialMedia?.length === 0} value={'mega'}>
                Mega: 1M+
              </Radio>
            </div>
          </Radio.Group>
        </div>
        <div className={styles.filterBlock}>
          <p className={styles.tagLabelStyle}>
            <Msg id="components.organisms.forms.creatorFilter.allTags" />
          </p>
          <MultiSelectTags
            onChange={(data) => {
              console.log(data);
              setSelectedTags(data as unknown as FilterTags[]);
            }}
            dropdownStyle={{ zIndex: 10000 }}
            size="large"
            defaultValue={parseDefaultValues(selectedTags as FilterTags[])}
            options={tagOptions}
            placeholder={{
              id: 'components.organisms.forms.creatorFilter.selectTags',
            }}
            transformOnChange={(data) =>
              data.map((x: { id: string; label: string }) => {
                return { id: x.id, name: x.label };
              })
            }
          />
        </div>
        <div className={styles.filterBlock}>
          <p className={styles.tagLabelStyle}>
            <Msg id="components.organisms.forms.creatorFilter.allTagsNotIn" />
          </p>
          <MultiSelectTags
            onChange={(data) => {
              setNotInSelectedTags(data as unknown as FilterTags[]);
            }}
            dropdownStyle={{ zIndex: 10000 }}
            size="large"
            defaultValue={parseDefaultValues(notInSelectedTags as FilterTags[])}
            options={tagOptions}
            placeholder={{
              id: 'components.organisms.forms.creatorFilter.selectTags',
            }}
            transformOnChange={(data) =>
              data.map((x: { id: string; label: string }) => {
                return { id: x.id, name: x.label };
              })
            }
          />
        </div>
        <div className={styles.filterBlock}>
          <p className={styles.tagLabelStyle}>
            <Msg id="components.organisms.forms.creatorFilter.notInOrganizations" />
          </p>
          <SelectWithSearch
            onChange={onChange}
            onSelect={(value: string, option: { label: string }) => {
              setNotInSelectedOrganization({
                id: value as string,
                name: option.label as string,
              });
            }}
            sort={false}
            defaultValue={
              notInSelectedOrganization && notInSelectedOrganization?.id
            }
            options={OrganizationOption}
            isDebounce
            dropdownStyle={{ zIndex: 10000 }}
            placeholder={{
              id: 'components.routes.modals.AddOrganizationModal.placeholder',
            }}
          />
        </div>
        <div className={styles.filterBlock}>
          <p className={styles.tagLabelStyle}>
            <Msg id="components.organisms.forms.creatorFilter.allOrganizations" />
          </p>
          <SelectWithSearch
            onChange={onChange}
            onSelect={(value: string, option: { label: string }) => {
              setSelectedOrganization({
                id: value as string,
                name: option.label as string,
              });
            }}
            sort={false}
            defaultValue={selectedOrganization && selectedOrganization?.id}
            options={OrganizationOption}
            isDebounce
            dropdownStyle={{ zIndex: 10000 }}
            placeholder={{
              id: 'components.routes.modals.AddOrganizationModal.placeholder',
            }}
          />
        </div>
        <div className={styles.filterBlock}>
          <p className={styles.tagLabelStyle}>
            <Msg id="components.organisms.forms.creatorFilter.pool" />
          </p>
          <SelectWithSearch
            onChange={onChange}
            onSelect={(value: string, option: { label: string }) => {
              setSelectedPool({
                id: value as string,
                name: option.label as string,
              });
            }}
            sort={false}
            defaultValue={selectedPool && selectedPool?.id}
            options={poolOptions}
            isDebounce
            dropdownStyle={{ zIndex: 10000 }}
            placeholder={{
              id: 'components.routes.modals.AddPool.placeholder',
            }}
          />
        </div>
      </div>
      <div className={styles.footerBlock}>
        {onCancel && (
          <div className={styles.buttonCancelStyle}>
            <Button
              onClick={resetFilter}
              text={{
                id: 'components.organisms.AddCreatorBlock.resetButton',
              }}
              variant="cancel"
            />
          </div>
        )}

        <div className={styles.buttonCreateStyle}>
          <Button
            onClick={applyFilter}
            text={{
              id: 'components.routes.modals.MoreFiltersModal.applyButton',
            }}
            variant="create"
          />
        </div>
      </div>
    </div>
  );
};
