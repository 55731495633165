import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useIntl } from 'react-intl';

import { TableDataText } from 'src/components/atoms/TableDataText';
import { Basket } from 'src/components/atoms/icons/interactions/Basket';
import { msg } from 'src/i18n/Msg';
import { Pool } from 'src/types';
import { getDateWithoutTime } from 'src/utils/dates';

import styles from 'src/components/molecules/CreatorOverviewPoolTable/styles.module.css';

type Props = {
  assignedPool: Array<Pool>;
  influencerId?: string;
  influencerName?: string;
};

export const CreatorOverviewPoolTable: React.FC<Props> = ({
  assignedPool,
  influencerId,
}) => {
  const intl = useIntl();
  const handelClick = (poolId: string) => {
    console.log('poolId', poolId, influencerId);
  };

  const columns: ColumnsType<Pool> = [
    {
      title: msg(intl, {
        id: 'components.organisms.creatorOverview.organization.name',
      }),
      dataIndex: 'orgId',
      key: 'orgId',
      render: (orgId) => <TableDataText text={orgId.name} />,
    },
    {
      title: msg(intl, {
        id: 'components.organisms.creatorOverview.pool.name',
      }),
      dataIndex: 'name',
      key: 'name',
      render: (name) => <TableDataText text={name} />,
    },
    {
      title: msg(intl, {
        id: 'components.organisms.creatorOverview.pool.scheduleDate',
      }),
      dataIndex: 'scheduleDate',
      key: 'scheduleDate',
      render: (scheduleDate) => (
        <TableDataText text={getDateWithoutTime(scheduleDate)} />
      ),
    },
    {
      title: 'Remove',
      dataIndex: '_id',
      key: '_id',
      render: (_id) => {
        return (
          <span
            className={styles.deleteOption}
            role="button"
            onClick={() => handelClick(_id)}
          >
            <Basket />
          </span>
        );
      },
    },
  ];
  return (
    <div className={styles.wrapper}>
      <Table columns={columns} dataSource={assignedPool} pagination={false} />
    </div>
  );
};
