import { List, Radio, RadioChangeEvent } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'src/actions';
import { Button } from 'src/components/atoms/Button';
import { Input } from 'src/components/atoms/Input';
import { Close } from 'src/components/atoms/icons/interactions/Close';

import styles from 'src/components/routes/modals/ReasonModal/styles.module.css';

//const { Option } = Select;
export type Props<T> = {
  //influencerId: string;
  reasonList: Array<{ label: string; value: string }>;
  onSubmit: (reason: string, data: T, submitActionType: string) => void;
  submitActionType: string;
  data: T;
};

// const reason = [
//   { label: 'Irrelevant content', value: 'Irrelevant content' },
//   { label: 'Wrong Geography', value: 'Wrong Geography' },
//   { label: 'Email didn’t work', value: 'Email didn’t work' },
//   { label: 'Very poor engagement', value: 'Very poor engagement' },
//   { label: 'Fake followers', value: 'Fake followers' },
//   { label: 'Other', value: 'Other' },
// ];

export const ReasonModal: React.FC<Props<unknown>> = ({
  reasonList,
  onSubmit,
  submitActionType,
  data,
}) => {
  const dispatch = useDispatch();
  const [reasonValue, setReasonValue] = useState('');
  const [reasonOtherValue, setReasonOtherValue] = useState('');

  const onCancel = () => {
    dispatch(actions.ui.modal.hide());
  };

  const onChange = (selection: RadioChangeEvent) => {
    //setFieldValue('radioSelected', selection.target.value);
    setReasonValue(selection.target.value);
  };

  const handleSubmit = () => {
    //console.log('sss', reasonValue, reasonOtherValue);
    let reason = reasonValue;
    if (reasonValue === 'Other') {
      reason = reasonOtherValue;
      //console.log(reason);
    }

    onSubmit(reason, data, submitActionType);
    onCancel();
  };

  return (
    <div className={styles.container}>
      <div className={styles.headerBlock}>
        <div className={styles.textBlock}>
          <p className={styles.titleStyle}>
            Help us improve creator recommendation.
          </p>

          <p className={styles.subTitleStyle}>
            What didn't work in this profile?
          </p>
        </div>
        <p className={styles.titleStyle}></p>
        {onCancel && (
          <button onClick={onCancel} className={styles.closeStyle}>
            <Close />
          </button>
        )}
      </div>

      <div className={styles.mainBlock}>
        <Radio.Group
          //defaultValue={radioButtonInput}
          // value={values.radioSelected}
          // value={radioButtonInput}
          style={{ width: '100%' }}
          onChange={onChange}
        >
          <List
            dataSource={reasonList}
            renderItem={(item) => (
              <List.Item style={{ border: 'none' }}>
                <Radio
                  // checked={item.value === values.radioSelected}
                  value={item.value}
                  style={{ display: 'flex', margin: '250', width: '100%' }}
                >
                  {item.label}
                </Radio>
              </List.Item>
            )}
          />
        </Radio.Group>

        {/* <Select
          allowClear
          optionLabelProp="label"
          options={reason}
          placeholder={'Reason'}
          defaultValue={''}
          onChange={(value: string) => {
            setReasonValue(value);
          }}
          dropdownStyle={{ zIndex: 2501 }}
        /> */}
        {reasonValue && reasonValue === 'Other' ? (
          <div className={styles.inputBlock}>
            <Input
              className={styles.inputStyle}
              placeholder={{
                id: 'components.modals.ReasonModal.placeholder',
              }}
              // onBlur={}
              onBlur={(value: string) => setReasonOtherValue(value)}
              onChange={function (_): void {
                //console.log(value);
                //throw new Error('Function not implemented.');
              }}
            />
          </div>
        ) : (
          ''
        )}
      </div>

      <div className={styles.footerBlock}>
        {onCancel && (
          <div className={styles.buttonCancelStyle}>
            <Button
              onClick={onCancel}
              text={{
                id: 'components.organisms.form.ReasonModal.cancelButton',
              }}
              variant="cancel"
            />
          </div>
        )}

        <div className={styles.buttonCreateStyle}>
          <Button
            onClick={() => handleSubmit()}
            //preloader={isFetching}
            text={{
              id: 'components.modals.Reason.submitButton',
            }}
            variant="create"
          />
        </div>
      </div>
    </div>
  );
};
