import React, { lazy } from 'react';

import { PAGES } from 'src/constants/pages';

//import { DictionaryKey } from 'src/i18n';

export type AuthMode = 'authorized' | 'unauthorized';

export const pagesRedirect: Record<AuthMode, PAGES> = {
  authorized: PAGES.CREATORS,
  unauthorized: PAGES.LOGIN,
};

export type PageRoute = {
  access: 'all' | AuthMode;
  //title: DictionaryKey;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: React.FC<any>;
};

export const pages: Record<PAGES, PageRoute> = {
  [PAGES.HOME]: {
    access: 'authorized',
    title: 'components.routes.pages.Main.title',
    component: lazy(() => import('src/components/routes/pages/Main')),
  },

  [PAGES.CREATORS]: {
    access: 'authorized',
    title: 'components.routes.pages.Page1.title',
    component: lazy(() => import('src/components/routes/pages/creators')),
  },

  [PAGES.TAGS]: {
    access: 'authorized',
    title: 'components.routes.pages.Tags.title',
    component: lazy(() => import('src/components/routes/pages/tags')),
  },

  [PAGES.POOL]: {
    access: 'authorized',
    title: 'components.routes.pages.Pool.title',
    component: lazy(() => import('src/components/routes/pages/pools')),
  },

  [PAGES.POOL_ID]: {
    access: 'authorized',
    title: 'components.routes.pages.Pool.title',
    component: lazy(() => import('src/components/routes/pages/pool')),
  },
  [PAGES.BRANDS]: {
    access: 'authorized',
    title: 'components.routes.pages.Brand.title',
    component: lazy(() => import('src/components/routes/pages/brands')),
  },
  [PAGES.ORGANIZATIONS]: {
    access: 'authorized',
    title: 'components.routes.pages.Organizations.title',
    // Organizations
    component: lazy(() => import('src/components/routes/pages/organizations')),
  },

  // [PAGES.PAGE]: {
  //   access: 'authorized',
  //   title: 'components.routes.pages.Page2.title',
  //   component: lazy(() => import('src/components/routes/pages/Page2')),
  // },

  [PAGES.LOGIN]: {
    access: 'unauthorized',
    title: 'components.routes.pages.Access.title',
    component: lazy(() => import('src/components/routes/pages/Login')),
  },

  // This item must be the last in the list
  [PAGES.ERROR_404]: {
    access: 'all',
    title: 'components.routes.pages.Error404.title',
    component: lazy(() => import('src/components/routes/pages/Error404')),
  },
};
