import { Switch, Table, Tooltip } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { actions } from 'src/actions';
import { TableDataText } from 'src/components/atoms/TableDataText';
import { Basket } from 'src/components/atoms/icons/interactions/Basket';
import { msg } from 'src/i18n/Msg';
import { InfluencerOrgMap } from 'src/types';
import { getFormattedDateTime } from 'src/utils/dates';

import styles from 'src/components/molecules/CreatorOverviewOrganizationTable/styles.module.css';

type Props = {
  assignedOrg: Array<{
    orgId: string;
    orgName: string;
    influencerId: string;
    emailSendCount: number;
    influencerOrgMap?: InfluencerOrgMap;
  }>;

  influencerId?: string;
  influencerName?: string;
};

export const CreatorOverviewOrganizationTable: React.FC<Props> = ({
  assignedOrg,
  //influencerId,
  influencerName,
}) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const reasonList = [
    { label: 'Irrelevant content', value: 'Irrelevant content' },
    { label: 'Wrong Geography', value: 'Wrong Geography' },
    { label: 'Email didn’t work', value: 'Email didn’t work' },
    { label: 'Very poor engagement', value: 'Very poor engagement' },
    { label: 'Fake followers', value: 'Fake followers' },
    { label: 'Other', value: 'Other' },
  ];
  type postDataParam = { [key: string]: string | number | boolean | undefined };
  //const [submitData, setSubmitData] = useState<postDataParam>();
  //const [submitActionType, setSubmitActionType] = useState('');

  const onReasonCallback = (
    res: string,
    submitData: unknown,
    submitActionType: string
  ) => {
    //setReason(res);
    console.log({ res, submitData, submitActionType });
    const postData = submitData as postDataParam;
    if (submitActionType === 'removeInfluencer') {
      console.log('----------------', res, submitActionType);
      dispatch(
        actions.api.influencers.removeInfluencerFromOrganization.started({
          reason: res,
          extra: {
            orgId: postData?.orgId as string,
            influencerId: postData?.influencerId as string,
            notificationsParams: {
              type: 'success',
              content: `${influencerName} removed from ${postData?.orgName}`,
            },
          },
        })
      );
    }

    if (submitActionType === 'disableInfluencer') {
      dispatch(
        actions.api.influencers.switchInfluencerOrgStatus.started({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          orgId: postData?.orgId as string,
          influencerId: postData?.influencerId as string,
          reason: res || '',
          status: postData?.status as string,
          extra: {
            notificationsParams: {
              type: 'success',
              content: `${influencerName} disabled  for ${postData?.orgName}`,
            },
          },
        })
      );
    }

    //console.log(reason, data, submitReason);
  };

  const handelClick = (
    influencerId: string,
    orgId: string,
    orgName: string
  ) => {
    const data = { influencerId, orgId, orgName };
    // setSubmitActionType('removeInfluencer');
    //setSubmitData(data);
    console.log({ data });
    dispatch(
      actions.ui.modal.show({
        name: 'ReasonModal',
        data: {
          reasonList,
          onSubmit: onReasonCallback,
          data: data,
          submitActionType: 'removeInfluencer',
        },
      })
    );
  };

  const onDisableHandel = (
    checked: boolean,
    influencerId: string,
    orgId: string,
    orgName: string
  ) => {
    let status = 'active';
    if (checked === false) {
      status = 'inactive';
      const data = { influencerId, orgId, orgName, status };
      dispatch(
        actions.ui.modal.show({
          name: 'ReasonModal',
          data: {
            reasonList,
            onSubmit: onReasonCallback,
            data,
            submitActionType: 'disableInfluencer',
          },
        })
      );
      //setSubmitActionType('disableInfluencer');
      //setSubmitData(data);
    } else {
      dispatch(
        actions.api.influencers.switchInfluencerOrgStatus.started({
          orgId,
          influencerId,
          reason: '',
          status,
          extra: {
            notificationsParams: {
              type: 'success',
              content: `${influencerName} enable for ${orgName}`,
            },
          },
        })
      );
    }

    console.log('disable', checked, influencerId, orgId, orgName);
  };

  const columns: ColumnsType<{
    orgId: string;
    orgName: string;
    influencerId: string;
    emailSendCount: number;
    influencerOrgMap?: InfluencerOrgMap;
  }> = [
    {
      title: msg(intl, {
        id: 'components.organisms.creatorOverview.organization.name',
      }),
      dataIndex: 'orgName',
      key: 'orgName',
      render: (_, data) => <TableDataText text={`${data.orgName}`} />,
    },
    {
      title: 'Emails Sent',
      dataIndex: 'emailSendCount',
      key: 'emailSendCount',
      width: 50,
      render: (_, data) => (
        <TableDataText
          text={`${data.emailSendCount ? data.emailSendCount : 0}`}
        />
      ),
    },
    {
      title: 'Added on',
      dataIndex: 'assignedAt',
      key: 'assignedAt',
      render: (_, data) => (
        <TableDataText
          text={`${
            data?.influencerOrgMap?.assignedAt
              ? getFormattedDateTime(
                  data?.influencerOrgMap?.assignedAt,
                  'D MMM YYYY'
                )
              : '-'
          }`}
        />
      ),
    },
    {
      title: 'Disabled/ Removed',
      dataIndex: 'unassignedAt',
      key: 'unassignedAt',
      width: 50,
      render: (_, data) => (
        <Tooltip
          placement="topLeft"
          title={
            data?.influencerOrgMap?.action.includes('unassign')
              ? data?.influencerOrgMap?.reason
              : ''
          }
        >
          <span>
            <TableDataText
              text={`${
                data?.influencerOrgMap?.unassignedAt
                  ? getFormattedDateTime(
                      data?.influencerOrgMap?.unassignedAt,
                      'D MMM YYYY'
                    )
                  : '-'
              }`}
            />
          </span>
        </Tooltip>
      ),
    },
    // {
    //   title: 'Disabled',
    //   dataIndex: 'disabled',
    //   key: 'disabled',
    //   render: (_, { influencerId, emailSendCount, orgName, orgId }) => {
    //     return emailSendCount && emailSendCount > 0 ? (
    //       <Switch
    //         defaultChecked
    //         onChange={(checked: boolean) =>
    //           onDisableHandel(checked, influencerId, orgId, orgName)
    //         }
    //       />
    //     ) : (
    //       <></>
    //     );
    //   },
    // },
    {
      title: 'Action',
      dataIndex: 'edit',
      key: 'edit',
      render: (
        _,
        { influencerId, emailSendCount, orgName, orgId, influencerOrgMap }
      ) => {
        return emailSendCount && emailSendCount > 0 ? (
          <>
            {/* <Tooltip
              placement="topLeft"
              title={
                'Creator can’t be removed from an organization as email has been sent'
              }
            >
              <span className={styles.disabledDeleteButton}>
                <Basket />
              </span>
            </Tooltip> */}
            <Switch
              checked={
                influencerOrgMap?.action.includes('unassign') ? false : true
              }
              defaultChecked
              onChange={(checked: boolean) =>
                onDisableHandel(checked, influencerId, orgId, orgName)
              }
            />
          </>
        ) : (
          // <Switch
          //   defaultChecked
          //   onChange={(checked: boolean) =>
          //     onDisableHandel(checked, influencerId, orgId, orgName)
          //   }
          // />
          <span
            className={styles.deleteOption}
            role="button"
            onClick={() => handelClick(influencerId, orgId, orgName)}
          >
            <Basket />
          </span>
        );
      },
    },

    // {
    //   title: msg(intl, {
    //     id: 'components.organisms.forms.CreatorForm.overviewTab.dateAdded',
    //   }),
    //   dataIndex: 'createdAt',
    //   key: 'createdAt',
    //   render: (text) => <TableDataText text={getFormattedDate(text)} />,
    // },
  ];
  return (
    <div className={styles.wrapper}>
      <Table columns={columns} dataSource={assignedOrg} pagination={false} />
    </div>
  );
};
