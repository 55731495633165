import React from 'react';

import {
  AssignOrganizationModal, //Props as AssignOrganizationModelProps,
} from 'src/components/routes/modals/AssignOrganizationModal';
import { AssignPoolModal } from 'src/components/routes/modals/AssignPoolModal';
import {
  AssignTagsModal, //Props as AssignOrganizationModelProps,
} from 'src/components/routes/modals/AssignTagsModal';
import {
  ColumnListModel,
  Props as ColumnListModelProps,
} from 'src/components/routes/modals/ColumnListModel';
import {
  ConfirmationModal,
  Props as ConfirmationModalProps,
} from 'src/components/routes/modals/ConfirmationModal';
import { CreatePoolModel } from 'src/components/routes/modals/CreatePoolModel';
import { CreateTagModel } from 'src/components/routes/modals/CreateTagModel';
import { CreatorFilterModal } from 'src/components/routes/modals/CreatorFilterModal';
import {
  OrgUsersModal,
  Props as OrgUsersModalProps,
} from 'src/components/routes/modals/OrgUsersModal';
import {
  ReasonModal,
  Props as ReasonModalProps,
} from 'src/components/routes/modals/ReasonModal';

export type MODALS = keyof Props;

export type Modals = {
  [P in MODALS]: { name: P; data?: Props[P]; style?: React.CSSProperties };
}[MODALS];

type Props = {
  CreatorFilterModal: {};
  ColumnListModel: ColumnListModelProps;
  AssignOrganizationModal: {};
  AssignTagsModal: {};
  CreateTagModel: {};
  CreatePoolModel: {};
  AssignPoolModal: {};
  ReasonModal: ReasonModalProps<unknown>;
  ConfirmationModal: ConfirmationModalProps;
  OrgUsersModal: OrgUsersModalProps;
};

export const modals: {
  [P in MODALS]: React.FC<Props[P]>;
} = {
  ColumnListModel,
  CreatorFilterModal,
  AssignOrganizationModal,
  AssignTagsModal,
  CreateTagModel,
  CreatePoolModel,
  AssignPoolModal,
  ReasonModal,
  ConfirmationModal,
  OrgUsersModal,
};
