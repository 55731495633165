import classNames from 'classnames';
import React from 'react';

import { Input } from 'src/components/atoms/Input';
import { WarningText } from 'src/components/atoms/WarningText';
import { WarningScreamer } from 'src/components/atoms/icons/interactions/WarningScreamer';
import { MsgProps } from 'src/i18n/Msg';

import styles from 'src/components/atoms/InputIcon/styles.module.css';

type Props = {
  value: string;
  icon: JSX.Element;
  onChange: (value: string) => void;
  placeholder?: MsgProps;
  error?: string;
  className?: string;
  disabled?: boolean;
};

export const InputIcon: React.FC<Props> = ({
  value,
  icon,
  onChange,
  placeholder,
  error,
  className,
  disabled,
}) => (
  <div className={styles.mainBlock}>
    <div
      className={classNames(styles.wrapper, {
        [styles.wrapperWarning]: error,
      })}
    >
      <div className={styles.emailIconBlock}>{icon}</div>

      <Input
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        className={classNames(className, styles.emailInputStyle, {
          [styles.emailInputStyleWarning]: error,
        })}
        defaultValue={value}
      />

      {error && (
        <div className={styles.warningIconBlock}>
          <WarningScreamer
            width={13}
            height={13}
            stroke="var(--COLOR_ERROR_500)"
          />
        </div>
      )}
    </div>

    {error && (
      <WarningText textId={error} className={styles.warningTextStyle} />
    )}
  </div>
);
