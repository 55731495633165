// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__nDaoQ {
  width: 100%;
}

.styles_errorStyle__PuhcY {
  color: var(--COLOR_FAILED_50);
}
`, "",{"version":3,"sources":["webpack://./src/components/atoms/Input/styles.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,6BAA6B;AAC/B","sourcesContent":[".wrapper {\n  width: 100%;\n}\n\n.errorStyle {\n  color: var(--COLOR_FAILED_50);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__nDaoQ`,
	"errorStyle": `styles_errorStyle__PuhcY`
};
export default ___CSS_LOADER_EXPORT___;
