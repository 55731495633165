import React from 'react';

import { IconBaseProps } from 'src/types';

type Props = IconBaseProps & {
  cursor?: boolean;
};

export const Close: React.FC<Props> = ({
  width = 12,
  height = 12,
  fill = 'none',
  cursor = false,
  stroke = 'var(--COLOR_GRAY_500)',
}) => (
  <svg
    style={{ cursor: cursor ? 'pointer' : '' }}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill={fill}
  >
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.67"
      d="M11 1 1 11M1 1l10 10"
    />
  </svg>
);
