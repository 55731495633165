import { ArgsProps } from 'antd/es/message';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { actions } from 'src/actions';

export type State = {
  config?: ArgsProps;
  isShown: boolean;
};

const initialState: State = {
  isShown: false,
};

const reducer = reducerWithInitialState<State>(initialState)
  .case(
    actions.ui.notifications.addNotification,
    (state, payload): State => ({
      ...state,
      config: payload,
      isShown: true,
    })
  )
  .case(actions.ui.notifications.deleteNotification, (): State => initialState);

export const notifications = { initialState, reducer };
