// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_wrapper__FKizk {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: var(--SIZE_206);
}

.styles_items__IELY0 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: var(--SIZE_174);
}

.styles_tag__Qhpsk {
  margin-right: var(--MARGIN_4);
  margin-bottom: var(--MARGIN_8);
  cursor: pointer;
}

.styles_tag__Qhpsk:last-child {
  margin-right: 0;
}

.styles_badge__jaL2e .ant-scroll-number {
  margin-left: var(--MARGIN_4);
  background-color: var(--COLOR_GRAY_100);
  color: var(--COLOR_PRIMARY_950);
}

.styles_userTags__dztas .ant-scroll-number {
  margin-left: var(--MARGIN_4);
  background-color: var(--COLOR_PRIMARY_50);
  color: var(--COLOR_PRIMARY_950);
}

.styles_expendableIcon__GPG48 {
  display: flex;
  justify-content: flex-end;
}

.styles_expendableIcon__GPG48 button {
  border: 0px;
}
`, "",{"version":3,"sources":["webpack://./src/components/molecules/Tags/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,6BAA6B;EAC7B,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,4BAA4B;EAC5B,uCAAuC;EACvC,+BAA+B;AACjC;;AAEA;EACE,4BAA4B;EAC5B,yCAAyC;EACzC,+BAA+B;AACjC;;AAEA;EACE,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,WAAW;AACb","sourcesContent":[".wrapper {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  max-width: var(--SIZE_206);\n}\n\n.items {\n  display: flex;\n  align-items: center;\n  flex-wrap: wrap;\n  max-width: var(--SIZE_174);\n}\n\n.tag {\n  margin-right: var(--MARGIN_4);\n  margin-bottom: var(--MARGIN_8);\n  cursor: pointer;\n}\n\n.tag:last-child {\n  margin-right: 0;\n}\n\n.badge :global(.ant-scroll-number) {\n  margin-left: var(--MARGIN_4);\n  background-color: var(--COLOR_GRAY_100);\n  color: var(--COLOR_PRIMARY_950);\n}\n\n.userTags :global(.ant-scroll-number) {\n  margin-left: var(--MARGIN_4);\n  background-color: var(--COLOR_PRIMARY_50);\n  color: var(--COLOR_PRIMARY_950);\n}\n\n.expendableIcon {\n  display: flex;\n  justify-content: flex-end;\n}\n\n.expendableIcon button {\n  border: 0px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `styles_wrapper__FKizk`,
	"items": `styles_items__IELY0`,
	"tag": `styles_tag__Qhpsk`,
	"badge": `styles_badge__jaL2e`,
	"userTags": `styles_userTags__dztas`,
	"expendableIcon": `styles_expendableIcon__GPG48`
};
export default ___CSS_LOADER_EXPORT___;
