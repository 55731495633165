import React from 'react';

import { IconBaseProps } from 'src/types';

type Props = IconBaseProps & {
  cursor?: boolean;
};

export const Basket: React.FC<Props> = ({
  width = 17,
  height = 19,
  fill = 'none',
  cursor = false,
  stroke = 'var(--COLOR_GRAY_500)',
}) => (
  <svg
    style={{ cursor: cursor ? 'pointer' : '' }}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill={fill}
  >
    <path
      stroke={stroke}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.66667"
      d="M11.8333 4.29622v-.66666c0-.93342 0-1.40013-.1816-1.75665-.1598-.31361-.4148-.56857-.7284-.72836-.3565-.181659-.8232-.181659-1.75663-.181659H7.83333c-.93342 0-1.40013 0-1.75665.181659-.3136.15979-.56857.41475-.72836.72836-.18165.35652-.18165.82323-.18165 1.75665v.66666m1.66666 4.58334v4.16664m3.33337-4.16664v4.16664M1 4.29622h15m-1.6667 0v9.33338c0 1.4001 0 2.1002-.2725 2.6349-.2396.4704-.6221.8529-1.0925 1.0926-.5348.2725-1.2348.2725-2.635.2725H6.66667c-1.40013 0-2.1002 0-2.63498-.2725-.4704-.2397-.85286-.6222-1.09254-1.0926-.27248-.5347-.27248-1.2348-.27248-2.6349V4.29622"
    />
  </svg>
);
