import { Modal as ModalAntd } from 'antd';
import React, { ReactNode } from 'react';

type Props = {
  children: ReactNode;
  visible: boolean;
  dismiss: () => void;
  style?: React.CSSProperties;
};

export const Modal: React.FC<Props> = ({
  children,
  visible,
  dismiss,
  style = {},
}) => (
  <ModalAntd
    maskStyle={{ zIndex: 2000 }}
    zIndex={2500}
    style={style}
    centered
    width="auto"
    footer={null}
    closable={false}
    open={visible}
    onCancel={dismiss}
  >
    {children}
  </ModalAntd>
);
