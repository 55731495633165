type PublicEnv = 'local' | 'staging' | 'production' | 'develop';
export const PUBLIC_ENV = process.env.REACT_APP_PUBLIC_ENV as PublicEnv;
export const APP_VERSION = process.env.REACT_APP_VERSION || '';

const configs: Record<PublicEnv, { API_HOST: string; CLIENT_HOST: string }> = {
  local: {
    API_HOST: 'localhost:9090',
    CLIENT_HOST: 'localhost:7000',
  },
  develop: {
    API_HOST: 'dev.admin.api.heyseva.com',
    CLIENT_HOST: 'dev.admin.heyseva.com',
  },
  staging: {
    API_HOST: 'api.example.com', // TODO
    CLIENT_HOST: 'example.com', // TODO
  },
  production: {
    API_HOST: 'admin.api.heyseva.com', // TODO
    CLIENT_HOST: 'admin.heyseva.com', // TODO
  },
};

export const { API_HOST, CLIENT_HOST } = configs[PUBLIC_ENV];
